import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./AdmissionForm.css";
import { customBorderBottom } from "../utils/methods";
import { countryListOptionWithBedoon, maxScoreOrGpa } from "../utils/methods";
import { PiPlusBold } from "react-icons/pi";
import { AiOutlineDelete } from "react-icons/ai";
import CommonAccordion from "./CommonAccordion";
import { maxLength, isNotArabic } from "../utils/methods";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Input from "./Input";
import AdmissionForm5 from "./AdmissionForm5";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "./ErrorMessage";
import ErrorTitle from "./ErrorTitle";

const AdmissionForm4 = ({
  formData,
  handleChange,
  errors,
  errorTitle,
  showError
}) => {
  // const [isCollege, setIsCollege] = useState(false);
  // const [isAcademic, setIsAcademic] = useState(false);

  // const handleCheckCollege = (event) => {
  //   setIsCollege((current) => !current);
  // };

  // console.log(formData.isAcademic.value);
  // const handleCheckAcademic = (event) => {
  //   setIsAcademic((current) => !current);
  // };

  const [testType, setTestType] = useState("");

  const initSchool = {
    name: { value: "", error: "" },
    type: { value: "", error: "" },
    gpa: { value: "", error: "" },
    date: { value: "", error: "" },
    degree: { value: "", error: "" },
    physics: { value: "", error: "" },
    chemistry: { value: "", error: "" },
    biology: { value: "", error: "" },
    average: { value: "", error: "" }
  };

  const initCollege = {
    name: { value: "", error: "" },
    type: { value: "", error: "" },
    country: { value: "", error: "" },
    gpa: { value: "", error: "" },
    date: { value: null, error: "" },
    major: { value: "", error: "" },
    degree: { value: "", error: "" }
  };

  const [data3, setData3] = useState([{ acheivement: "" }]);
  const addEmptyRow1 = () => {
    if (formData.school.value.length < 3) {
      handleChange(
        { target: { value: [...formData.school.value, initSchool] } },
        "school"
      );
    }
  };

  let schoolValueLength = formData.school.value.length;
  let collegeValueLength = formData.college.value.length;
  let achievementValueLength = formData.archivements.value.length;
  const removeSchoolRow = () => {
    if (formData.school.value.length > 1) {
      handleChange(
        { target: { value: formData.school.value.slice(0, -1) } },
        "school"
      );
    }
  };

  const addEmptyRow2 = () => {
    if (formData.college.value.length < 3) {
      handleChange(
        { target: { value: [...formData.college.value, initCollege] } },
        "college"
      );
    }
  };

  const removeCollegeRow = () => {
    if (formData.college.value.length > 1) {
      handleChange(
        { target: { value: formData.college.value.slice(0, -1) } },
        "college"
      );
    }
  };

  const addEmptyRow3 = () => {
    if (formData.archivements.value.length < 3) {
      setData3([...data3, { id: 1, name: "" }]);
      handleChange(
        {
          target: {
            value: [...formData.archivements.value, { value: "", error: "" }]
          }
        },
        "archivements"
      );
    }
  };

  const removeAchievementRow = () => {
    if (formData.archivements.value.length > 1) {
      handleChange(
        { target: { value: formData.archivements.value.slice(0, -1) } },
        "archivements"
      );
    }
  };

  const findAverage = () => {
    formData.scientificMarks.value.forEach((row, index) => {
      let total = 0;
      let count = 3;
      const physics = parseFloat(row.physics.value) || 0;
      const chemistry = parseFloat(row.chemistry.value) || 0;
      const biology = parseFloat(row.biology.value) || 0;

      total += physics + chemistry + biology;
      handleChange(
        { target: { value: Math.floor((total / count) * 100) / 100 } },
        "scientificMarks",
        "value",
        `${index}`,
        "average"
      );
    });
  };

  const handleMarksChange = (index, subject, value) => {
    findAverage();
  };

  useEffect(() => {
    document.title = "AUMS • Academic";
    if (localStorage.getItem("FormData")) {
      setTestType(formData.test.testType.value);
    }
  });


  return (
    <Box sx={{ gap: "10rem" }}>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "space-between",
          backgroundColor: "#B70042",
          color: "white",
          width: "100%",
          padding: "1rem"
        }}
      >
        <span>{formLabel.headings.academicPageTitle.english}</span>
        <span>{formLabel.headings.academicPageTitle.arabic}</span>
      </Box>
      <Box>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.academicPageTitle.arabic}
          englishLabel={formLabel.headings.academicPageTitle.english}
        >
          <Box className="flex flex-col">
            {showError && <ErrorTitle />}
            <h4 className="flex justify-between font-bold">
              <span>{formLabel.headings.academicDetails.english}</span>
              <span>{formLabel.headings.academicDetails.arabic}</span>
            </h4>
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="font-bold">
                      {formLabel.school.name.english}
                    </span>
                    <span className="font-bold"> / </span>
                    <span className="font-bold">
                      {formLabel.school.name.arabic}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="font-bold">
                      {formLabel.school.gpa.english}
                    </span>
                    <span className="font-bold"> / </span>
                    <span className="font-bold">
                      {formLabel.school.gpa.arabic}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="font-bold">
                      {formLabel.school.date.english}
                    </span>
                    <span className="font-bold"> / </span>
                    <span className="font-bold">
                      {formLabel.school.date.arabic}
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="font-bold">
                      {formLabel.school.degree.english}
                    </span>
                    <span className="font-bold"> / </span>
                    <span className="font-bold">
                      {formLabel.school.degree.arabic}
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.school.value.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      <Input
                        value={row.name.value}
                        handleChange={(e) => {
                          if (!isNotArabic(e)) return;
                          handleChange(
                            e,
                            "school",
                            "value",
                            `${index}`,
                            "name"
                          );
                        }}
                      />
                      {errors?.school?.[index]?.schoolName && (
                        <ErrorMessage
                          errorMessage={errors?.school?.[index].schoolName}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Input
                        value={row.gpa.value}
                        type={"number"}
                        handleChange={(e) => {
                          if (maxScoreOrGpa(e)) return;
                          handleChange(e, "school", "value", `${index}`, "gpa");
                        }}
                      />
                      {errors?.school?.[index]?.schoolGpa && (
                        <ErrorMessage
                          errorMessage={errors?.school?.[index]?.schoolGpa}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      <Input
                        type={"date"}
                        value={row.date.value}
                        handleChange={(e) => {
                          if (new Date(e.target.value).getFullYear() > 2099)
                            return;
                          handleChange(
                            e,
                            "school",
                            "value",
                            `${index}`,
                            "date"
                          );
                        }}
                      />
                      {errors?.school?.[index]?.schoolDate && (
                        <ErrorMessage
                          errorMessage={errors?.school?.[index]?.schoolDate}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Input
                        value={row.degree.value}
                        handleChange={(e) => {
                          if (!isNotArabic(e)) return;
                          handleChange(
                            e,
                            "school",
                            "value",
                            `${index}`,
                            "degree"
                          );
                        }}
                      />
                      {errors?.school?.[index]?.schoolDegree && (
                        <ErrorMessage
                          errorMessage={errors?.school?.[index]?.schoolDegree}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex justify-end">
            <DeleteRowBtn
              deleteRowFun={removeSchoolRow}
              length={schoolValueLength}
            />
            <AddRowBtn addRowFun={addEmptyRow1} />
          </div>

          <TableContainer component={Paper} className="mt-4">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className=" ">
                  <TableCell align="center" className="px-6">
                    <span className="font-semibold">Physics</span>
                  </TableCell>
                  <TableCell align="center" className="">
                    <span className="font-semibold">Chemistry</span>
                  </TableCell>
                  <TableCell align="center" className="">
                    <span className="font-semibold"> Biology</span>
                  </TableCell>
                  <TableCell align="center" className="">
                    <span className="font-semibold">
                      Average of scientific subjects
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.scientificMarks.value.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      <Input
                        type={"number"}
                        value={row.physics.value}
                        handleChange={(e) => {
                          if (maxLength(e, 5)) return;

                          handleChange(
                            e,
                            "scientificMarks",
                            "value",
                            `${index}`,
                            "physics"
                          );
                          handleMarksChange(index, "physics", e.target.value);
                        }}
                      />
                      {errors.scientificMarksPhysics && (
                        <ErrorMessage
                          errorMessage={errors.scientificMarksPhysics}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Input
                        type={"number"}
                        value={row.chemistry.value}
                        handleChange={(e) => {
                          if (maxLength(e, 5)) return;

                          handleChange(
                            e,
                            "scientificMarks",
                            "value",
                            `${index}`,
                            "chemistry"
                          );
                          handleMarksChange(index, "chemistry", e.target.value);
                        }}
                      />
                      {errors.scientificMarksChemistry && (
                        <ErrorMessage
                          errorMessage={errors.scientificMarksChemistry}
                        />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <Input
                        type={"number"}
                        value={row.biology.value}
                        handleChange={(e) => {
                          if (maxLength(e, 5)) return;

                          handleChange(
                            e,
                            "scientificMarks",
                            "value",
                            `${index}`,
                            "biology"
                          );
                          handleMarksChange(index, "biology", e.target.value);
                        }}
                      />
                      {errors.scientificMarksBiology && (
                        <ErrorMessage
                          errorMessage={errors.scientificMarksBiology}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.average.value !== "" && row.average.value !== 0
                        ? Math.floor(row.average.value * 100) / 100
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CommonAccordion>

        <div className="px-8 pb-3 flex gap-3 my-3 border-b-[1px]">
          <input
            checked={formData.isCollege.value === true}
            type="checkbox"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.checked === false ? null : true
                  }
                },
                "isCollege"
              );
            }}
            name="collegeUniversity"
            id="collegeUniversity"
          />

          <label className="font-semibold" htmlFor="collegeUniversity">
            Have you previously attended any colleges or universities? If yes
            please fill out the details below
          </label>
        </div>
        {formData.isCollege.value === true && (
          <div className="px-8 py-3">
            <Box>
              <h4 className="font-semibold flex flex-col">
                <span>
                  List all Colleges and Universities you attended starting with
                  the most recent (if not applicable, skip this section)
                </span>
                <br />{" "}
                <span className="text-right">
                  قائمة بأسماء الجامعات والكليات المسجل بها سابقا ( ابتدءا من
                  الأحدث)
                </span>
              </h4>
            </Box>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="font-bold">
                        {formLabel.college.name.english}
                      </span>
                      <span className="font-bold"> / </span>
                      <span className="font-bold">
                        {formLabel.college.name.arabic}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-bold">
                        {formLabel.college.country.english}
                      </span>
                      <span className="font-bold"> / </span>
                      <span className="font-bold">
                        {formLabel.college.country.arabic}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-bold">
                        {formLabel.college.gpa.english}
                      </span>
                      <span className="font-bold"> / </span>
                      <span className="font-bold">
                        {formLabel.college.gpa.arabic}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-bold">
                        {formLabel.college.major.english}
                      </span>
                      <span className="font-bold"> / </span>
                      <span className="font-bold">
                        {formLabel.college.major.arabic}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-bold">
                        {formLabel.college.degree.english}
                      </span>
                      <span className="font-bold"> / </span>
                      <span className="font-bold">
                        {formLabel.college.degree.arabic}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.college.value.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 }
                      }}
                    >
                      <TableCell align="center">
                        <Input
                          value={row.name.value}
                          handleChange={(e) => {
                            if (!isNotArabic(e)) return;
                            handleChange(
                              e,
                              "college",
                              "value",
                              `${index}`,
                              "name"
                            );
                          }}
                        />

                        {errors?.college?.[index]?.collegeName && (
                          <ErrorMessage
                            errorMessage={errors?.college?.[index]?.collegeName}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Input
                          value={row.country.value}
                          select={true}
                          option={countryListOptionWithBedoon}
                          customMenuItemClass={customBorderBottom}
                          handleChange={(e) =>
                            handleChange(
                              e,
                              "college",
                              "value",
                              `${index}`,
                              "country"
                            )
                          }
                        />
                        {errors?.college?.[index]?.collegeCountry && (
                          <ErrorMessage
                            errorMessage={
                              errors?.college?.[index]?.collegeCountry
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Input
                          value={row.gpa.value}
                          type={"number"}
                          handleChange={(e) => {
                            if (maxScoreOrGpa(e)) return;
                            handleChange(
                              e,
                              "college",
                              "value",
                              `${index}`,
                              "gpa"
                            );
                          }}
                        />
                        {errors?.college?.[index]?.collegeGpa && (
                          <ErrorMessage
                            errorMessage={errors?.college?.[index]?.collegeGpa}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Input
                          value={row.major.value}
                          handleChange={(e) => {
                            if (!isNotArabic(e)) return;
                            handleChange(
                              e,
                              "college",
                              "value",
                              `${index}`,
                              "major"
                            );
                          }}
                        />{" "}
                        {errors?.college?.[index]?.collegeMajor && (
                          <ErrorMessage
                            errorMessage={
                              errors?.college?.[index]?.collegeMajor
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Input
                          value={row.degree.value}
                          handleChange={(e) => {
                            if (!isNotArabic(e)) return;
                            handleChange(
                              e,
                              "college",
                              "value",
                              `${index}`,
                              "degree"
                            );
                          }}
                        />{" "}
                        {errors?.college?.[index]?.collegeDegree && (
                          <ErrorMessage
                            errorMessage={
                              errors?.college?.[index]?.collegeDegree
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <AddRowBtn addRowFun={addEmptyRow2} /> */}
            <div className="flex justify-end">
              <DeleteRowBtn
                deleteRowFun={removeCollegeRow}
                length={collegeValueLength}
              />
              <AddRowBtn addRowFun={addEmptyRow2} />
            </div>
          </div>
        )}
        <div className="px-8 pb-3 flex gap-3 my-3 border-b-[1px]">
          <input
            checked={formData.isAcademic.value === true}
            type="checkbox"
            onChange={(e) => {
              handleChange(
                {
                  target: {
                    value: e.target.checked === false ? null : true
                  }
                },
                "isAcademic"
              );
            }}
            name="acadamic"
            id="acadamic"
          />

          <label htmlFor="acadamic" className="font-semibold">
            Academic Awards
          </label>
        </div>
        {formData.isAcademic.value === true && (
          <Box className="px-8 border-b-[1px] pb-3">
            <h4 className="font-semibold flex flex-col">
              <span>Academic Prizes (Indicate years)</span>
              <br />
              <span className="text-right">
                اذكر، أن وجد، الأعمال الأكاديمية المتميزة التي قمت بها أو
                الجوائز العلمية الحاصل عليها. مع ذكر السنة
              </span>
            </h4>
            <div>
              {formData.archivements.value.map((row, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <Input
                      sx={{ padding: "1rem" }}
                      value={row.value}
                      handleChange={(e) => {
                        if (!isNotArabic(e)) return;
                        handleChange(e, "archivements", "value", `${index}`);
                      }}
                    />

                    {errors?.isAcademic?.[index]?.archivements && (
                      <ErrorMessage
                        errorMessage={errors?.isAcademic?.[index]?.archivements}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end">
              <DeleteRowBtn
                deleteRowFun={removeAchievementRow}
                length={achievementValueLength}
              />
              <AddRowBtn addRowFun={addEmptyRow3} />
            </div>
          </Box>
        )}

        <Box className="px-4">
          <AdmissionForm5
            errors={errors}
            formData={formData}
            handleChange={handleChange}
          />
        </Box>
      </Box>
      {/* <CommonAccordion
        defaultExpanded={true}
        arabicLabel={formLabel.headings.languageDetails.arabic}
        englishLabel={formLabel.headings.languageDetails.english}
      >
        <Box>
          <h4
            style={{ color: "black", fontWeight: "600", fontSize: "1rem" }}
            className="form mb-2 flex justify-between"
          >
            <span>{formLabel.languages.title.english}</span>
            <span>{formLabel.languages.title.arabic}</span>
          </h4>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <span className="font-bold">
                    {formLabel.languages.subTitle.english}
                  </span>
                  <span className="font-bold"> / </span>
                  <span className="font-bold">
                    {formLabel.languages.subTitle.arabic}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-bold">
                    {formLabel.languages.read.english}
                  </span>
                  <span className="font-bold"> / </span>
                  <span className="font-bold">
                    {formLabel.languages.read.arabic}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-bold">
                    {formLabel.languages.write.english}
                  </span>
                  <span className="font-bold"> / </span>
                  <span className="font-bold">
                    {formLabel.languages.write.arabic}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-bold">
                    {formLabel.languages.speak.english}
                  </span>
                  <span className="font-bold"> / </span>
                  <span className="font-bold">
                    {formLabel.languages.speak.arabic}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    {row.read}
                    {errors?.languages?.[index]?.languageRead && (
                      <ErrorMessage
                        errorMessage={errors?.languages?.[index].languageRead}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.write}{" "}
                    {errors?.languages?.[index]?.languageWrite && (
                      <ErrorMessage
                        errorMessage={errors?.languages?.[index].languageWrite}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.speak}
                    {errors?.languages?.[index]?.languageSpeak && (
                      <ErrorMessage
                        errorMessage={errors?.languages?.[index].languageSpeak}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CommonAccordion> */}
      <CommonAccordion title="English professionally test">
        <TableContainer component={Paper} className="mt-4">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>
                  <span className="font-semibold">
                    English Proficiency Test
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-semibold">Score</span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-semibold">Date</span>
                </TableCell>
                <TableCell align="right">
                  <span className="font-semibold">Test Type</span>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right">
                  <Input
                    type={"number"}
                    value={formData.test.testScore.value}
                    handleChange={(e) => {
                      handleChange(e, "test", "testScore");
                    }}
                    disabled={
                      formData.test.testType.value === "NA" ? true : false
                    }
                  />
                  {errors.testScore && (
                    <ErrorMessage errorMessage={errors.testScore} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Input
                    type={"date"}
                    value={formData.test.testDate.value}
                    handleChange={(e) => {
                      if (new Date(e.target.value).getFullYear() > 2099) return;
                      handleChange(e, "test", "testDate");
                    }}
                    disabled={
                      formData.test.testType.value === "NA" ? true : false
                    }
                  />
                  {errors.testDate && (
                    <ErrorMessage errorMessage={errors.testDate} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Input
                    select={true}
                    // disabled={true}
                    type="select"
                    option={formData.test.testType.option}
                    value={formData.test.testType.value}
                    handleChange={(e) => {
                      handleChange(e, "test", "testType");
                      setTestType(e.target.value);
                    }}
                  />
                  {errors.testType && (
                    <ErrorMessage errorMessage={errors.testType} />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        {testType === "other" && (
          <div className="w-full mt-3">
            <div className="flex items-center justify-between">
              <p className="my-2 text-gray-500 font-semibold">Please specify</p>
              {errors.testTypeOtherNote && (
                <ErrorMessage
                  alignRight="text-right"
                  errorMessage={errors.testTypeOtherNote}
                />
              )}
            </div>

            <textarea
              placeholder="Describe"
              value={formData.testTypeOtherNote.value}
              onChange={(e) => {
                handleChange(e, "testTypeOtherNote");
              }}
              className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
            />
          </div>
        )}
      </CommonAccordion>
    </Box>
  );
};

const AddRowBtn = ({ addRowFun }) => {
  return (
    <div className="flex justify-end p-4 mt-2">
      <button
        type={"button"}
        className="flex gap-1 items-center p-2 border-2 border-[#b70042] text-[#b70042] font-bold rounded-lg uppercase hover:bg-[#b70042] hover:text-white"
        onClick={() => addRowFun()}
      >
        <PiPlusBold className="text-xl font-bold" /> <span>Add row</span>
      </button>
    </div>
  );
};

const DeleteRowBtn = ({ deleteRowFun, length }) => {
  return (
    <div className="flex justify-end p-4 mt-2">
      <button
        disabled={length === 1 ? true : false}
        type={"button"}
        className={`flex gap-1 items-center p-2 border-2  border-[#b70042] bg-[#b70042] text-white font-bold rounded-lg uppercase hover:bg-transparent ${
          length > 1 && "hover:text-[#B70042]"
        } disabled:bg-slate-500 disabled:border-slate-500 cursor-pointer`}
        onClick={() => deleteRowFun()}
      >
        <AiOutlineDelete className="text-xl font-bold" />{" "}
        <span>Delete row</span>
      </button>
    </div>
  );
};
export default AdmissionForm4;
