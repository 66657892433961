import React from "react";

const AdmissionFormStatus = ({ icon, message }) => {
  return (
    <div
      className=" bg-[#e9ecef] flex items-center justify-center min-h-screen"
      style={{
        backgroundColor: "#fff",
        backgroundImage:
          "radial-gradient(rgba(0, 0, 0,.5) 10%, transparent 10%)",
        backgroundPosition: "0% 0%",
        backgroundSize: "1rem 1rem"
      }}
    >
      <div className="flex flex-col items-center justify-center bg-white rounded-lg drop-shadow-2xl px-8 md:gap-6 gap-2 py-10">
        {icon}
        <p className="text-2xl font-bold md:text-[30px] uppercase">{message}</p>
      </div>
    </div>
  );
};

export default AdmissionFormStatus;
