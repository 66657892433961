// import { initialFormData } from "./formData";

export const validateForm = (formData, setErrors, step) => {
  const newErrors = {};
  if (step === 0) {
    if (!formData.firstName.value) {
      newErrors.firstName = "First name is required";
    }
    if (!formData.middleName.value) {
      newErrors.middleName = "Middle name is required";
    }
    if (!formData.lastName.value) {
      newErrors.lastName = "Last name is required";
    }

    if (!formData.arabicFirstName.value) {
      newErrors.arabicFirstName = "Arabic First name is required";
    }
    if (!formData.arabicMiddleName.value) {
      newErrors.arabicMiddleName = "Arabic Middle name is required";
    }
    if (!formData.arabicLastName.value) {
      newErrors.arabicLastName = "Arabic Last name is required";
    }

    if (!formData.dob.value) {
      newErrors.dob = "Date of birth is required";
    }
    if (!formData.gender.value) {
      newErrors.gender = "Gender is required";
    }
    if (!formData.maritalStatus.value) {
      newErrors.maritalStatus = "Marital status is required";
    }
    if (!formData.civilId.value) {
      newErrors.civilId = "Civil ID is required";
    }
    if (!formData.civilIdExpiryDate.value) {
      newErrors.civilIdExpiryDate = "Civil ID expiry date is required";
    }
    if (!formData.mobileOne.value) {
      newErrors.mobileOne = "Mobile number is required";
    }
    if (!formData.homeTelOne.value) {
      newErrors.homeTelOne = "Home telephone number is required";
    }
    if (!formData.emailOne.value) {
      newErrors.emailOne = "Email address is required";
    }
    if (!formData.nationality.value) {
      newErrors.nationality = "Nationality is required";
    }
    if (!formData.birthCountry.value) {
      newErrors.birthCountry = "Birth country is required";
    }
    console.log(newErrors);
    if (formData.nationality.value === "Non-Kuwaiti") {
      if (!formData.securityCard.value) {
        newErrors.securityCard = "Security card is required";
      }
      if (!formData.securityCardExpiry.value) {
        newErrors.securityCardExpiry = "Security card expiry date is required";
      }
    }

    console.log(newErrors);

    // if (formData.nationality.value === "BN") {
    //   if (!formData.securityCard.value) {
    //     newErrors.securityCard = "Security card is required";
    //   }
    //   if (!formData.securityCardExpiry.value) {
    //     newErrors.securityCardExpiry = "Security card expiry date is required";
    //   }
    // }
    //  else {
    //   if (!formData.passport.value) {
    //     newErrors.passport = "Passport number is required";
    //   }
    //   if (!formData.passportExpiry.value) {
    //     newErrors.passportExpiry = "Passport expiry date is required";
    //   }
    // }

    if (!formData.address.block.value) {
      newErrors.addressBlock = "Block is required";
    }
    if (!formData.address.street.value) {
      newErrors.addressStreet = "Street is required";
    }
    if (!formData.address.appartment.value) {
      newErrors.addressAppartment = "Apartment is required";
    }
    if (!formData.address.area.value) {
      newErrors.addressArea = "Area is required";
    }
    if (!formData.address.jaddah.value) {
      newErrors.addressJaddah = "Jaddah is required";
    }
    if (!formData.address.country.value) {
      newErrors.addressCountry = "Country is required";
    }
    if (!formData.address.po.value) {
      newErrors.addressPo = "Postal office is required";
    }
    if (!formData.address.zipCode.value) {
      newErrors.addressZipCode = "Zip code is required";
    }
    if (!formData.address.governorate.value) {
      newErrors.addressGovernorate = "Governorate is required";
    }
    if (!formData.mobileOne.value) {
      newErrors.mobileOne = "Mobile number is required";
    }
    if (!formData.homeTelOne.value) {
      newErrors.homeTelOne = "Telephone number is required";
    }

    if (!formData.emailOne.value) {
      newErrors.emailOne = "Email is required";
    }
  }

  if (step === 1) {
    if (!formData.family.fatherName.value) {
      newErrors.fatherName = "Father name is required";
    }

    // if (!formData.family.arabicFatherName.value) {
    //   newErrors.arabicFatherName = "Arabic Father name is required";
    // }
    // if (!formData.family.fatherDob.value) {
    //   newErrors.fatherDob = "Father date of birth is required";
    // }

    // if (!formData.family.fatherNationality.value) {
    //   newErrors.fatherNationality = "Father nationality is required";
    // }

    // if (!formData.family.fatherCivilId.value) {
    //   newErrors.fatherCivilId = "Father Civil ID is required";
    // }

    if (!formData.family.fatherOccup.value) {
      newErrors.fatherOccup = "Father occupation is required";
    }

    if (!formData.family.fatherMobile.value) {
      newErrors.fatherMobile = "Father mobile number is required";
    }

    // if (!formData.family.fatherWorkTel.value) {
    //   newErrors.fatherWorkTel = "Father work telephone number is required";
    // }

    if (!formData.family.fatherDepartment.value) {
      newErrors.fatherDepartment = "Father department is required";
    }

    if (!formData.family.motherName.value) {
      newErrors.motherName = "Mother name is required";
    }

    // if (!formData.family.arabicMotherName.value) {
    //   newErrors.arabicMotherName = "Arabic Mother name is required";
    // }

    // if (!formData.family.motherDob.value) {
    //   newErrors.motherDob = "Mother date of birth is required";
    // }

    // if (!formData.family.motherNationality.value) {
    //   newErrors.motherNationality = "Mother nationality is required";
    // }

    // if (!formData.family.motherCivilId.value) {
    //   newErrors.motherCivilId = "Mother Civil ID is required";
    // }

    if (!formData.family.motherOccup.value) {
      newErrors.motherOccup = "Mother occupation is required";
    }

    if (!formData.family.motherMobile.value) {
      newErrors.motherMobile = "Mother mobile number is required";
    }

    if (
      formData.relatives.value.filter(
        (data) => data.value === formData.relatives.selected.value
      )?.length === 0
    ) {
      newErrors.relatives = "Please select one of the above";
    }

    if (formData.relatives.selected.value === "mohe") {
      if (!formData.relativesNote.value) {
        newErrors.relativesNote = "Note is required";
      }
    }
    if (formData.relatives.selected.value === "aums") {
      if (!formData.relativesNote.value) {
        newErrors.relativesNote = "Note is required";
      }
    }

    // if (!formData.family.motherWorkTel.value) {
    //   newErrors.motherWorkTel = "Mother work telephone number is required";
    // }

    // if (!formData.family.motherEmail.value) {
    //   newErrors.motherEmail = "Mother email is required";
    // }

    if (formData.sponsorship.value === null) {
      newErrors.sponser = "Please select any one of these";
    }

    // sponsorship: Object.keys(formData.sponsorship.value || {})?.[0],
    if (Object.keys(formData.sponsorship.value || {})?.[0] === "others") {
      if (!formData.sponsorshipOtherNote.value) {
        newErrors.sponsorshipOtherNote = "Note is required";
      }
    }
    if (Object.keys(formData.sponsorship.value || {})?.[0] === "ministry") {
      if (!formData.sponsorshipScholarshipNote.value) {
        newErrors.sponsorshipScholarshipNote = "Note is required";
      }
    }

    console.log(newErrors);
  }
  if (step === 2) {
    if (formData.isHealthRecord.value === null) {
      newErrors.healthError = "Please select Yes or No";
    } else {
      if (formData.isHealthRecord.value === true) {
        if (!formData.healthNote.value) {
          newErrors.healthNote = "Health note is required";
        }
      }
    }
  }

  if (step === 3) {
    const school = [];
    formData.school.value.map((data, index) => {
      school.push({});
      if (!data.name.value) {
        school[index].schoolName = "School name is required";
      }
      if (!data.gpa.value) {
        school[index].schoolGpa = "GPA is required";
      }
      if (!data.date.value) {
        school[index].schoolDate = "Date is required";
      }

      if (!data.degree.value) {
        school[index].schoolDegree = "Degree is required";
      }

      return school;
    });

    school.map((data, index) => {
      if (Object.keys(school[index]) != 0) {
        newErrors.school = school;
      }
    });

    if (!formData.scientificMarks.value[0].physics.value) {
      newErrors.scientificMarksPhysics = "Physics marks is required";
    }

    if (!formData.scientificMarks.value[0].chemistry.value) {
      newErrors.scientificMarksChemistry = "Chemistry marks is required";
    }
    if (!formData.scientificMarks.value[0].biology.value) {
      newErrors.scientificMarksBiology = "Biology marks is required";
    }

    if (formData.isCollege.value === true) {
      const college = [];
      formData.college.value.map((data, index) => {
        college.push({});
        if (!data.name.value) {
          college[index].collegeName = "College name is required";
        }
        if (!data.gpa.value) {
          college[index].collegeGpa = "GPA is required";
        }
        if (!data.country.value) {
          college[index].collegeCountry = "Country is required";
        }
        if (!data.major.value) {
          college[index].collegeMajor = "Major is required";
        }
        if (!data.degree.value) {
          college[index].collegeDegree = "Degree is required";
        }

        return college;
      });
      college.map((data, index) => {
        if (Object.keys(college[index]) != 0) {
          newErrors.college = college;
        }
      });
    }

    if (formData.isAcademic.value === true) {
      const isAcademic = [];
      formData.archivements.value.map((data, index) => {
        isAcademic.push({});
        if (!data.value) {
          isAcademic[index].archivements = "Achievements is required";
        }
        return isAcademic;
      });

      isAcademic.map((data, index) => {
        if (Object.keys(isAcademic[index]) != 0) {
          newErrors.isAcademic = isAcademic;
        }
      });
    }

    if (formData.isSuspended.value === null) {
      newErrors.isSuspended = "Please select Yes or No";
    } else {
      if (formData.isSuspended.value === true) {
        if (!formData.suspensionNote.value) {
          newErrors.suspensionNote = "Suspended reason is required";
        }
      }
    }

    // const languages = [];
    // formData.languages.map((language, index) => {
    //   languages.push({});
    //   if (language.language.value !== "") {
    //     if (!language.read.value) {
    //       languages[index].languageRead = "Please select any one of these";
    //     }

    //     if (!language.write.value) {
    //       languages[index].languageWrite = "Please select any one of these";
    //     }
    //     if (!language.speak.value) {
    //       languages[index].languageSpeak = "Please select any one of these";
    //     }
    //   }
    // });
    // languages.map((data, index) => {
    //   if (Object.keys(languages[index]) != 0) {
    //     newErrors.languages = languages;
    //   }
    // });

    if (formData.test.testType.value !== "NA") {
      if (!formData.test.testScore.value) {
        newErrors.testScore = "Test score is required";
      }

      if (!formData.test.testDate.value) {
        newErrors.testDate = "Test date is required";
      }
    }
    if (!formData.test.testType.value) {
      newErrors.testType = "Test type is required";
    }

    if (formData.test.testType.value === "other") {
      if (!formData.testTypeOtherNote.value) {
        newErrors.testTypeOtherNote = "Please specify";
      }
    }
  }

  // if (step === 4) {
  //   const emergencies = [];
  //   emergencies.push({});
  //   if (!formData.emergency[0].name.value) {
  //     emergencies[0].emergencyName = "Emergency person name is required";
  //   }

  //   if (!formData.emergency[0].relationship.value) {
  //     emergencies[0].emergencyRelationship =
  //       "Emergency person relationship is required";
  //   }

  //   if (!formData.emergency[0].civilId.value) {
  //     emergencies[0].emergencyCivilId = "Emergency person Civil ID is required";
  //   }

  //   if (!formData.emergency[0].contact.value) {
  //     emergencies[0].emergencyContact = "Emergency person contact is required";
  //   }
  //   if (Object.keys(emergencies[0]) != 0) {
  //     newErrors.emergencies = emergencies;
  //   }

  //   if (formData.emergency[1].personTwo.value === true) {
  //     emergencies.push({});
  //     if (!formData.emergency[1].name.value) {
  //       emergencies[1].emergencyName = "Emergency person name is required";
  //     }

  //     if (!formData.emergency[1].relationship.value) {
  //       emergencies[1].emergencyRelationship =
  //         "Emergency person relationship is required";
  //     }

  //     if (!formData.emergency[1].civilId.value) {
  //       emergencies[1].emergencyCivilId =
  //         "Emergency person Civil ID is required";
  //     }

  //     if (!formData.emergency[1].contact.value) {
  //       emergencies[1].emergencyContact =
  //         "Emergency person contact is required";
  //     }
  //     if (Object.keys(emergencies[1]) != 0) {
  //       newErrors.emergencies = emergencies;
  //     }
  //   }

  //   if (!formData.isDeclared.value) {
  //     newErrors.isDeclared = "Please confirm";
  //   }
  // }

  if (step === 4) {
    if (formData.nationality.value === "Non-Kuwaiti") {
      if (!formData.document.securityCardFront.value) {
        newErrors.securityCardFront = "Security Card front copy is required";
      }

      if (!formData.document.securityCardBack.value) {
        newErrors.securityCardBack = "Security Card back copy is required";
      }
    } else {
      if (!formData.document.civilidFront.value) {
        newErrors.civilIdFront = "Civil ID front copy is required";
      }

      if (!formData.document.civilidBack.value) {
        newErrors.civilIdBack = "Civil ID back copy is required";
      }
    }
  }

  if (step === 5) {
    if (formData.eligible.value === null) {
      newErrors.eligible = "Please confirm";
    }

    // if (
    //   formData.relatives.value.filter(
    //     (data) => data.value === formData.relatives.selected.value
    //   )?.length === 0
    // ) {
    //   newErrors.relatives = "Please select one of the above";
    // }
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

export const validateDocument = (formData, setErrors) => {
  const newErrors = {};

  if (formData.nationality.value === "Non-Kuwaiti") {
    if (!formData.document.securityCardFront.value) {
      newErrors.securityCardFront = "Security Card front copy is required";
    }

    if (!formData.document.securityCardBack.value) {
      newErrors.securityCardBack = "Security Card back copy is required";
    }
  } else {
    if (!formData.document.civilidFront.value) {
      newErrors.civilIdFront = "Civil ID front copy is required";
    }

    if (!formData.document.civilidBack.value) {
      newErrors.civilIdBack = "Civil ID back copy is required";
    }
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
