import React, { useEffect, useState } from "react";
import { acknowledgment } from "../utils/acknowledgment";
import logo from "../assets/AUSM-LOGO.png";
const Acknowledgment = ({ stepHandler }) => {
  const [confirm, setConfirm] = useState(false);

  const handleChange = () => {
    setConfirm((current) => !current);
  };

  const handleConfirm = () => {
    if (confirm) {
      stepHandler();
    }
  };

  useEffect(() => {
    document.title = "AUMS • Acknowledgment";
  }, []);

  return (
    <div className="bg-[#e9ecef] min-h-screen w-full flex items-center justify-center md:p-24 p-4 acknowledgment">
      <div className="bg-white md:w-3/5 w-max-content h-auto rounded-2xl p-4">
        <ArabicAcknowledgment />

        <div>
          <div className="my-2 flex gap-2 font-bold">
            <input
              checked={confirm}
              type="checkbox"
              name="isAcknowledgmentDeclare"
              id="isAcknowledgmentDeclare"
              onChange={handleChange}
            />
            <label htmlFor="isAcknowledmentDeclare" onClick={handleChange}>
              Please read the above declaration and acknowledge it by checking
              the checkbox.
            </label>
          </div>
          <button
            disabled={!confirm}
            onClick={handleConfirm}
            className="bg-[#B70042]  disabled:bg-gray-300 disabled:text-gray-600 text-white font-semibold px-6 py-3 w-full rounded-xl"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const ArabicAcknowledgment = () => {
  return (
    <div>
      {acknowledgment.map((content, index) => {
        return (
          <div key={index}>
            {/* <div className="bg-[#b70042] rounded-xl">
              <img
                src={logo}
                alt=""
                className="h-full w-[500px] mx-auto mb-4 object-cover rounded-xl"
              />
            </div> */}
            <div className="bg-[#B70042] text-white rounded-xl p-3 acknowledgment-header">
              <h1 className="font-bold uppercase text-2xl text-center">
                {content.title}
              </h1>
            </div>
            <div className="p-3 mt-3  bg-slate-100 rounded-xl ">
              <p className="px-2 mb-2 font-semibold  text-slate-700 text-justify">
                {content.text}
              </p>
              <div className="font-semibold bg-white p-3 border-[1px] border-slate-200 rounded-lg text-md text-justify text-slate-800">
                <span>{content.subtext}</span>
              </div>
            </div>

            {content.importantNotes.map((importantNote, index) => {
              return (
                <details
                  open
                  key={index}
                  className=" bg-slate-100 p-3 mt-3 rounded-xl"
                >
                  <summary className="cursor-pointer font-bold text-lg bg-[#B70042] p-3 rounded-lg border-[1px] border-slate-200  text-white uppercase">
                    {importantNote.title}
                  </summary>
                  <div className="border-[1px] border-[#B70042] list-decimal font-semibold px-3 bg-white py-3 rounded-lg  mt-2">
                    <p className="text-center text-lg">
                      {importantNote.subTitle}
                    </p>
                    <p className="text-center text-[#B70042] font-black text-xl uppercase my-2">
                      {importantNote.for}
                    </p>
                    <ul>
                      {importantNote.points.map((point, index) => {
                        return (
                          <li
                            key={index}
                            className="border-[1px] border-[#B70042]  text-justify px-3 bg-white py-3 rounded-lg mb-2"
                          >
                            <span className="font-bold">{point.text}</span>
                            <span className="block text-justify">
                              {point.content?.length > 0 && point.content}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                    <p className="text-center bg-[#B70042] text-slate-100 font-extrabold text-lg uppercase my-2 border-[2px] rounded-lg py-2 border-[#B70042] hidden">
                      ANNUAL FEES{" "}
                      <span className="text-3xl text-white ">
                        {importantNote.annualFees.text}.
                      </span>{" "}
                      (SIX YEAR PROGRAM)
                    </p>
                    <p className="text-justify text-slate-700 font-bold text-lg  my-2 ">
                      {importantNote.importantPoints.text}
                    </p>
                  </div>
                </details>
              );
            })}

            {content.termsAndConditions.map((term, index) => {
              return (
                <details
                  open
                  key={index}
                  className=" bg-slate-100 p-3 mt-3 rounded-xl"
                >
                  <summary className="cursor-pointer font-bold text-lg bg-white p-3 rounded-lg border-[1px] border-slate-200  text-slate-700 ">
                    {term.title}
                  </summary>
                  <ol className="border-[1px] border-slate-200 list-decimal font-semibold px-7 bg-white py-3 rounded-lg text-justify mt-2">
                    {term.conditions.map((condition, index) => {
                      return <li key={index}>{condition.text}</li>;
                    })}
                  </ol>
                </details>
              );
            })}

            {content.notes.map((note, index) => {
              return (
                <details
                  open
                  key={index}
                  className="mt-4 bg-slate-100 rounded-lg p-3"
                >
                  <summary className="bg-white p-3 border-[1px] font-bold text-lg text-slate-700 border-slate-200 rounded-lg  cursor-pointer">
                    {note.title}
                  </summary>
                  <ul className="border-[1px] border-slate-200 list-decimal font-semibold px-7 bg-white py-3 rounded-lg text-justify mt-2">
                    {note.points.map((point, index) => {
                      return (
                        <li className="list-disc" key={index}>
                          {point.text}
                        </li>
                      );
                    })}
                  </ul>
                </details>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Acknowledgment;
