import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";

const ErrorTitle = () => {
    return (
        <div className="bg-[#ffccd5] w-full rounded-lg my-4 px-3 py-2 font-bold text-[#c9184a] border-[1px] border-[#ff8fa3] uppercase">
            <p className="w-full flex  items-center gap-3">
                <RiErrorWarningLine className="text-2xl" /> please fill all required fields
            </p>
        </div>
    );
};

export default ErrorTitle;
