import React, { useEffect, useState } from "react";
import { AiTwotoneMail } from "react-icons/ai";
import Spinner from "../components/Spinner";
import SubmitOTP from "./SubmitOTP";
import { RiErrorWarningLine } from "react-icons/ri";
import { BASE_URL } from "../utils/config";

const EmailVerification = ({ email, handleChange, stepHandler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [isOTP, setIsOTP] = useState(false);

  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  const handleEmailVerify = async (e) => {
    e.preventDefault();

    try {
      if (!isValidEmail(email.value)) {
        setIsError("Invalid Email: Please enter a valid email");
        return;
      }
      setIsLoading(true);
      setTimeout(() => {
        setIsOTP(true);
        setIsLoading(false);
      }, 500);
      await fetch(`${BASE_URL}/service/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          to: email.value,
          subject: "OTP Verification",
          type: "OTP"
        })
      });
    } catch (error) {
      setIsLoading(false);
      setIsError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "AUMS • Email verification";
  }, []);

  return (
    <>
      {isOTP ? (
        <SubmitOTP stepHandler={stepHandler} email={email.value} />
      ) : (
        <div
          // style={{
          //   background:
          //     "linear-gradient(to bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.90) 100%), url('/form.png')",
          //   backgroundPosition: "center",
          //   backgroundSize: "cover",
          //   backgroundRepeat: "no-repeat",
          //   backgroundAttachment: "fixed"
          // }}
          className="w-screen h-screen bg-gradient-to-r from-[#ef233c] to-[#B70042] bg-[#B70042]  flex items-center justify-center"
        >
          <div className="w-96 md:w-[600px] bg-[#edf2f4] rounded-2xl shadow-xl md:px-10 px-4 flex flex-col items-start justify-center gap-4 py-6">
            <div className="flex w-full items-center justify-center flex-col">
              <AiTwotoneMail className="text-[100px] text-[#B70042]" />
              <h1 className="md:text-3xl text-2xl font-bold font-sans">
                Email verification
              </h1>
              <p className="font-semibold text-[#5d6677]">
                Enter your email for verification.
              </p>
            </div>
            <div className="w-full">
              <form
                onSubmit={handleEmailVerify}
                className="w-[100%] flex items-center gap-2 "
                noValidate
              >
                <input
                  type="email"
                  value={email.value}
                  autoFocus
                  onChange={(e) => handleChange(e, "emailOne")}
                  placeholder="Enter your email"
                  className="px-6 py-3 flex-1 rounded-xl outline-0"
                />
                <button
                  disabled={isLoading || !email.value}
                  className="px-4 py-3 rounded-xl disabled:bg-gray-300 disabled:text-gray-600 bg-[#2b2d42] text-white font-bold hover:bg-[#8d99ae] hover:text-[#2b2d42]"
                >
                  Verify
                </button>
              </form>
            </div>
            {isError && (
              <div className="font-bold w-full rounded-xl p-4 bg-[#ffccd5] text-[#B70042]">
                <p className="flex items-center gap-1">
                  <RiErrorWarningLine className="text-[15.5px] md:text-xl" />
                  <span className="text-[12.5px] font-bold md:text-[15px]">
                    {isError}
                  </span>
                </p>
              </div>
            )}
          </div>
          {isLoading && <Spinner />}
        </div>
      )}
    </>
  );
};

export default EmailVerification;
