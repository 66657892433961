import React from 'react'

const FormFieldLabel = ({ englishLabel, arabicLabel }) => {
    return (
        <label className="font-semibold flex justify-between">
            <span>{englishLabel}</span>
            <span>{arabicLabel}</span>
        </label>
    )
}

export default FormFieldLabel