import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Route, Routes } from "react-router-dom";
import ConfirmationPage from "./pages/ConfirmationPage";
import AdmissionFormPage from "./routePages/AdmissionFormPage";
import DocumentPage from "./pages/DocumentPage";
import CompleteDocumentPage from "./pages/CompeletPage";

// const router = createBrowserRouter([
//   {
//     path: "/admission",
//     element: <AdmissionFormPage />
//   },
//   {
//     path: "/:id",
//     element: <ConfirmationPage />
//   },
//   {
//     path: "*",
//     element: <AdmissionFormPage />
//   }
// ]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<AdmissionFormPage />} />
        <Route path="/:id" element={<ConfirmationPage />} />
        <Route path="/document/:id" element={<DocumentPage />} />
        {/* <Route path="/all-document/:id" element={<CompleteDocumentPage />} /> */}
        {/* <Route path="*" element={<AdmissionFormPage />} /> */}
      </Routes>
    </HashRouter>
    {/* <RouterProvider router={router} /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
