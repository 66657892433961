import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "./ErrorMessage";
import { isNotArabic } from "../utils/methods";
const AdmissionForm5 = ({ formData, handleChange, errors }) => {
  const CustomCheckboxYes = styled(Checkbox)(({ theme }) => ({
    color: "#B70042", // Set the color of the checkbox itself
    "&.Mui-checked": {
      color: "#b70042" // Change color when checked
    }
  }));

  const CustomCheckboxNo = styled(Checkbox)(({ theme }) => ({
    color: "#B70042", // Set the color of the checkbox itself
    "&.Mui-checked": {
      color: "#b70042" // Change color when checked
    }
  }));
  return (
    <Box>
      <Box sx={{ padding: "1rem", fontSize: "1.2rem", fontWeight: "1rem" }}>
        <h4 className="font-semibold flex flex-col">
          <span>
            Have you ever been subject to any academic or disciplinary action
            (i.e. probation, suspension, dismissal etc..) From any institution
            attended?
          </span>
          <br />{" "}
          <span className="text-right">
            هل سبق لك خضعت لأية إجراءات تأديبية أو أكاديمية او وضعت لتحت
            المراقبة، او تعليق القيد ، او الطرد من أي مؤسسة تعليمية من قبل؟
          </span>
        </h4>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <input
              checked={formData.isSuspended.value === true}
              type="checkbox"
              onChange={(e) => {
                handleChange(
                  {
                    target: { value: e.target.checked === true ? true : null }
                  },
                  "isSuspended"
                );
              }}
            />
            <label htmlFor="isHealthRecord" className="ml-2 font-bold text-lg">
              {formLabel.suspendedDetails.isSuspended.yes.english} /
              {formLabel.suspendedDetails.isSuspended.yes.arabic}
            </label>
          </div>

          <div>
            <input
              type="checkbox"
              checked={formData.isSuspended.value === false}
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      value: e.target.checked === true ? false : null
                    }
                  },
                  "isSuspended"
                );
              }}
            />
            <label htmlFor="isHealthRecord" className="ml-2 font-bold text-lg">
              {formLabel.suspendedDetails.isSuspended.no.english} /
              {formLabel.suspendedDetails.isSuspended.no.arabic}
            </label>
          </div>
          {errors.isSuspended && (
            <ErrorMessage errorMessage={errors.isSuspended} />
          )}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            padding: "1rem"
          }}
        >
          <h4
            style={{ color: "black", fontWeight: "600", fontSize: "1rem" }}
            className="form mb-2 flex justify-between"
          >
            <span>
              {formLabel.suspendedDetails.suspensionNoteTitle.english}
            </span>
            <span>{formLabel.suspendedDetails.suspensionNoteTitle.arabic}</span>
          </h4>
          <TextField
            disabled={!formData.isSuspended.value}
            // required={formData.isSuspended.value}
            value={formData.suspensionNote.value}
            onChange={(e) => {
              if (!isNotArabic(e)) return;

              handleChange(e, "suspensionNote");
            }}
            multiline
            rows={4}
            size={"small"}
            color="primary"
            // id="outlined-start-adornment"
            // sx={{ m: 1, width: '100%' }}
            sx={{
              width: "100%",

              borderColor: "lightgray",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  // height: "8rem",
                  borderColor: "none",
                  borderRadius: "12px" // Specify your desired border color here
                },
                //   '&:hover fieldset': {
                //     borderColor: 'green', // Specify your desired hover border color here
                //   },
                "&.Mui-focused fieldset": {
                  borderColor: "black" // Specify your desired focused border color here
                }
              }
            }}
          />
          {errors.suspensionNote && (
            <ErrorMessage errorMessage={errors.suspensionNote} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AdmissionForm5;
