import { initialFormData } from "./formData";

export const formLabel = {
  headings: {
    personalPageTitle: {
      english: "Personal Details of Applicant",
      arabic: "المعلومات الشخصية للطالب المتقدم"
    },
    familyPageTitle: {
      english: "Family Background",
      arabic: "معلومات عن الوالدين"
    },
    healthPageTitle: {
      english: "Health Information",
      arabic: "معلومات عن الحالة الصحية للطالب"
    },
    academicPageTitle: {
      english: "Educational Information",
      arabic: " المعلومات العلمية"
    },
    emergencyPageTitle: {
      english: "Emergency Contact",
      arabic: "معلومات للطوار"
    },
    documentPageTitle: {
      english: "Document",
      arabic: "وثيقة"
    },
    personalDetails: {
      english: "Personal Details of Applicant",
      arabic: "المعلومات الشخصية للطالب المتقدم"
    },
    nationalityResidency: {
      english: "Nationality and Residency",
      arabic: "الجنسية والإقامة:جنسية والمحافة"
    },
    addressDetails: {
      english: "Address Information",
      arabic: "معلومات السكن"
    },
    contactDetails: {
      english: "Contact Information",
      arabic: "معلومات الاتصال"
    },
    fatherDetails: {
      english: "Father Details",
      arabic: "معلومات الأب"
    },
    motherDetails: {
      english: "Mother Details",
      arabic: "معلومات الأم"
    },
    financialDetails: {
      english: "Sponsorship",
      arabic: "المصاريف الدراسية"
    },
    academicDetails: {
      english: "Secondary School Education Details",
      arabic: "معلومات عن التعليم الثانويالمقررات"
    },
    languageDetails: {
      english: "Language Proficiency",
      arabic: "المهارات اللغوية "
    }
  },

  firstName: {
    english: "First Name",
    arabic: "اسم الطالب"
  },
  middleName: {
    english: "Middle Name",
    arabic: "اسم الأب"
  },
  lastName: {
    english: "Family Name",
    arabic: "اسم العائلة"
  },
  dob: {
    english: "Date of Birth",
    arabic: "تاريخ الميلاد"
  },
  gender: {
    english: "Gender",
    arabic: "الجنس"
  },
  maritalStatus: {
    english: "Marital Status:",
    arabic: "الحالة الإجتماعية"
  },
  civilId: {
    english: "Civil ID Number",
    arabic: "الرقم المدني"
  },
  civilIdExpiryDate: {
    english: "Civil ID Expiry Date",
    arabic: ""
  },

  contactInfo: {
    mobileOne: {
      english: "Mobile No.",
      arabic: "رقم الهاتف الجوال"
    },
    homeTelNo: {
      english: "Home Telephone Number",
      arabic: "رقم الهاتف المنزلي"
    },
    emailAddress: {
      english: "E-Mail",
      arabic: "البريد الإلكتروني"
    }
  },

  nationality: {
    english: "Country of Nationality",
    arabic: "الجنسية"
  },
  birthCountry: {
    english: "Country of Birth",
    arabic: "الميلاد"
  },
  passport: {
    english: "Passport Number",
    arabic: "رقم جواز السفر"
  },
  passportExpiry: {
    english: "",
    arabic: ""
  },
  residency: {
    english: "",
    arabic: ""
  },
  securityCard: {
    english: "Security Card Number",
    arabic: "رقم البطاقة الأمنية للمقيمين بصورة غير قانونية"
  },
  securityCardExpiry: {
    english: "",
    arabic: ""
  },
  healthDetails: {
    title: {
      english:
        "Do you suffer from any physical, learning, mental, and disabilities? If yes, please describe and provide a medical report stamped from a recognized official institution.",
      arabic:
        "هل تعاني من أي حالة مرضية جسدية أو نفسية أو إعاقة ؟اذا كانت الإجابة بنعم؛ الرجاء ذكر نوع الحالة المرضية وارفاق تقرير طبي من جهة رسمية (إن وجد)."
    },
    isHealthRecord: {
      yes: {
        english: "Yes",
        arabic: "نعم"
      },
      no: {
        english: "No",
        arabic: "لا"
      }
    },
    healthNoteTitle: {
      english: "If yes please indicate details.",
      arabic: "اذكر التفاصيل اذا كانت الإجابة بنعم"
    },
    healthNote: {
      english: "",
      arabic: ""
    }
  },
  suspendedDetails: {
    isSuspended: {
      yes: {
        english: "Yes",
        arabic: "نعم"
      },
      no: {
        english: "No",
        arabic: "لا"
      }
    },
    suspensionNoteTitle: {
      english: "If yes please indicate details.",
      arabic: "اذكر التفاصيل اذا كانت الإجابة بنعم"
    }
  },

  declaration: {
    title: {
      english: "DECELARATION AND ACKNOWLEDGMENT",
      arabic: "تصريح و اقرار"
    },
    isDeclared: {
      english: "",
      arabic: ""
    },
    declarationText: {
      english:
        "I undersigned hereby certify that the above information is complete, accurate and correct. I authorize AUMS and related officials to verify all statements contained therein and give the University the absolute discretion to use these documents whenever and wherever it deems fit in connection with its tasks. Withholding information, misrepresentation, or forgery of the presented documents renders me subject to immediate dismissal from the school and all the applicable legal penalties. All documents presented by applicants to complete a file for admission become the property of AUMS.",
      arabic:
        "اقر انا الموقع أدناه بأن المعلومات المذكورة في هذا الطلب صحيحة وكاملة. وامنح الجامعة  أو أي جهة رسمية معنية الحق كاملا بالتحقق منها  أو باستعمال المستندات المرفقة بما تراه ملائما. واسمح للجامعة باستخدام المعلومات المقدمة مني لأغراضها التعليمية. كما امنح الجامعة الحق برفض طلبي أوإلغاء قيدي أو فصلي منها في حال اكتشافهم تزويرأو عدم صحة  لأي معلومة مقدمه مني  أو مستند من المستندات المرفقة ، كما أن للجامعة الحق  في الاحتفاظ بجميع الرسوم المدفوعه في حال حدوث ذلك ، كما اتعهد في حالة قبولي في الجامعة باستكمال وإنهاء  جميعا الآجراءات الخاصة  بها ، كما تعتبر المعلومات المقدمة مني ملكا للكلية ولها الحق بالاحتفاظ بجميع المستندات المقدمة مني له "
    }
  },
  document: {
    title: {
      english: "Upload the following documents PDF Only",
      arabic: ""
    },
    photograph: {
      english: "Passport photographs attached.",
      arabic: "صور شخصية حديثة"
    },
    transcript: {
      english:
        "Official transcript (signed) with attestation from Ministry of Education in Kuwait for non-governmental schools.",
      arabic:
        "إرفاق كشف الدرجات (رسمي) مع المعادله من وزارة التعليم بالكويت للمدارس الغير حكومية وللمدارس التي بخارج دولة الكويت"
    },
    englishscore: {
      english: "Copy of IELTS score"
      // arabic: "إرفاق نتائج امتحان اللغة الإنجليزية التوفل او الايلز"
    },
    highschool: {
      english:
        "High school original Certificate with attestation from Ministry of Education in Kuwait.",
      arabic:
        "إرفاق شهادة الثانوية العامة الأصلية من قبل وزارة التربية مع المعادلة"
    },
    civilid: {
      english: "",
      arabic: ""
    },
    passport: {
      english: "",
      arabic: ""
    },
    record: {
      english:
        "Applicants of 21 years of age or above and having been discontinued from studying for more than one academic year, must submit a recent valid clear criminal record (not exceeding 3 months) upon application.",
      arabic:
        "يجب على المتقدم الذي يبلغ من العمر 21 عاماً فما فوق وانقطع عن الدراسة لأكثر من عام دراسي أن يقدم سجلاً جنائياً واضحاً وحديثاً وسارياً (لا يتجاوز 3 أشهر) عند تقديم الطلب."
    },
    goodconduct: {
      english: "Certificate of Good Conduct",
      arabic: "شهادة حسن سير و سلوك"
    },
    fatherCivilId: {
      english: "",
      arabic: ""
    },
    motherCivilId: {
      english: "",
      arabic: ""
    }
  },

  archivements: {
    english: "",
    arabic: ""
  },
  address: {
    home: {
      english: "Home Address",
      arabic: "العنوان"
    },
    block: {
      english: "Block",
      arabic: "القطعة"
    },
    appartment: {
      english: "House/Apartment",
      arabic: "رقم المنزل/ الشقة"
    },
    street: {
      english: "Street",
      arabic: "الشارع"
    },
    area: {
      english: "Area",
      arabic: "المنطقة"
    },
    jaddah: {
      english: "Jaddah",
      arabic: "جادة"
    },

    country: {
      english: "Country",
      arabic: "البلد"
    },
    postalOffice: {
      english: "Po. Box",
      arabic: "العنوان"
    },
    zipCode: {
      english: "Zip Code",
      arabic: "الرمز"
    },
    governorate: {
      english: "Governorate",
      arabic: "المحافظة"
    }
  },

  family: {
    fatherName: {
      english: "Father Name",
      arabic: "اسم الأب"
    },
    fatherDob: {
      english: "Date of Birth",
      arabic: "تاريخ الميلاد"
    },
    fatherNationality: {
      english: "Nationality",
      arabic: "الجنسية"
    },
    fatherCivilId: {
      english: "Civil ID No",
      arabic: "الرقم المدني"
    },
    fatherOccup: {
      english: "Occupation",
      arabic: "جهة العمل"
    },
    fatherEmployer: {
      english: "Employer",
      arabic: "جهة العمل"
    },
    fatherDepartment: {
      english: "Department",
      arabic: "قسم"
    },
    fatherMobile: {
      english: "Mobile No",
      arabic: "رقم الموبايل"
    },
    fatherWorkTel: {
      english: "Work Tel",
      arabic: "رقم جهة العمل"
    },
    fatherEmail: {
      english: "E-mail",
      arabic: "البريد الالكتروني"
    },
    motherName: {
      english: "Mother Name",
      arabic: "اسم الأم"
    },
    motherDob: {
      english: "Date of Birth",
      arabic: "تاريخ الميلاد"
    },
    motherNationality: {
      english: "Nationality",
      arabic: "الجنسية"
    },
    motherCivilId: {
      english: "Civil ID No",
      arabic: "الرقم المدني"
    },
    motherOccup: {
      english: "Occupation",
      arabic: "المنطقة"
    },
    motherEmployer: {
      english: "Employer",
      arabic: "جهة العمل"
    },
    motherDepartment: {
      english: "Department",
      arabic: "قسم"
    },
    motherMobile: {
      english: "Mobile No",
      arabic: "رقم الموبايل"
    },
    motherWorkTel: {
      english: "Work Tel",
      arabic: "رقم جهة العمل"
    },
    motherEmail: {
      english: "E-Mail",
      arabic: "البريد الالكتروني"
    }
  },
  emergency: {
    title: {
      english:
        "Indicate below the names and Civil ID number of 2 persons to be contacted in case of emergency",
      arabic: "اذكر أسم شخصين يمكن الاتصال بهم في حالة وقوع أي طارئ"
    },
    name: {
      english: "Name",
      arabic: "الاسم"
    },
    contact: {
      english: "Telephone",
      arabic: "هاتف"
    },
    relationship: {
      english: "Relationship",
      arabic: "صلة القرابة"
    },
    civilId: {
      english: "Civil ID",
      arabic: "الرقم المدني"
    }
  },
  school: {
    name: {
      english: "School Name",
      arabic: "اسم المدرسة"
    },
    type: {
      english: "",
      arabic: ""
    },
    gpa: {
      english: "GPA",
      arabic: "المعدل العام"
    },
    date: {
      english: "Graduation date",
      arabic: "تاريخ التخرج"
    },
    degree: {
      english: "Degree Awarded",
      arabic: "الدرجة الممنوحة"
    },
    physics: {
      english: "",
      arabic: ""
    },
    chemistry: {
      english: "",
      arabic: ""
    },
    biology: {
      english: "",
      arabic: ""
    },
    average: {
      english: "",
      arabic: ""
    }
  },

  college: {
    name: {
      english: "College/University Name",
      arabic: "اسم الجامعة/الكلية"
    },
    country: {
      english: "Country",
      arabic: "البلد"
    },
    gpa: {
      english: "GPA",
      arabic: "المعدل العام"
    },
    date: {
      english: "",
      arabic: ""
    },
    major: {
      english: "Major",
      arabic: "التخصص"
    },
    degree: {
      english: "Diploma or certificate",
      arabic: "الشهادة الممنوحة"
    }
  },
  languages: {
    title: {
      english: "Language Proficiency: (Excellent, Good or Fair)",
      arabic: "المهارات اللغوية (ممتاز، جيد، مقبول)"
    },
    subTitle: {
      english: "Language",
      arabic: "ﻟُﻐَﺔ "
    },
    language1: {
      english: "English",
      arabic: "اللغة الإنجليزية"
    },
    language2: {
      english: "Arabic",
      arabic: "اللغة العربية"
    },
    language3: {
      english: "Other",
      arabic: "أخرى"
    },
    read: {
      english: "Read ",
      arabic: "قراءة"
    },
    write: {
      english: "Written ",
      arabic: "كتابة"
    },
    speak: {
      english: "Spoken ",
      arabic: "محادثة"
    }
  },

  test: {
    testScore: {
      english: "",
      arabic: ""
    },
    testDate: {
      english: "",
      arabic: ""
    },
    testType: {
      english: "",
      arabic: ""
    }
  },
  isRelativeWorking: {
    english: "",
    arabic: ""
  },
  isEligible: {
    english: "",
    arabic: ""
  }
};
