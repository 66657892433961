import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
    return (
        <div className='backdrop-blur-sm fixed top-0 left-0 h-screen w-screen flex items-center justify-center' style={{ position: "fixed", backdropFilter: "blur(1.5px)", top: 0, left: 0, backgroundColor: "rgba(255,255,255,0.5)", zIndex: 9999 }}>
            <CircularProgress />
        </div>
    )
}

export default Spinner