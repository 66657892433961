import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../utils/config";
import { Box } from "@mui/material";
import Download from "../components/Download";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "../components/ErrorMessage";
import ErrorTitle from "../components/ErrorTitle";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { initialFormData } from "../utils/formData";
import Paper from "@mui/material/Paper";
import { validateDocument } from "../utils/validateForm";
import AdmissionFormStatus from "./AdmissionFormStatus";
import { GoXCircleFill, GoCheckCircleFill } from "react-icons/go";
import { MdBlock } from "react-icons/md";
import SuccessPage from "./SuccessPage";
import Spinner from "../components/Spinner";
import { toast, ToastContainer } from "react-toastify";
import CompleteDocumentPage from "./CompeletPage";

const DocumentPage = () => {
  const { id } = useParams();
  const [formTwoData, setFormTwoData] = useState(initialFormData);
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formNotFound, setFormNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [documentErrorMsg, setDocumentErrorMsg] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [errors, setErrors] = useState({});
  const [isNull, setIsNull] = useState(false);

  const btnRef = useRef(null);

  const keysToCheck = [
    "photograph",
    "transcript",
    "englishscore",
    "highschool",
    "civilidFront",
    "civilidBack",
    "securityCardFront",
    "securityCardBack",
    "passport",
    "record",
    "goodconduct",
    "fatherCivilId",
    "motherCivilId"
  ];

  const findNullKeys = (doc) => {
    const nullKeys = [];
    keysToCheck.forEach((key) => {
      if (doc[key] === null) {
        nullKeys.push(key);
      }
    });
    return nullKeys;
  };

  const getStudent = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/student/admission/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await response.json();
      // console.log(json);
      if (json.status) {
        setFormStatus(json.data.student.admissionStatus);
        setFormData(json.data);
        const nullKeys = findNullKeys(json?.data.document);
        console.log("Keys with null values:", nullKeys.length);
        if (nullKeys.length > 0) {
          setIsNull(true);
        } else if (nullKeys.length === 0) {
          setIsNull(false);
        }
        setFormNotFound(false);
      } else {
        setDocumentError(true);
        setDocumentErrorMsg(json.message);
        setFormNotFound(true);
      }
    } catch (error) {
      setDocumentError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  console.log(formStatus, isNull);

  const handleChange = (e, firstKey, secondKey, thirdKey, forthKey) => {
    if (e.persist) e.persist();

    setFormTwoData((currentData) => {
      const value = e.target.value;
      const data = { ...currentData };
      let updateData = data;
      if (firstKey) {
        updateData = updateData[firstKey];
      }

      if (secondKey) {
        updateData = updateData[secondKey];
      }

      if (thirdKey) {
        updateData = updateData[thirdKey];
      }
      if (forthKey) {
        updateData = updateData[forthKey];
      }
      updateData.value = value;

      return data;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btnRef.current.disabled = true;
    // console.log(btnRef);
    const isValid = validateDocument(formTwoData, setErrors);

    if (isValid) {
      const docFile = new FormData();
      if (formData.nationality === "Non-Kuwaiti") {
        docFile.append(
          "securityFront",
          formTwoData.document.securityCardFront.value
        );
        docFile.append(
          "securityBack",
          formTwoData.document.securityCardBack.value
        );
        docFile.append("civilidFront", formTwoData.document.civilidFront.value);
        docFile.append("civilidBack", formTwoData.document.civilidBack.value);
      } else {
        docFile.append("civilidFront", formTwoData.document.civilidFront.value);
        docFile.append("civilidBack", formTwoData.document.civilidBack.value);
      }
      docFile.append("id", id);

      try {
        setIsLoading(true);
        const docResponse = await fetch(
          `${BASE_URL}/student/admission/document/`,
          {
            method: "POST",
            body: docFile
          }
        );

        const jsonDoc = await docResponse.json();
        // console.log(jsonDoc);
        if (jsonDoc.status) {
          setIsSubmitted(true);
        } else {
          toast.error(jsonDoc.message);
        }
        if (jsonDoc.status !== true) {
          setDocumentError(true);
          btnRef.current.disabled = false;
          setDocumentErrorMsg(jsonDoc.message);
        }
      } catch (error) {
        setDocumentError(true);
        setDocumentErrorMsg(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {formStatus === "approved" && !isNull && formData.document !== null ? (
        <AdmissionFormStatus
          icon={
            <GoCheckCircleFill className="md:text-[160px] text-[120px] text-[#52b69a]" />
          }
          message="Application form is approved"
        />
      ) : formStatus === "rejected" ? (
        <AdmissionFormStatus
          icon={
            <GoXCircleFill className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form one is rejected"
        />
      ) : formNotFound ? (
        <AdmissionFormStatus
          icon={
            <GoXCircleFill className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form not found"
        />
      ) : isSubmitted ? (
        <SuccessPage redirectURL={"/"} />
      ) : formStatus === "approved" && isNull ? (
        <CompleteDocumentPage isNull={isNull} id={id} />
      ) : (
        (formStatus === "preapproved" || formStatus === "pending") &&
        formData.document === null && (
          <>
            {isLoading && <Spinner />}
            <Box sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  backgroundColor: "#B70042",
                  padding: "1rem",
                  fontWeight: "bold"
                }}
              >
                <h4 className="form">Documents</h4>
              </Box>

              <Box sx={{ padding: "0.5rem", color: "#606060" }}>
                <Box>
                  <h4 className="my-3 font-bold text-center">
                    {formLabel.document.title.english}
                  </h4>
                </Box>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead className="w-full ">
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <span className="text-lg">
                            Copy of the Valid Kuwait Civil ID card
                          </span>
                          <br />
                          1. FRONT COPY
                          {errors.civilIdFront && (
                            <ErrorMessage errorMessage={errors.civilIdFront} />
                          )}
                          {!errors.civilIdFront && <br />}
                          2. BACK COPY
                          <br />
                          {errors.civilIdBack && (
                            <ErrorMessage errorMessage={errors.civilIdBack} />
                          )}
                        </TableCell>
                        <TableCell className="w-[300px]">
                          <div
                            style={{
                              display: "flex",
                              gap: "0.3rem",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-end"
                            }}
                          >
                            <Download
                              formData={formTwoData}
                              handleChange={handleChange}
                              value={"civilidFront"}
                            />
                            <Download
                              formData={formTwoData}
                              handleChange={handleChange}
                              value={"civilidBack"}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      {formData.nationality === "Non-Kuwaiti" && (
                        <TableRow>
                          <TableCell sx={{ fontWeight: "700" }} align="left">
                            {formData.nationality === "Non-Kuwaiti" ? (
                              <>
                                <span className="text-lg">
                                  Copy of the Security card
                                </span>
                                <br />
                                1. FRONT COPY
                                {errors.securityCardFront && (
                                  <ErrorMessage
                                    errorMessage={errors.securityCardFront}
                                  />
                                )}
                                {!errors.securityCardFront && <br />}
                                2. BACK COPY
                                <br />
                                {errors.securityCardBack && (
                                  <ErrorMessage
                                    errorMessage={errors.securityCardBack}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <span className="text-lg">
                                  Copy of the Valid Kuwait Civil ID card
                                </span>
                                <br />
                                1. FRONT COPY
                                {errors.civilIdFront && (
                                  <ErrorMessage
                                    errorMessage={errors.civilIdFront}
                                  />
                                )}
                                {!errors.civilIdFront && <br />}
                                2. BACK COPY
                                <br />
                                {errors.civilIdBack && (
                                  <ErrorMessage
                                    errorMessage={errors.civilIdBack}
                                  />
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell className="w-[300px]">
                            <div
                              style={{
                                display: "flex",
                                gap: "0.3rem",
                                width: "100%",
                                flexDirection: "column",
                                justifyContent: "flex-end"
                              }}
                            >
                              {formData.nationality === "Non-Kuwaiti" ? (
                                <>
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"securityCardFront"}
                                  />
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"securityCardBack"}
                                  />
                                </>
                              ) : (
                                <>
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"civilidFront"}
                                  />
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"civilidBack"}
                                  />
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>
              <div className="w-full">
                <button
                  ref={btnRef}
                  onClick={handleSubmit}
                  className="bg-[#b70042] disabled:bg-rose-300 text-white font-bold px-10 py-3 rounded-lg mx-auto flex items-center my-6 hover:bg-rose-700"
                >
                  Submit
                </button>
              </div>
            </Box>
            <ToastContainer />
          </>
        )
      )}
    </>
  );
};

export default DocumentPage;
