export const acknowledgment = [
  {
    title:
      "Request an Application for Bachelor of Dental Medicine Study Program Academic Year 2024 – 2025",
    text: `Admission requirements and minimum requirements for admission and enrollment in the BDM study program (Bachelor of Dental Medicine) for the academic year 2024/2025:-`,
    subtext: ` Admission to the BDM program (Bachelor) for the academic year 2024/2025 is
competitive and according to the absorptive capacity of the college, noting that
achieving the requirements and conditions of admission or submitting the required
documents does not necessarily mean recognition by the university administration for
admission, and these conditions are as follows: -`,
    termsAndConditions: [
      {
        title: "Read all the conditions",
        conditions: [
          {
            text: `The student must have an 85% secondary general average (high school score) -
scientific - or its equivalent in the State of Kuwait and recognized by the American
University of Medical Sciences.`
          },
          {
            text: `The student must have 85% in the following subjects in high school:
Biology - Physics - Chemistry`
          },
          {
            text: `Passing the IELTS English language exam and achieving a score of at least 5.5 or its
equivalent.`
          },
          {
            text: `Bring and submit the required documents to the university administration.`
          },
          {
            text: `Pay the required fees (application fees and non-refundable entrance exam fees).`
          },
          {
            text: `Passing the personal interview and admission exams.`
          }
        ]
      }
    ],
    importantNotes: [
      {
        title: "Important Notes",
        subTitle:
          "How to apply for Bachelor of Dental Medicine Program (B.D.M)",
        for: "Only for self sponsored students",
        points: [
          {
            text: "Step 1: Request an application form",
            content:
              "Upload all required documents and submit your request. Due to high demand and limited number of seats, only eligible candidates will be contacted to continue their application (within 1 month)."
          },
          {
            text: "Step 2: Eligible Candidates will proceed to fill Admission Application Form",
            content: ""
          },
          {
            text: "Step 3: Admission",
            content:
              "All decisions of admission (within 1 month) of offers and rejections are made at the discretion of the Admission Committee. All decisions by the Admission Committee are final."
          }
        ],
        annualFees: {
          text: "12000 KD"
        },
        offerAnnualFees: {
          text: "10000 KD"
        },
        importantPoints: {
          text: "The University reserves the right to increase or modify the Tuition fee upon approval of the Private University Council - Ministry of Higher Education and the University Board of Trustees  "
        }
      }
    ],
    notes: [
      {
        title: "Note",
        points: [
          {
            text: `Admission is extremely competitive and is based on the strength of the applicant pool
and the available capacity.`
          },
          {
            text: `Note that fulfilling the requirements and admission conditions does not necessarily
mean acceptance into the university.`
          }
        ]
      }
    ]
  }
];
