import { Box, Checkbox, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { isNotArabic } from "../utils/methods";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "./ErrorMessage";
import ErrorTitle from "./ErrorTitle";
const AdmissionForm3 = ({ formData, handleChange, errors, showError }) => {
  // const CustomCheckboxYes = styled(Checkbox)(({ theme }) => ({
  //   color: "#B70042", // Set the color of the checkbox itself
  //   "&.Mui-checked": {
  //     color: "blue" // Change color when checked
  //   }
  // }));

  // const CustomCheckboxNo = styled(Checkbox)(({ theme }) => ({
  //   color: "#B70042", // Set the color of the checkbox itself
  //   "&.Mui-checked": {
  //     color: "blue" // Change color when checked
  //   }
  // }));

  useEffect(() => {
    document.title = "AUMS • Health";
  }, []);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "space-between",
          backgroundColor: "#B70042",
          color: "white",
          width: "100%",
          padding: "1rem"
        }}
      >
        <span>{formLabel.headings.healthPageTitle.english}</span>
        <span>{formLabel.headings.healthPageTitle.arabic}</span>
      </Box>

      <Box
        sx={{
          padding: "1rem",
          fontSize: "1.2rem",
          fontWeight: "600",
          color: "#606060"
        }}
      >
        {showError && <ErrorTitle />}
        <p className="flex flex-col">
          <span>{formLabel.healthDetails.title.english}</span>
          <br />
          <span className="text-right">
            {formLabel.healthDetails.title.arabic}
          </span>
        </p>
        <Box sx={{ display: "flex ", flexDirection: "column" }}>
          <div className="mt-3 flex justify-end">
            <input
              checked={formData.isHealthRecord.value === true}
              type="checkbox"
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      value: e.target.checked === false ? null : true
                    }
                  },
                  "isHealthRecord"
                );
              }}
            />
            <label htmlFor="isHealthRecord" className="ml-2">
              {formLabel.healthDetails.isHealthRecord.yes.english} /
              {formLabel.healthDetails.isHealthRecord.yes.arabic}
            </label>
          </div>

          <div className="flex justify-end">
            <input
              checked={formData.isHealthRecord.value === false}
              type="checkbox"
              onChange={(e) => {
                if (!isNotArabic(e)) return;
                handleChange(
                  {
                    target: {
                      value: e.target.checked === false ? null : false
                    }
                  },
                  "isHealthRecord"
                );
              }}
            />
            <label htmlFor="isHealthRecord" className="ml-2">
              {formLabel.healthDetails.isHealthRecord.no.english} /{" "}
              {formLabel.healthDetails.isHealthRecord.no.arabic}
            </label>
          </div>
          {errors.healthError && (
            <ErrorMessage
              alignRight="text-right"
              errorMessage={errors.healthError}
            />
          )}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            padding: "1rem"
          }}
        >
          <h4
            style={{ color: "black", fontWeight: "600", fontSize: "1rem" }}
            className="form mb-2 flex justify-between"
          >
            <span>{formLabel.healthDetails.healthNoteTitle.english}</span>
            <span>{formLabel.healthDetails.healthNoteTitle.arabic}</span>
          </h4>
          {errors.healthNote && (
            <ErrorMessage
              alignRight="text-right"
              errorMessage={errors.healthNote}
            />
          )}
          <TextField
            disabled={!formData.isHealthRecord.value}
            // required={formData.isHealthRecord.value}
            placeholder="Describe"
            size={"small"}
            value={formData.healthNote.value}
            onChange={(e) => handleChange(e, "healthNote")}
            color="primary"
            // id="outlined-start-adornment"
            // sx={{ m: 1, width: '100%' }}
            sx={{
              width: "100%",

              borderColor: "lightgray",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  height: "8rem",
                  borderColor: "none",
                  borderRadius: "12px" // Specify your desired border color here
                },
                //   '&:hover fieldset': {
                //     borderColor: 'green', // Specify your desired hover border color here
                //   },
                "&.Mui-focused fieldset": {
                  borderColor: "black" // Specify your desired focused border color here
                }
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdmissionForm3;
