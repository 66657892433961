import React, { memo, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import FormFieldLabel from "./FormFieldLabel";

const Input = ({
  label,
  size = "small",
  endornment,
  name,
  handleChange,
  englishLabel,
  arabicLabel,
  variant = "outlined",
  type = "text",
  value = "",
  select = false,
  disabled = false,
  option = null,
  isRequired = false,
  customMenuItemClass = null
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: "0.3rem"
      }}
    >
      {label ? (
        <label className="font-semibold">{label}</label>
      ) : englishLabel || arabicLabel ? (
        <label className="flex justify-between w-full font-semibold">
          <span>{englishLabel}</span>
          <span>{arabicLabel}</span>
        </label>
      ) : null}

      <TextField
        required={isRequired}
        size={size}
        color="primary"
        type={type}
        select={select}
        autoComplete="off"
        // id="outlined-start-adornment"
        // sx={{ m: 1, width: '100%' }}
        sx={{
          background: "#F6F2F8",
          width: "100%",
          borderColor: "lightgray",
          textTransform: "capitalize",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "none",
              borderRadius: "8px" // Specify your desired border color here
            },

            //   '&:hover fieldset': {
            //     borderColor: 'green', // Specify your desired hover border color here
            //   },
            "&.Mui-focused fieldset": {
              borderColor: "black" // Specify your desired focused border color here
            }
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{endornment}</InputAdornment>
          )
        }}
        name={name}
        variant={variant}
        value={value || ""}
        onChange={handleChange}
        disabled={disabled}
      >
        {option?.map((data, index) => {
          return (
            <MenuItem
              value={data?.value}
              key={data.value}
              className={`${
                customMenuItemClass ? customMenuItemClass(index) : ""
              }`}
            >
              {data?.label}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
};

// Input.defaultProps = {
//   size: "small",
//   variant: "outlined",
//   type: "text",
//   value: "",
//   select: false,
//   disabled: false,
//   option: null,
//   isRequired: false,
//   customMenuItemClass: null
// };

export default memo(Input, (prev, next) => {
  return prev.value === next.value && prev.disabled === next.disabled;
});

// export default Input;
