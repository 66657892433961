import Resizer from "react-image-file-resizer";

import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "../components/AdmissionForm.css";
import { Grid } from "@mui/material";
import CommonAccordion from "../components/CommonAccordion";
import { customBorderBottom, isEnglish, isNotArabic } from "../utils/methods";
import Input from "../components/Input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { MdBlock } from "react-icons/md";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import "../components/phoneInput.css";
import PhoneInput from "react-phone-input-2";
import { formLabel } from "../utils/formFieldLabels";
import FormFieldLabel from "../components/FormFieldLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import { countryListOptionWithBedoon, getStudentAge } from "../utils/methods";
import { GoXCircleFill, GoCheckCircleFill } from "react-icons/go";
import ErrorMessage from "../components/ErrorMessage";
import ErrorTitle from "../components/ErrorTitle";
import { IoPersonCircle } from "react-icons/io5";
import { MdFileUploadOff } from "react-icons/md";
import { initialFormData } from "../utils/formData";
import { maxLength } from "../utils/methods";
import AdmissionFormStatus from "./AdmissionFormStatus";
import { validateFormTwo } from "../utils/validateFormTwo";
import Spinner from "../components/Spinner";
import SuccessPage from "./SuccessPage";
import { BASE_URL } from "../utils/config";
import { ToastContainer, toast } from "react-toastify";

const ConfirmationPage = () => {
  const [formData, setFormData] = useState({});
  const [formTwoData, setFormTwoData] = useState(initialFormData);
  const [documentError, setDocumentError] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [formNotFound, setFormNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [isImageError, setIsImageError] = useState("");
  const [resetDocumentState, setResetDocumentState] = useState(false);

  const handleChange = (e, firstKey, secondKey, thirdKey, forthKey) => {
    if (e.persist) e.persist();

    setFormTwoData((currentData) => {
      const value = e.target.value;
      const data = { ...currentData };
      let updateData = data;
      if (firstKey) {
        updateData = updateData[firstKey];
      }

      if (secondKey) {
        updateData = updateData[secondKey];
      }

      if (thirdKey) {
        updateData = updateData[thirdKey];
      }
      if (forthKey) {
        updateData = updateData[forthKey];
      }
      updateData.value = value;

      localStorage.setItem("form-two", JSON.stringify(formTwoData));
      return data;
    });
  };
  const isChecked = formTwoData.emergency[1].personTwo.value;

  const [financialSupport, setFinancialSupport] = useState("");

  const handleFinancialSupportChange = (index, key) => {
    setFinancialSupport(financialData[index].value);
    handleChange({ target: { value: { [key]: true } } }, "sponsorship");
    if (financialData[index].value !== "others") {
      setShowTextBox(false);
    }
  };

  function createData(name, read, write, speak) {
    return { name, read, write, speak };
  }

  const option = [
    { value: "Excellent", label: "Excellent" },
    { value: "Good", label: "Good" },
    { value: "Fair", label: "Fair" }
  ];
  const rows = [
    createData(
      <>
        <span className="font-bold">
          {formLabel.languages.language1.english}
        </span>
        <span className="font-bold"> / </span>
        <span className="font-bold">
          {formLabel.languages.language1.arabic}
        </span>
      </>,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[0].read.value}
        handleChange={(e) => {
          handleChange(e, "languages", "0", "read");
        }}
      />,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[0].write.value}
        handleChange={(e) => {
          handleChange(e, "languages", "0", "write");
        }}
      />,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[0].speak.value}
        handleChange={(e) => {
          handleChange(e, "languages", "0", "speak");
        }}
      />
    ),
    createData(
      <>
        <span className="font-bold">
          {formLabel.languages.language2.english}
        </span>
        <span className="font-bold"> / </span>
        <span className="font-bold">
          {formLabel.languages.language2.arabic}
        </span>
      </>,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[1].read.value}
        handleChange={(e) => {
          handleChange(e, "languages", "1", "read");
        }}
      />,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[1].write.value}
        handleChange={(e) => {
          handleChange(e, "languages", "1", "write");
        }}
      />,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[1].speak.value}
        handleChange={(e) => {
          handleChange(e, "languages", "1", "speak");
        }}
      />
    ),
    createData(
      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <>
          <span className="font-bold">
            {formLabel.languages.language3.english}
          </span>
          <span className="font-bold"> / </span>
          <span className="font-bold">
            {formLabel.languages.language3.arabic}
          </span>
        </>
        ,
        <Input
          value={formTwoData.languages[2].language.value}
          handleChange={(e) => {
            handleChange(e, "languages", "2", "language");
          }}
        />
      </div>,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[2].read.value}
        handleChange={(e) => {
          handleChange(e, "languages", "2", "read");
        }}
      />,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[2].write.value}
        handleChange={(e) => {
          handleChange(e, "languages", "2", "write");
        }}
      />,
      <Input
        select={true}
        option={option}
        value={formTwoData.languages[2].speak.value}
        handleChange={(e) => {
          handleChange(e, "languages", "2", "speak");
        }}
      />
    )
  ];

  useEffect(() => {
    if (financialSupport.toLocaleLowerCase() === "others") {
      setShowTextBox(true);
    }
  }, [financialSupport, setFinancialSupport]);

  const financialData = [
    {
      title: "Parents",
      value: "Parents",
      english: "Parents ",
      arabic: "الوالدين"
    },
    {
      title: "Myself",
      value: "Myself",
      english: "Myself",
      arabic: "الطالب نفسه"
    },
    {
      title: "Ministry",
      value: "Ministry",
      english: "Ministry",
      arabic: "وزارة (اذكر الإسم)"
    },
    {
      title: "Others",
      value: "Others",
      english: "Others",
      arabic: "اخرى (اذكر الإسم)"
    }
  ];
  const { id } = useParams();
  const getStudentAdmissionForm = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/student/admission/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await response.json();
      // console.log(json);

      if (json.status) {
        setFormStatus(json.data.student.admissionStatus);
        setFormData(json.data);
        setFormNotFound(false);
      } else {
        setFormNotFound(true);
      }

      return json;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    if (
      validateFormTwo(
        formTwoData,
        formData.nationality,
        formData.dob,
        setErrors
      )
    ) {
      const docFile = new FormData();
      docFile.append("photograph", formTwoData.document.photograph.value);
      docFile.append("transcript", formTwoData.document.transcript.value);
      docFile.append("englishscore", formTwoData.document.englishscore.value);
      docFile.append("highschool", formTwoData.document.highschool.value);
      docFile.append("passport", formTwoData.document.passport.value);
      docFile.append("record", formTwoData.document.record.value);
      docFile.append("goodconduct", formTwoData.document.goodconduct.value);
      docFile.append("fatherCivilId", formTwoData.document.fatherCivilId.value);
      docFile.append("motherCivilId", formTwoData.document.motherCivilId.value);
      docFile.append("id", id);

      const filteredLanguages = formTwoData.languages
        .filter((data) => data.language.value.trim() !== "")
        .map((data) => ({
          language: data.language.value,
          read: data.read.value,
          write: data.write.value,
          speak: data.speak.value
        }));

      try {
        setIsLoading(true);

        // First API: Personal and Family Information API
        const personalResponse = await fetch(
          `${BASE_URL}/student/admission/application-two/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              passport: formTwoData.passport.value,
              passportExpiry: formTwoData.passportExpiry.value,
              family: {
                fatherDob: formTwoData.family.fatherDob.value,
                fatherNationality: formTwoData.family.fatherNationality.value,
                fatherCivilId: formTwoData.family.fatherCivilId.value,
                fatherWorkTel: formTwoData.family.fatherWorkTel.value,
                fatherEmail: formTwoData.family.fatherEmail.value,
                motherDob: formTwoData.family.motherDob.value,
                motherNationality: formTwoData.family.motherNationality.value,
                motherCivilId: formTwoData.family.motherCivilId.value,
                motherWorkTel: formTwoData.family.motherWorkTel.value,
                motherEmail: formTwoData.family.motherEmail.value
              },
              emergency: [
                {
                  name: formTwoData.emergency[0].name.value,
                  contact: formTwoData.emergency[0].contact.value,
                  relationship: formTwoData.emergency[0].relationship.value,
                  civilId: formTwoData.emergency[0].civilId.value
                }
              ],
              languages: filteredLanguages
            })
          }
        );
        const personalJson = await personalResponse.json();
        // console.log("PERSONAL STATUS", personalJson.status);

        if (!personalJson.status) {
          toast.error(personalJson.message);
          setShowError(true);
          window.scrollTo(0, 0);
          setIsLoading(false);
          return;
        }

        if (personalJson.status) {
          // Second API: Review API
          const reviewResponse = await fetch(
            `${BASE_URL}/student/record/review/${formData.student_uid}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                admissionStatus: "approved"
              })
            }
          );
          const reviewJson = await reviewResponse.json();
          // console.log("REVIEW STATUS", reviewJson.status);

          if (!reviewJson.status) {
            toast.error(reviewJson.message);
            setShowError(true);
            window.scrollTo(0, 0);
            setIsLoading(false);
            return;
          }
        }

        // Third API: Document API
        const docResponse = await fetch(
          `${BASE_URL}/student/admission/document/`,
          {
            method: "POST",
            body: docFile
          }
        );

        const docJson = await docResponse.json();
        // console.log("DOCUMENT STATUS", docJson.status);

        if (docJson.status) {
          setStudentId(docJson.data.id);
          localStorage.removeItem("form-two");
          localStorage.removeItem("FormData");
          setIsSubmitted(true);
        } else {
          toast.error(docJson.message);
          setDocumentError(true);
          setShowError(true);
          setIsLoading(false);

          setFormTwoData({
            ...formTwoData,
            document: {
              photograph: { value: null },
              transcript: { value: null },
              englishscore: { value: null },
              highschool: { value: null },
              passport: { value: null },
              record: { value: null },
              goodconduct: { value: null },
              fatherCivilId: { value: null },
              motherCivilId: { value: null }
            }
          });

          setResetDocumentState(true);
        }
      } catch (error) {
        setDocumentError(true);
        toast.error("An error occurred while submitting.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setShowError(true);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (resetDocumentState) {
      setResetDocumentState(false); // Reset the state flag
    }
  }, [resetDocumentState]);

  // const handleSubmit = async () => {
  //   if (
  //     validateFormTwo(
  //       formTwoData,
  //       formData.nationality,
  //       formData.dob,
  //       setErrors
  //     )
  //   ) {
  //     const requestBody = {
  //       passport: formTwoData.passport.value,
  //       passportExpiry: formTwoData.passportExpiry.value,
  //       // securityCard: formTwoData.securityCard.value,
  //       // securityCardExpiry: formTwoData.securityCardExpiry.value,
  //       family: {
  //         fatherDob: formTwoData.family.fatherDob.value,
  //         fatherNationality: formTwoData.family.fatherNationality.value,
  //         fatherCivilId: formTwoData.family.fatherCivilId.value,
  //         fatherWorkTel: formTwoData.family.fatherWorkTel.value,
  //         fatherEmail: formTwoData.family.fatherEmail.value,
  //         motherDob: formTwoData.family.motherDob.value,
  //         motherNationality: formTwoData.family.motherNationality.value,
  //         motherCivilId: formTwoData.family.motherCivilId.value,
  //         motherWorkTel: formTwoData.family.motherWorkTel.value,
  //         motherEmail: formTwoData.family.motherEmail.value
  //       },
  //       emergency: [
  //         {
  //           name: formTwoData.emergency[0].name.value,
  //           contact: formTwoData.emergency[0].contact.value,
  //           relationship: formTwoData.emergency[0].relationship.value,
  //           civilId: formTwoData.emergency[0].civilId.value
  //         }
  //       ],
  //       languages: [
  //         ...formTwoData.languages?.map((data) => ({
  //           language: data.language.value,
  //           read: data.read.value,
  //           write: data.write.value,
  //           speak: data.speak.value
  //         }))
  //       ]
  //     };

  //     if (formTwoData.emergency[1].personTwo.value) {
  //       requestBody.emergency.push({
  //         name: formTwoData.emergency[1].name.value,
  //         contact: formTwoData.emergency[1].contact.value,
  //         relationship: formTwoData.emergency[1].relationship.value,
  //         civilId: formTwoData.emergency[1].civilId.value
  //       });
  //     }

  //     const body = JSON.stringify(requestBody);
  //     try {
  //       setIsLoading(true);
  //       if (!documentError) {
  //         const response = await fetch(
  //           `${BASE_URL}/student/admission/application-two/${id}`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json"
  //             },
  //             body: body
  //           }
  //         );
  //         const json = await response.json();
  //         if (json.status) {
  //           setStudentId(json.data.id);
  //           console.log(studentId);
  //           const docFile = new FormData();
  //           docFile.append("photograph", formTwoData.document.photograph.value);
  //           docFile.append("transcript", formTwoData.document.transcript.value);
  //           docFile.append(
  //             "englishscore",
  //             formTwoData.document.englishscore.value
  //           );
  //           docFile.append("highschool", formTwoData.document.highschool.value);
  //           docFile.append("passport", formTwoData.document.passport.value);
  //           docFile.append("record", formTwoData.document.record.value);
  //           docFile.append(
  //             "goodconduct",
  //             formTwoData.document.goodconduct.value
  //           );
  //           docFile.append(
  //             "fatherCivilId",
  //             formTwoData.document.fatherCivilId.value
  //           );
  //           docFile.append(
  //             "motherCivilId",
  //             formTwoData.document.motherCivilId.value
  //           );
  //           // console.log(json);
  //           docFile.append("id", `${json.data.id}`);

  //           const res = await fetch(`${BASE_URL}/student/admission/document/`, {
  //             method: "POST",
  //             body: docFile
  //           });

  //           await fetch(
  //             `${BASE_URL}/student/record/review/${formData.student_uid}`,
  //             {
  //               method: "PATCH",
  //               headers: {
  //                 "Content-Type": "application/json"
  //               },
  //               body: JSON.stringify({
  //                 admissionStatus: "approved"
  //               })
  //             }
  //           );

  //           const docResponse = await res.json();
  //           console.log("docstatus", docResponse.status);
  //           if (docResponse.status) {
  //             localStorage.removeItem("form-two");
  //             localStorage.removeItem("FormData");
  //             setIsSubmitted(true);
  //             // await fetch(
  //             //   `${BASE_URL}/student/record/review/${formData.student_uid}`,
  //             //   {
  //             //     method: "PATCH",
  //             //     headers: {
  //             //       "Content-Type": "application/json"
  //             //     },
  //             //     body: JSON.stringify({
  //             //       admissionStatus: "approved"
  //             //     })
  //             //   }
  //             // );
  //           } else {
  //             toast.error(docResponse.message);
  //             setDocumentError(true);
  //           }
  //         }
  //       } else {
  //         const docFile = new FormData();
  //         docFile.append("photograph", formTwoData.document.photograph.value);
  //         docFile.append("transcript", formTwoData.document.transcript.value);
  //         docFile.append(
  //           "englishscore",
  //           formTwoData.document.englishscore.value
  //         );
  //         docFile.append("highschool", formTwoData.document.highschool.value);
  //         docFile.append("passport", formTwoData.document.passport.value);
  //         docFile.append("record", formTwoData.document.record.value);
  //         docFile.append("goodconduct", formTwoData.document.goodconduct.value);
  //         docFile.append(
  //           "fatherCivilId",
  //           formTwoData.document.fatherCivilId.value
  //         );
  //         docFile.append(
  //           "motherCivilId",
  //           formTwoData.document.motherCivilId.value
  //         );
  //         // console.log(json);
  //         docFile.append("id", studentId);

  //         const res = await fetch(`${BASE_URL}/student/admission/document/`, {
  //           method: "POST",
  //           body: docFile
  //         });

  //         const result = await res.json();
  //         console.log("docstatus", result.status);
  //         if (result.status) {
  //           localStorage.removeItem("form-two");
  //           localStorage.removeItem("FormData");
  //           setIsSubmitted(true);
  //           // await fetch(
  //           //   `${BASE_URL}/student/record/review/${formData.student_uid}`,
  //           //   {
  //           //     method: "PATCH",
  //           //     headers: {
  //           //       "Content-Type": "application/json"
  //           //     },
  //           //     body: JSON.stringify({
  //           //       admissionStatus: "approved"
  //           //     })
  //           //   }
  //           // );
  //         } else {
  //           toast.error(result.message);
  //           setDocumentError(true);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       toast.error(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   } else {
  //     // console.log(setErrors);

  //     setShowError(true);
  //     window.scrollTo(0, 0);
  //   }
  // };

  useEffect(() => {
    try {
      getStudentAdmissionForm();
      if (localStorage.getItem("form-two")) {
        setFormTwoData({
          ...JSON.parse(localStorage.getItem("form-two")),
          document: formTwoData.document
        });
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "AUMS • Form #2";
  }, []);

  return (
    <>
      {formStatus === "approved" ? (
        <AdmissionFormStatus
          icon={
            <GoCheckCircleFill className="md:text-[160px] text-[120px] text-[#52b69a]" />
          }
          message="Application form is approved"
        />
      ) : formStatus === "rejected" ? (
        <AdmissionFormStatus
          icon={
            <GoXCircleFill className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form one is rejected"
        />
      ) : formStatus === "pending" ? (
        <AdmissionFormStatus
          icon={
            <MdBlock className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form one is not approved"
        />
      ) : formNotFound ? (
        <AdmissionFormStatus
          icon={
            <GoXCircleFill className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form not found"
        />
      ) : isSubmitted ? (
        <SuccessPage redirectURL={"/"} />
      ) : (
        formStatus === "preapproved" && (
          <>
            {isLoading && <Spinner />}
            <Box sx={{ gap: "10rem" }}>
              <Box
                sx={{
                  display: "flex",
                  fontWeight: "bold",
                  justifyContent: "space-center",
                  backgroundColor: "#B70042",
                  color: "white",
                  width: "100%",
                  padding: "1rem",
                  fontSize: "1.5rem"
                }}
              >
                {/* <span>{formLabel.headings.personalPageTitle.english}</span>
          <span>{formLabel.headings.personalPageTitle.arabic}</span> */}
                <span className="w-full text-center">
                  Please verify all information
                </span>
              </Box>
              <div className="px-4">{showError && <ErrorTitle />}</div>
              <Box>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.personalDetails.arabic}
                  englishLabel={formLabel.headings.personalDetails.english}
                >
                  <Grid container spacing={1}>
                    <Grid item container spacing={4}>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.firstName}
                          arabicLabel={formLabel.firstName.arabic}
                          englishLabel={formLabel.firstName.english}
                        />
                        <Input
                          value={formData.firstNameArabic}
                          disabled
                          arabicLabel={formLabel.firstName.arabic}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.middleName}
                          arabicLabel={formLabel.middleName.arabic}
                          englishLabel={formLabel.middleName.english}
                        />
                        <Input
                          value={formData.middleNameArabic}
                          disabled
                          arabicLabel={formLabel.middleName.arabic}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.lastName}
                          arabicLabel={formLabel.lastName.arabic}
                          englishLabel={formLabel.lastName.english}
                        />
                        <Input
                          value={formData.lastNameArabic}
                          disabled
                          arabicLabel={formLabel.lastName.arabic}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          type={"date"}
                          value={formData.dob}
                          arabicLabel={formLabel.dob.arabic}
                          englishLabel={formLabel.dob.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.gender}
                          arabicLabel={formLabel.gender.arabic}
                          englishLabel={formLabel.gender.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.maritalStatus}
                          arabicLabel={formLabel.maritalStatus.arabic}
                          englishLabel={formLabel.maritalStatus.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-60">
                        <Input
                          disabled
                          value={formData.civilId}
                          arabicLabel={formLabel.civilId.arabic}
                          englishLabel={formLabel.civilId.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          type={"date"}
                          disabled
                          value={formData.civilIdExpiryDate}
                          arabicLabel={formLabel.civilIdExpiryDate.arabic}
                          englishLabel={formLabel.civilIdExpiryDate.english}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}></Grid>
                  </Grid>
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.nationalityResidency.arabic}
                  englishLabel={formLabel.headings.nationalityResidency.english}
                >
                  <Grid container spacing={1}>
                    <Grid item container spacing={4}>
                      <Grid xs={12} md={6} item className="opacity-50">
                        <Input
                          endornment={null}
                          arabicLabel={formLabel.nationality.arabic}
                          englishLabel={formLabel.nationality.english}
                          disabled
                          value={formData?.nationality}
                        />
                      </Grid>
                      <Grid xs={12} md={6} item className="opacity-50">
                        <Input
                          endornment={null}
                          arabicLabel={formLabel.birthCountry.arabic}
                          englishLabel={formLabel.birthCountry.english}
                          disabled
                          value={formData?.birthCountry}
                        />
                      </Grid>
                      <Grid xs={12} md={6} item>
                        <Input
                          value={formTwoData.passport.value}
                          handleChange={(e) => {
                            if (!isNotArabic(e)) return;

                            handleChange(e, "passport");
                          }}
                          arabicLabel={formLabel.passport.arabic}
                          englishLabel={formLabel.passport.english}
                        />
                        {errors.passport && (
                          <ErrorMessage errorMessage={errors.passport} />
                        )}
                      </Grid>

                      <Grid xs={12} md={6} item>
                        <Input
                          value={formTwoData.passportExpiry.value}
                          handleChange={(e) => {
                            if (new Date(e.target.value).getFullYear() > 2099)
                              return;
                            handleChange(e, "passportExpiry");
                          }}
                          type={"date"}
                          label={"Passport Expiry Date"}
                        />
                        {errors.passportExpiry && (
                          <ErrorMessage errorMessage={errors.passportExpiry} />
                        )}
                      </Grid>
                      {formData.nationality === "Non-Kuwaiti" ? (
                        <>
                          <Grid xs={12} md={6} item className="opacity-50">
                            <Input
                              disabled
                              value={formData.securityCard}
                              arabicLabel={formLabel.securityCard.arabic}
                              englishLabel={formLabel.securityCard.english}
                            />
                          </Grid>

                          <Grid xs={12} md={6} item className="opacity-50">
                            <Input
                              type={"date"}
                              disabled
                              className="opacity-50"
                              value={formData.securityCardExpiry}
                              label={"Security Card Expiry Date"}
                            />
                          </Grid>
                        </>
                      ) : null}
                      {
                        formData.nationality !== "Non-Kuwaiti" && (
                          <>
                            {/* <Grid xs={12} md={6} item>
                              <Input
                                value={formTwoData.passport.value}
                                handleChange={(e) =>
                                  handleChange(e, "passport")
                                }
                                arabicLabel={formLabel.passport.arabic}
                                englishLabel={formLabel.passport.english}
                              />
                              {errors.passport && (
                                <ErrorMessage errorMessage={errors.passport} />
                              )}
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <Input
                                value={formTwoData.passportExpiry.value}
                                handleChange={(e) =>
                                  handleChange(e, "passportExpiry")
                                }
                                type={"date"}
                                label={"Passport Expiry Date"}
                              />
                              {errors.passportExpiry && (
                                <ErrorMessage
                                  errorMessage={errors.passportExpiry}
                                />
                              )}
                            </Grid> */}
                          </>
                        )
                        // : (
                        //   <>
                        //     <Grid xs={12} md={6} item>
                        //       <Input
                        //         endornment={null}
                        //         type={"number"}
                        //         value={formTwoData.securityCard.value}
                        //         handleChange={(e) => {
                        //           if (maxLength(e, 12)) return;
                        //           handleChange(e, "securityCard");
                        //         }}
                        //         arabicLabel={formLabel.securityCard.arabic}
                        //         englishLabel={formLabel.securityCard.english}
                        //       />
                        //       {errors.securityCard && (
                        //         <ErrorMessage errorMessage={errors.nationality} />
                        //       )}
                        //     </Grid>

                        //     <Grid xs={12} md={6} item>
                        //       <Input
                        //         type={"date"}
                        //         value={formTwoData.securityCardExpiry.value}
                        //         handleChange={(e) =>
                        //           handleChange(e, "securityCardExpiry")
                        //         }
                        //         label={"Security Card Expiry Date"}
                        //       />
                        //       {errors.securityCardExpiry && (
                        //         <ErrorMessage
                        //           errorMessage={errors.securityCardExpiry}
                        //         />
                        //       )}
                        //     </Grid>
                        //   </>
                        // )
                      }
                    </Grid>
                    <Grid item container spacing={1}></Grid>
                  </Grid>
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.addressDetails.arabic}
                  englishLabel={formLabel.headings.addressDetails.english}
                >
                  <Grid container spacing={1}>
                    <Grid item container spacing={4}>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.address?.block}
                          arabicLabel={formLabel.address.block.arabic}
                          englishLabel={formLabel.address.block.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.address?.street}
                          arabicLabel={formLabel.address.street.arabic}
                          englishLabel={formLabel.address.street.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.address?.appartment}
                          arabicLabel={formLabel.address.appartment.arabic}
                          englishLabel={formLabel.address.appartment.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.address?.area}
                          arabicLabel={formLabel.address.area.arabic}
                          englishLabel={formLabel.address.area.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          arabicLabel={formLabel.address.jaddah.arabic}
                          englishLabel={formLabel.address.jaddah.english}
                          type="number"
                          disabled
                          value={formData.address?.jaddah}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          arabicLabel={formLabel.address.country.arabic}
                          englishLabel={formLabel.address.country.english}
                          disabled
                          value={formData.address?.country}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          arabicLabel={formLabel.address.postalOffice.arabic}
                          englishLabel={formLabel.address.postalOffice.english}
                          value={formData.address?.po}
                        />
                        if (!isNotArabic(e)) return;
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.address?.zipCode}
                          arabicLabel={formLabel.address.zipCode.arabic}
                          englishLabel={formLabel.address.zipCode.english}
                        />
                      </Grid>

                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          arabicLabel={formLabel.address.governorate.arabic}
                          englishLabel={formLabel.address.governorate.english}
                          disabled
                          value={formData.address?.governorate}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}></Grid>
                  </Grid>
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.contactDetails.arabic}
                  englishLabel={formLabel.headings.contactDetails.english}
                >
                  <Grid container spacing={1}>
                    <Grid item container spacing={4}>
                      <Grid xs={12} md={6} item className="opacity-50">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "0.5rem"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.contactInfo.mobileOne.arabic}
                            englishLabel={
                              formLabel.contactInfo.mobileOne.english
                            }
                          />

                          <PhoneInput
                            disabled
                            value={formData.mobileOne}
                            inputStyle={{
                              width: "100%",
                              background: "#F6F2F8",
                              border: "1px solid lightgray",
                              borderRadius: "12px",
                              height: "2.6rem"
                            }}
                            country={"kw"}
                            inputProps={{
                              required: true
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid xs={12} md={6} item className="opacity-50">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "0.5rem"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.contactInfo.homeTelNo.arabic}
                            englishLabel={
                              formLabel.contactInfo.homeTelNo.english
                            }
                          />

                          <PhoneInput
                            disabled
                            value={formData.homeTelOne}
                            inputStyle={{
                              width: "100%",
                              background: "#F6F2F8",
                              border: "1px solid lightgray",
                              borderRadius: "12px",
                              height: "2.6rem"
                            }}
                            country={"kw"}
                          />
                        </div>
                      </Grid>

                      <Grid xs={12} md={6} item className="opacity-50">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "0.3rem"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={
                              formLabel.contactInfo.emailAddress.arabic
                            }
                            englishLabel={
                              formLabel.contactInfo.emailAddress.english
                            }
                          />

                          <TextField
                            // required={true}
                            size={"small"}
                            color="primary"
                            disabled
                            value={formData.emailOne}
                            sx={{
                              background: "#F6F2F8",
                              width: "100%",
                              borderColor: "lightgray",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "none",
                                  borderRadius: "8px" // Specify your desired border color here
                                },

                                "&.Mui-focused fieldset": {
                                  borderColor: "black" // Specify your desired focused border color here
                                }
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailOutlinedIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}></Grid>
                  </Grid>
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.fatherDetails.arabic}
                  englishLabel={formLabel.headings.fatherDetails.english}
                >
                  <Grid container spacing={1}>
                    <Grid item container spacing={4}>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.family?.fatherName}
                          arabicLabel={formLabel.family.fatherName.arabic}
                          englishLabel={formLabel.family.fatherName.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <Input
                          type={"date"}
                          value={formTwoData.family.fatherDob.value}
                          handleChange={(e) => {
                            if (new Date(e.target.value).getFullYear() > 2099)
                              return;
                            handleChange(e, "family", "fatherDob");
                          }}
                          arabicLabel={formLabel.family.fatherDob.arabic}
                          englishLabel={formLabel.family.fatherDob.english}
                        />
                        {errors.fatherDob && (
                          <ErrorMessage errorMessage={errors.fatherDob} />
                        )}
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <Input
                          value={formTwoData.family.fatherNationality.value}
                          handleChange={(e) =>
                            handleChange(e, "family", "fatherNationality")
                          }
                          arabicLabel={
                            formLabel.family.fatherNationality.arabic
                          }
                          englishLabel={
                            formLabel.family.fatherNationality.english
                          }
                          option={countryListOptionWithBedoon}
                          customMenuItemClass={customBorderBottom}
                          select={true}
                        />
                        {errors.fatherNationality && (
                          <ErrorMessage
                            errorMessage={errors.fatherNationality}
                          />
                        )}
                      </Grid>

                      <Grid xs={12} md={4} item>
                        <Input
                          type={"number"}
                          value={formTwoData.family.fatherCivilId.value}
                          handleChange={(e) => {
                            if (maxLength(e, 12)) return;
                            handleChange(e, "family", "fatherCivilId");
                          }}
                          arabicLabel={formLabel.family.fatherCivilId.arabic}
                          englishLabel={formLabel.family.fatherCivilId.english}
                        />
                        {errors.fatherCivilId && (
                          <ErrorMessage errorMessage={errors.fatherCivilId} />
                        )}
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.family?.fatherOccup}
                          arabicLabel={formLabel.family.fatherOccup.arabic}
                          englishLabel={formLabel.family.fatherOccup.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          value={formData.family?.fatherEmployer}
                          disabled
                          arabicLabel={formLabel.family.fatherEmployer.arabic}
                          englishLabel={formLabel.family.fatherEmployer.english}
                        />
                      </Grid>

                      <Grid xs={12} md={4} item className="opacity-50">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.family.fatherMobile.arabic}
                            englishLabel={formLabel.family.fatherMobile.english}
                          />

                          <PhoneInput
                            inputStyle={{
                              width: "100%",
                              background: "#F6F2F8",
                              height: "2.6rem"
                            }}
                            country={"kw"}
                            disabled
                            value={formData.family?.fatherMobile}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "0.2rem"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.family.fatherWorkTel.arabic}
                            englishLabel={
                              formLabel.family.fatherWorkTel.english
                            }
                          />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PhoneInput
                              inputStyle={{
                                width: "100%",
                                background: "#F6F2F8",
                                height: "2.6rem"
                              }}
                              country={"kw"}
                              value={formTwoData.family.fatherWorkTel.value}
                              onChange={(value, countryData) => {
                                const countryCodeNumber = countryData?.dialCode;
                                handleChange(
                                  { target: { value } },
                                  "family",
                                  "fatherWorkTel"
                                );
                                handleChange(
                                  { target: { value: countryCodeNumber } },
                                  "family",
                                  "fatherWorkTel",
                                  "phoneCode"
                                );
                              }}
                            />
                          </div>
                          {errors.fatherWorkTel && (
                            <ErrorMessage errorMessage={errors.fatherWorkTel} />
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.family.fatherEmail.arabic}
                            englishLabel={formLabel.family.fatherEmail.english}
                          />

                          <TextField
                            size={"small"}
                            color="primary"
                            value={formTwoData.family.fatherEmail.value}
                            onChange={(e) =>
                              handleChange(e, "family", "fatherEmail")
                            }
                            sx={{
                              width: "100%",
                              background: "#F6F2F8",
                              borderColor: "lightgray",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "none",
                                  borderRadius: "12px"
                                },

                                "&.Mui-focused fieldset": {
                                  borderColor: "black"
                                }
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailOutlinedIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                          {errors.fatherEmail && (
                            <ErrorMessage errorMessage={errors.fatherEmail} />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}></Grid>
                  </Grid>
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.motherDetails.arabic}
                  englishLabel={formLabel.headings.motherDetails.english}
                >
                  <Grid container spacing={1}>
                    <Grid item container spacing={4}>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.family?.motherName}
                          arabicLabel={formLabel.family.motherName.arabic}
                          englishLabel={formLabel.family.motherName.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <Input
                          type={"date"}
                          value={formTwoData.family.motherDob.value}
                          handleChange={(e) => {
                            if (new Date(e.target.value).getFullYear() > 2099)
                              return;
                            handleChange(e, "family", "motherDob");
                          }}
                          arabicLabel={formLabel.family.motherDob.arabic}
                          englishLabel={formLabel.family.motherDob.english}
                        />
                        {errors.motherDob && (
                          <ErrorMessage errorMessage={errors.motherDob} />
                        )}
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <Input
                          value={formTwoData.family.motherNationality.value}
                          handleChange={(e) =>
                            handleChange(e, "family", "motherNationality")
                          }
                          arabicLabel={
                            formLabel.family.motherNationality.arabic
                          }
                          englishLabel={
                            formLabel.family.motherNationality.english
                          }
                          option={countryListOptionWithBedoon}
                          customMenuItemClass={customBorderBottom}
                          select={true}
                        />
                        {errors.motherNationality && (
                          <ErrorMessage
                            errorMessage={errors.motherNationality}
                          />
                        )}
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <Input
                          value={formTwoData.family.motherCivilId.value}
                          handleChange={(e) => {
                            if (maxLength(e, 12)) return;
                            handleChange(e, "family", "motherCivilId");
                          }}
                          type={"number"}
                          arabicLabel={formLabel.family.motherCivilId.arabic}
                          englishLabel={formLabel.family.motherCivilId.english}
                        />
                        {errors.motherCivilId && (
                          <ErrorMessage errorMessage={errors.motherCivilId} />
                        )}
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.family?.motherOccup}
                          arabicLabel={formLabel.family.motherOccup.arabic}
                          englishLabel={formLabel.family.motherOccup.english}
                        />
                      </Grid>
                      <Grid xs={12} md={4} item className="opacity-50">
                        <Input
                          disabled
                          value={formData.family?.motherEmployer}
                          arabicLabel={formLabel.family.motherEmployer.arabic}
                          englishLabel={formLabel.family.motherEmployer.english}
                        />
                      </Grid>

                      <Grid xs={12} md={4} item className="opacity-50">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            width: "100%"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.family.motherMobile.arabic}
                            englishLabel={formLabel.family.motherMobile.english}
                          />
                          <PhoneInput
                            inputStyle={{
                              width: "100%",
                              background: "#F6F2F8",
                              height: "2.6rem"
                            }}
                            country={"kw"}
                            disabled
                            value={formData.family?.motherMobile}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "0.2rem"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.family.motherWorkTel.arabic}
                            englishLabel={
                              formLabel.family.motherWorkTel.english
                            }
                          />

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <PhoneInput
                              inputStyle={{
                                width: "100%",
                                background: "#F6F2F8",
                                height: "2.6rem"
                              }}
                              country={"kw"}
                              value={formTwoData.family.motherWorkTel.value}
                              onChange={(value, countryData) => {
                                const countryCodeNumber = countryData?.dialCode;
                                handleChange(
                                  { target: { value } },
                                  "family",
                                  "motherWorkTel"
                                );
                                handleChange(
                                  { target: { value: countryCodeNumber } },
                                  "family",
                                  "motherWorkTel",
                                  "phoneCode"
                                );
                              }}
                            />
                          </div>
                          {errors.motherWorkTel && (
                            <ErrorMessage errorMessage={errors.motherWorkTel} />
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column"
                          }}
                        >
                          <FormFieldLabel
                            arabicLabel={formLabel.family.motherEmail.arabic}
                            englishLabel={formLabel.family.motherEmail.english}
                          />

                          <TextField
                            size={"small"}
                            color="primary"
                            value={formTwoData.family.motherEmail.value}
                            onChange={(e) =>
                              handleChange(e, "family", "motherEmail")
                            }
                            sx={{
                              width: "100%",
                              background: "#F6F2F8",
                              borderColor: "lightgray",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "none",
                                  borderRadius: "12px"
                                },

                                "&.Mui-focused fieldset": {
                                  borderColor: "black"
                                }
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailOutlinedIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                          {errors.motherEmail && (
                            <ErrorMessage errorMessage={errors.motherEmail} />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}></Grid>
                  </Grid>
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.financialDetails.arabic}
                  englishLabel={formLabel.headings.financialDetails.english}
                >
                  <Grid container spacing={1} className="opacity-50">
                    <p className="font-semibold text-zinc-500 w-full flex justify-between flex-col">
                      <span className="text-right">
                        How do you expect to meet the cost of tuition and other
                        expenses? Check all that applies
                      </span>
                      <span className=" text-right">
                        المسئول عن دفع وسداد مصاريف الدراسة وغيرها؟
                      </span>
                    </p>
                    <Grid xs={12} md={12} item>
                      <div className="flex justify-start flex-col w-full">
                        <div className="flex flex-col mt-3">
                          <p className="capitalize font-semibold text-xl">
                            {formData.sponsorship}
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  {formData.sponsorship === "others" && (
                    <div className="w-full mt-3">
                      <textarea
                        value={formData.sponsorshipOtherNote}
                        disabled
                        className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
                      />
                    </div>
                  )}
                  {formData.sponsorship === "ministry" &&
                    formData.sponsorshipNote !== "" && (
                      <div className="w-full mt-3 opacity-50">
                        <textarea
                          value={formData.sponsorshipNote}
                          disabled
                          className="w-full font-semibold h-24 resize-none rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
                        />
                      </div>
                    )}
                </CommonAccordion>
                <CommonAccordion
                  arabicLabel={formLabel.headings.healthPageTitle.arabic}
                  englishLabel={formLabel.headings.healthPageTitle.english}
                >
                  <Box
                    sx={{
                      padding: "1rem",
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      color: "#606060",
                      opacity: 0.6
                    }}
                  >
                    <p className="flex flex-col">
                      <span>{formLabel.healthDetails.title.english}</span>
                      <br />
                      <span className="text-right">
                        {formLabel.healthDetails.title.arabic}
                      </span>
                    </p>
                  </Box>

                  {formData.isHealthRecord ? (
                    <>
                      <Grid container spacing={1}>
                        <Grid xs={12} md={12} item className="opacity-60">
                          <div className="flex justify-start flex-col w-full">
                            <div
                              className="flex flex-col w-full font-semibold gap-3 justify-end mt-3"
                              style={{
                                fontSize: "1.2rem"
                              }}
                            >
                              <span className="opacity-60">
                                {formData.isHealthRecord ? "Yes" : "No"}
                              </span>
                              {formData.healthNote && (
                                <p className="opacity-60">
                                  Health note: {formData.healthNote}
                                </p>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Box
                      sx={{
                        padding: "1rem",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        color: "#606060",
                        textAlign: "left",
                        opacity: 0.5
                      }}
                    >
                      <span>No</span>
                    </Box>
                  )}
                </CommonAccordion>
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.academicPageTitle.arabic}
                  englishLabel={formLabel.headings.academicPageTitle.english}
                >
                  <Box className="flex flex-col">
                    <h4 className="flex justify-between font-bold">
                      <span>{formLabel.headings.academicDetails.english}</span>
                      <span>{formLabel.headings.academicDetails.arabic}</span>
                    </h4>
                  </Box>

                  <TableContainer component={Paper} className="opacity-60">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="font-bold">
                              {formLabel.school.name.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.school.name.arabic}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="font-bold">
                              {formLabel.school.gpa.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.school.gpa.arabic}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="font-bold">
                              {formLabel.school.date.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.school.date.arabic}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="font-bold">
                              {formLabel.school.degree.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.school.degree.arabic}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.education
                          ?.filter((row) => row.type === "school")
                          ?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell align="center">
                                <Input value={row.name} disabled />
                              </TableCell>
                              <TableCell align="center">
                                <Input
                                  value={row.gpa}
                                  type={"number"}
                                  disabled
                                />
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                <Input
                                  type={"date"}
                                  value={row.date}
                                  disabled
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Input value={row.degree} disabled />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TableContainer component={Paper} className="mt-4 opacity-60">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow className=" ">
                          <TableCell
                            align="center"
                            className="px-6 font-semibold"
                          >
                            <span className="font-semibold">Physics</span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="font-semibold">Chemistry</span>
                          </TableCell>
                          <TableCell align="center" className="font-semibold">
                            <span className="font-semibold">Biology</span>
                          </TableCell>
                          <TableCell align="center" className="">
                            <span className="font-semibold">
                              Average of scientific subjects
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell align="center">
                            <Input
                              type={"number"}
                              disabled
                              value={formData.scientific_sub?.physics}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Input
                              type={"number"}
                              disabled
                              value={formData.scientific_sub?.chemistry}
                            />
                          </TableCell>

                          <TableCell align="center">
                            <Input
                              type={"number"}
                              disabled
                              value={formData.scientific_sub?.biology}
                            />
                          </TableCell>
                          <TableCell>
                            {formData.scientific_sub?.average !== "" &&
                            formData.scientific_sub?.average !== 0
                              ? Math.floor(
                                  formData.scientific_sub?.average * 100
                                ) / 100
                              : ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CommonAccordion>
                {formData.education?.filter((row) => row.type === "college")
                  .length > 0 ? (
                  <div className="px-8 py-3 opacity-60">
                    <Box>
                      <h4 className="font-semibold flex flex-col">
                        <span>
                          List all Colleges and Universities you attended
                          starting with the most recent (if not applicable, skip
                          this section)
                        </span>
                        <br />{" "}
                        <span className="text-right">
                          قائمة بأسماء الجامعات والكليات المسجل بها سابقا (
                          ابتدءا من الأحدث)
                        </span>
                      </h4>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <span className="font-bold">
                                {formLabel.college.name.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.college.name.arabic}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="font-bold">
                                {formLabel.college.country.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.college.country.arabic}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="font-bold">
                                {formLabel.college.gpa.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.college.gpa.arabic}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="font-bold">
                                {formLabel.college.major.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.college.major.arabic}
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="font-bold">
                                {formLabel.college.degree.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.college.degree.arabic}
                              </span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formData.education
                            ?.filter((row) => row.type === "college")
                            ?.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell align="center">
                                  <Input value={row.name} disabled />
                                </TableCell>
                                <TableCell align="center">
                                  <Input value={row.country} disabled />
                                </TableCell>
                                <TableCell align="center">
                                  {" "}
                                  <Input
                                    value={row.gpa}
                                    type={"number"}
                                    disabled
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Input value={row.major} disabled />{" "}
                                </TableCell>
                                <TableCell align="center">
                                  <Input value={row.degree} disabled />{" "}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) : null}

                {Object.keys(formData.archivements).length > 0 ? (
                  <Box
                    sx={{ fontSize: "1.2rem", fontWeight: "1rem" }}
                    className="px-8 border-b-[1px] pb-3"
                  >
                    <h4 className="font-semibold flex flex-col my-3 opacity-55">
                      <span>
                        Academic Distinctions, Awards, Prizes, etc. (Indicate
                        years)
                      </span>
                      <br />
                      <span className="text-right">
                        اذكر، أن وجد، الأعمال الأكاديمية المتميزة التي قمت بها
                        أو الجوائز العلمية الحاصل عليها. مع ذكر السنة
                      </span>
                    </h4>
                    <div>
                      <div className="mb-3 opacity-55">
                        {/* {Object.keys(formData.archivements[0]).map(
                          (key, index) => (
                            <li key={index}>{formData.archivements[key]}</li>
                          )
                        )} */}
                        <div className="mb-3 opacity-55">
                          <ol className="list-decimal px-4">
                            {Object.keys(formData.archivements).map(
                              (row, index) => {
                                return (
                                  <li key={index} className="font-semibold">
                                    {formData.archivements[row]}
                                  </li>
                                );
                              }
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end"></div>
                  </Box>
                ) : null}
                {formData.isSuspended ? (
                  <Box className="px-4 opacity-60 ">
                    <Box
                      sx={{
                        padding: "1rem",
                        fontSize: "1.2rem",
                        fontWeight: "1rem"
                      }}
                    >
                      <h4 className="font-semibold flex flex-col my-2">
                        <span>
                          Have you ever been subject to any academic or
                          disciplinary action (i.e. probation, suspension,
                          dismissal etc..) From any institution attended?
                        </span>
                        <br />{" "}
                        <span className="text-right">
                          هل سبق لك خضعت لأية إجراءات تأديبية أو أكاديمية او
                          وضعت لتحت المراقبة، او تعليق القيد ، او الطرد من أي
                          مؤسسة تعليمية من قبل؟
                        </span>
                      </h4>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <div>
                          {formData.isSuspended ? (
                            <span>Yes</span>
                          ) : (
                            <span>No</span>
                          )}
                        </div>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          padding: "1rem"
                        }}
                      >
                        <TextField
                          disabled
                          // required={formData.isSuspended.value}
                          value={formData.suspensionNote}
                          multiline
                          rows={4}
                          size={"small"}
                          color="primary"
                          // id="outlined-start-adornment"
                          // sx={{ m: 1, width: '100%' }}
                          sx={{
                            width: "100%",

                            borderColor: "lightgray",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                // height: "8rem",
                                borderColor: "none",
                                borderRadius: "12px" // Specify your desired border color here
                              },
                              //   '&:hover fieldset': {
                              //     borderColor: 'green', // Specify your desired hover border color here
                              //   },
                              "&.Mui-focused fieldset": {
                                borderColor: "black" // Specify your desired focused border color here
                              }
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                <CommonAccordion
                  defaultExpanded={true}
                  arabicLabel={formLabel.headings.languageDetails.arabic}
                  englishLabel={formLabel.headings.languageDetails.english}
                >
                  <Box>
                    <h4
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "1rem"
                      }}
                      className="form mb-2 flex justify-between"
                    >
                      <span>{formLabel.languages.title.english}</span>
                      <span>{formLabel.languages.title.arabic}</span>
                    </h4>
                  </Box>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <span className="font-bold">
                              {formLabel.languages.subTitle.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.languages.subTitle.arabic}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-bold">
                              {formLabel.languages.read.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.languages.read.arabic}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-bold">
                              {formLabel.languages.write.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.languages.write.arabic}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-bold">
                              {formLabel.languages.speak.english}
                            </span>
                            <span className="font-bold"> / </span>
                            <span className="font-bold">
                              {formLabel.languages.speak.arabic}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 }
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="center">
                              {row.read}
                              {errors?.languages?.[index]?.languageRead && (
                                <ErrorMessage
                                  errorMessage={
                                    errors?.languages?.[index].languageRead
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.write}
                              {errors?.languages?.[index]?.languageWrite && (
                                <ErrorMessage
                                  errorMessage={
                                    errors?.languages?.[index].languageWrite
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {row.speak}
                              {errors?.languages?.[index]?.languageSpeak && (
                                <ErrorMessage
                                  errorMessage={
                                    errors?.languages?.[index].languageSpeak
                                  }
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CommonAccordion>
                <CommonAccordion title="English professionally test">
                  <TableContainer component={Paper} className="mt-4">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2}>
                            English professionally test
                          </TableCell>
                          <TableCell align="right">Score</TableCell>
                          <TableCell align="right">Date</TableCell>
                          <TableCell align="right">Test Type</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="right">
                            <Input
                              type={"number"}
                              value={formData.english_test?.score}
                              disabled
                              // disabled={
                              //   formTwoData.test.testType.value === "NA"
                              //     ? true
                              //     : false
                              // }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Input
                              type={"date"}
                              value={formData.english_test?.date}
                              disabled
                              // disabled={
                              //   formTwoData.test.testType.value === "NA"
                              //     ? true
                              //     : false
                              // }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Input
                              disabled
                              value={formData.english_test?.type}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </CommonAccordion>
                <Box sx={{ gap: "10rem" }}>
                  <Box>
                    <CommonAccordion
                      defaultExpanded={true}
                      englishLabel={
                        formLabel.headings.emergencyPageTitle.english
                      }
                      arabicLabel={formLabel.headings.emergencyPageTitle.arabic}
                    >
                      <Grid
                        container
                        spacing={1}
                        className="border-[1px] p-3 rounded-md"
                        style={{ marginTop: ".1rem" }}
                      >
                        <p className="flex gap-1 items-center my-3 font-bold uppercase ml-2">
                          <IoPersonCircle className="text-2xl text-[#B70042] " />
                          Person 1
                        </p>
                        <Grid item container spacing={4}>
                          <Grid xs={12} md={6} item>
                            <Input
                              englishLabel={formLabel.emergency.name.english}
                              arabicLabel={formLabel.emergency.name.arabic}
                              value={formTwoData.emergency[0].name.value}
                              handleChange={(e) => {
                                if (!isEnglish(e)) return;

                                handleChange(e, "emergency", "0", "name");
                              }}
                            />
                            {errors?.emergencies?.[0]?.emergencyName && (
                              <ErrorMessage
                                errorMessage={
                                  errors?.emergencies?.[0]?.emergencyName
                                }
                              />
                            )}
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Input
                              englishLabel={
                                formLabel.emergency.relationship.english
                              }
                              arabicLabel={
                                formLabel.emergency.relationship.arabic
                              }
                              value={
                                formTwoData.emergency[0].relationship.value
                              }
                              handleChange={(e) => {
                                if (!isEnglish(e)) return;

                                handleChange(
                                  e,
                                  "emergency",
                                  "0",
                                  "relationship"
                                );
                              }}
                            />
                            {errors?.emergencies?.[0]
                              ?.emergencyRelationship && (
                              <ErrorMessage
                                errorMessage={
                                  errors?.emergencies?.[0]
                                    ?.emergencyRelationship
                                }
                              />
                            )}
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Input
                              type={"number"}
                              englishLabel={formLabel.emergency.civilId.english}
                              arabicLabel={formLabel.emergency.civilId.arabic}
                              value={formTwoData.emergency[0].civilId.value}
                              handleChange={(e) => {
                                if (maxLength(e, 12)) return;
                                handleChange(e, "emergency", "0", "civilId");
                              }}
                            />
                            {errors?.emergencies?.[0]?.emergencyCivilId && (
                              <ErrorMessage
                                errorMessage={
                                  errors?.emergencies?.[0]?.emergencyCivilId
                                }
                              />
                            )}
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                gap: "0.3rem"
                              }}
                            >
                              <FormFieldLabel
                                englishLabel={
                                  formLabel.emergency.contact.english
                                }
                                arabicLabel={formLabel.emergency.contact.arabic}
                              />

                              <PhoneInput
                                inputStyle={{
                                  width: "100%",
                                  border: "1px solid lightgray",
                                  borderRadius: "8px",
                                  background: "#F6F2F8",
                                  height: "2.6rem"
                                  /* Add any additional styles you need */
                                }}
                                country={"kw"}
                                value={formTwoData.emergency[0].contact.value}
                                // onlyCountries={['in', 'de', 'ru']}
                                onChange={(value, countryData) => {
                                  const countryCodeNumber =
                                    countryData?.dialCode;
                                  handleChange(
                                    { target: { value } },
                                    "emergency",
                                    "0",
                                    "contact"
                                  );
                                  handleChange(
                                    { target: { value: countryCodeNumber } },
                                    "emergency",
                                    "0",
                                    "contact",
                                    "phoneCode"
                                  );
                                }}
                              />
                              {errors?.emergencies?.[0]?.emergencyContact && (
                                <ErrorMessage
                                  errorMessage={
                                    errors?.emergencies?.[0]?.emergencyContact
                                  }
                                />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={1}
                        className="border-[1px] p-3 rounded-md"
                        style={{ marginTop: "1rem" }}
                      >
                        <p className="flex gap-1 items-center my-3 font-bold uppercase ml-2">
                          <IoPersonCircle className="text-2xl text-[#B70042] " />
                          Person 2 <i className="lowercase">(optional)</i>
                        </p>
                        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center ml-2">
                          <input
                            type="checkbox"
                            name="autoSaver"
                            className="sr-only "
                            checked={formTwoData.emergency[1].personTwo.value}
                            onChange={(e) => {
                              handleChange(
                                { target: { value: e.target.checked } },
                                "emergency",
                                "1",
                                "personTwo"
                              );
                            }}
                          />
                          <span
                            className={`slider mr-3 flex h-[22px] w-[40px] items-center rounded-full p-1 duration-200 ${
                              isChecked ? "bg-[#B70042]" : "bg-[#CCCCCE]"
                            }`}
                          >
                            <span
                              className={`dot h-[16px] w-[16px] rounded-full bg-white duration-200 ${
                                isChecked ? "translate-x-4" : ""
                              }`}
                            ></span>
                          </span>
                          <span className="label flex items-center text-sm font-medium text-black"></span>
                        </label>
                        {isChecked && (
                          <Grid item container spacing={4}>
                            <Grid xs={12} md={6} item>
                              <Input
                                englishLabel={formLabel.emergency.name.english}
                                arabicLabel={formLabel.emergency.name.arabic}
                                value={formTwoData.emergency[1].name.value}
                                handleChange={(e) =>
                                  handleChange(e, "emergency", "1", "name")
                                }
                              />
                              {errors?.emergencies?.[1]?.emergencyName && (
                                <ErrorMessage
                                  errorMessage={
                                    errors?.emergencies?.[1]?.emergencyName
                                  }
                                />
                              )}
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <Input
                                englishLabel={
                                  formLabel.emergency.relationship.english
                                }
                                arabicLabel={
                                  formLabel.emergency.relationship.arabic
                                }
                                value={
                                  formTwoData.emergency[1].relationship.value
                                }
                                handleChange={(e) =>
                                  handleChange(
                                    e,
                                    "emergency",
                                    "1",
                                    "relationship"
                                  )
                                }
                              />
                              {errors?.emergencies?.[1]
                                ?.emergencyRelationship && (
                                <ErrorMessage
                                  errorMessage={
                                    errors?.emergencies?.[1]
                                      ?.emergencyRelationship
                                  }
                                />
                              )}
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <Input
                                englishLabel={
                                  formLabel.emergency.civilId.english
                                }
                                arabicLabel={formLabel.emergency.civilId.arabic}
                                type={"number"}
                                value={formTwoData.emergency[1].civilId.value}
                                handleChange={(e) => {
                                  if (maxLength(e, 12)) return;
                                  handleChange(e, "emergency", "1", "civilId");
                                }}
                              />
                            </Grid>

                            <Grid xs={12} md={6} item>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexDirection: "column",
                                  gap: "0.3rem"
                                }}
                              >
                                <FormFieldLabel
                                  englishLabel={
                                    formLabel.emergency.contact.english
                                  }
                                  arabicLabel={
                                    formLabel.emergency.contact.arabic
                                  }
                                />

                                <PhoneInput
                                  inputStyle={{
                                    width: "100%",
                                    border: "1px solid lightgray",
                                    borderRadius: "8px",
                                    background: "#F6F2F8",
                                    height: "2.6rem"
                                  }}
                                  country={"kw"}
                                  value={formTwoData.emergency[1].contact.value}
                                  // onlyCountries={['in', 'de', 'ru']}
                                  onChange={(value, countryData) => {
                                    const countryCodeNumber =
                                      countryData?.dialCode;
                                    handleChange(
                                      { target: { value } },
                                      "emergency",
                                      "1",
                                      "contact"
                                    );
                                    handleChange(
                                      { target: { value: countryCodeNumber } },
                                      "emergency",
                                      "1",
                                      "contact",
                                      "phoneCode"
                                    );
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </CommonAccordion>
                  </Box>
                </Box>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor: "#B70042",
                      padding: "1rem",
                      fontWeight: "bold"
                    }}
                  >
                    <h4 className="form">Documents</h4>
                  </Box>

                  <Box sx={{ padding: "0.5rem", color: "#606060" }}>
                    <Box>
                      <h4 className="my-3 font-bold text-center">
                        {formLabel.document.title.english}
                      </h4>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell> 1</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              <span className="font-bold">
                                {formLabel.document.photograph.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.document.photograph.arabic}
                              </span>
                              {errors.photograph && (
                                <ErrorMessage
                                  errorMessage={errors.photograph}
                                />
                              )}
                              {isImageError !== "" && (
                                <ErrorMessage errorMessage={isImageError} />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"photograph"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 2</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              <span className="font-bold">
                                {formLabel.document.transcript.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.document.transcript.arabic}
                              </span>
                              {errors.transcript && (
                                <ErrorMessage
                                  errorMessage={errors.transcript}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"transcript"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 3</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              <span className="font-bold">
                                {formLabel.document.englishscore.english}
                              </span>
                              <span className="font-bold"> </span>
                              <span className="font-bold">
                                {formLabel.document.englishscore.arabic}
                              </span>
                              {errors.englishscore && (
                                <ErrorMessage
                                  errorMessage={errors.englishscore}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"englishscore"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 4</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              <span className="font-bold">
                                {formLabel.document.highschool.english}
                              </span>
                              <span className="font-bold"> / </span>
                              <span className="font-bold">
                                {formLabel.document.highschool.arabic}
                              </span>
                              {errors.highschool && (
                                <ErrorMessage
                                  errorMessage={errors.highschool}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"highschool"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 5</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              {" "}
                              Copy of the Valid Passport Copy.
                              <br />
                              {errors.passportCopy && (
                                <ErrorMessage
                                  errorMessage={errors.passportCopy}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.3rem"
                                }}
                              >
                                <Download
                                  value={"passport"}
                                  formData={formData}
                                  handleChange={handleChange}
                                  setIsImageError={setIsImageError}
                                  resetDocumentState={resetDocumentState}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 6</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              Applicant of 21 years of age or above and been
                              discontinued from studying for more than one
                              academic year, must submit a recent valid clear
                              criminal record (not exceeding 3 months) upon
                              application.
                              {errors.record && (
                                <ErrorMessage errorMessage={errors.record} />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"record"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 7</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              Certificate of Good Conduct.
                              {errors.goodconduct && (
                                <ErrorMessage
                                  errorMessage={errors.goodconduct}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"goodconduct"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 8</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              Father Civil ID
                              {errors.fatherCivilIdDoc && (
                                <ErrorMessage
                                  errorMessage={errors.fatherCivilIdDoc}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"fatherCivilId"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableHead>
                          <TableRow>
                            <TableCell> 9</TableCell>
                            <TableCell sx={{ fontWeight: "700" }} align="left">
                              Mother Civil ID
                              {errors.motherCivilIdDoc && (
                                <ErrorMessage
                                  errorMessage={errors.motherCivilIdDoc}
                                />
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Download
                                value={"motherCivilId"}
                                formData={formData}
                                handleChange={handleChange}
                                setIsImageError={setIsImageError}
                                resetDocumentState={resetDocumentState}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody></TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
              <Box sx={{}}>
                <Box sx={{ padding: "2rem", paddingBottom: "12rem" }}>
                  <Box></Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{ border: "2px solid redx" }}
                      className="flex  gap-2"
                    >
                      <input
                        type="checkbox"
                        name="isDeclared"
                        id="isDeclared"
                        checked={formTwoData.isDeclared.value === true}
                        onChange={(e) => {
                          handleChange(
                            { target: { value: e.target.checked } },
                            "isDeclared"
                          );
                        }}
                      />
                      <h4 className="text-[#B70042] flex justify-start gap-2 items-center font-bold">
                        <span>{formLabel.declaration.title.english}</span>
                        <span>{formLabel.declaration.title.arabic}</span>
                      </h4>

                      {errors.isDeclared && (
                        <ErrorMessage errorMessage={errors.isDeclared} />
                      )}
                    </div>
                    <div className="mt-5">
                      <p className="font-semibold flex flex-col text-justify">
                        <span>
                          {formLabel.declaration.declarationText.english}
                        </span>
                        <br />
                        <span className="text-right">
                          {formLabel.declaration.declarationText.arabic}
                        </span>
                      </p>
                    </div>
                  </Box>
                </Box>
                <div className="bg-white w-full border-[1px] border-t-[#b70042] flex justify-center fixed left-0 bottom-0 items-center py-4">
                  <button
                    onClick={() => handleSubmit()}
                    className="bg-[#b70042] disabled:bg-rose-300 text-white font-bold px-10 py-3 rounded-lg hover:bg-rose-700"
                  >
                    Submit
                  </button>
                </div>
              </Box>
            </Box>
          </>
        )
      )}
      <ToastContainer />
    </>
  );
};

const Download = ({
  handleChange,
  value,
  setIsImageError,
  resetDocumentState
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [sizeError, setSizeError] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (value === "photograph" && selectedFile.type === "application/pdf") {
      setIsImageError("Please upload image");
      return;
    } else {
      setIsImageError("");
    }

    let compressedImage;
    if (
      selectedFile.size / 1000 > 1024 &&
      selectedFile.type !== "application/pdf"
    ) {
      compressedImage = await resizeFile(selectedFile);
    }

    if (
      selectedFile.type === "application/pdf" &&
      selectedFile.size / 1000 > 5120
    ) {
      setSizeError(true);
      return;
    }

    // if (!selectedFile) {
    //   console.log("No file is selected");
    // }

    const updatedFormData = {
      target: {
        value: compressedImage || selectedFile
      }
    };

    handleChange(updatedFormData, "document", value);
    setSelectedFile(updatedFormData);
  };

  useEffect(() => {
    if (selectedFile) setSizeError(false);
  }, [selectedFile]);

  useEffect(() => {
    if (resetDocumentState) {
      setSelectedFile(null);
      setSizeError(false);
    }
  }, [resetDocumentState]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "0.5rem"
      }}
    >
      <div className="flex items-center justify-center gap-[.5rem]">
        <div
          className={`${
            !selectedFile && "bg-slate-200"
          } rounded-lg flex items-center justify-center w-[80px] h-[66px]`}
        >
          {!selectedFile ? (
            <MdFileUploadOff className="text-[50px] text-slate-500" />
          ) : (
            <GoCheckCircleFill className="text-[50px] text-[#17bebb]" />
          )}
        </div>
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png,application/pdf"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <button
          type="button"
          disabled={selectedFile}
          onClick={handleButtonClick}
          className={`${
            !selectedFile
              ? "bg-[#b70042] hover:bg-rose-700"
              : "bg-slate-500 opacity-55 hidden"
          }  h-full px-4 lg:py-4 py-2 text-white font-bold rounded-lg `}
        >
          Upload Document
        </button>
      </div>
      {sizeError ? (
        <p className="bg-rose-200 w-fit-content px-2 rounded-lg text-left py-1 font-bold text-rose-500 my-2 mb-2 ">
          * The PDF file size exceeds 5MB.
        </p>
      ) : null}
    </Box>
  );
};

export default ConfirmationPage;
