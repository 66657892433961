import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./AdmissionForm.css";
import { Grid } from "@mui/material";
import CommonAccordion from "./CommonAccordion";
import Input from "./Input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { countryListOptionWithBedoon } from "../utils/methods";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phoneInput.css";
import { customBorderBottom } from "../utils/methods";
import { formLabel } from "../utils/formFieldLabels";
import FormFieldLabel from "./FormFieldLabel";
import ErrorMessage from "./ErrorMessage";
import ErrorTitle from "./ErrorTitle";
import { maxLength, isEnglish } from "../utils/methods";

const AdmissionForm2 = ({ formData, handleChange, errors, showError }) => {
  const [sponsorship, setSponsorship] = useState("");
  const [relativeWorking, setRelativeWorking] = useState("");

  const financialData = [
    {
      title: "Parents",
      value: "parents",
      english: "Parents ",
      arabic: "الوالدين"
    },
    {
      title: "Myself",
      value: "myself",
      english: "Myself",
      arabic: "الطالب نفسه"
    },
    {
      title: "Ministry",
      value: "ministry",
      english: "Ministry",
      arabic: "وزارة"
    },
    {
      title: "Others",
      value: "others",
      english: "Others",
      arabic: "اخرى"
    }
  ];

  useEffect(() => {
    document.title = "AUMS • Family";
    if (localStorage.getItem("FormData")) {
      setSponsorship(Object.keys(formData.sponsorship.value || {})?.[0]);
      setRelativeWorking(formData.relatives.selected.value);
    }
  });

  return (
    <Box sx={{ gap: "10rem" }}>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "space-between",
          backgroundColor: "#B70042",
          color: "white",
          width: "100%",
          padding: "1rem"
        }}
      >
        <span>{formLabel.headings.familyPageTitle.english}</span>
        <span>{formLabel.headings.familyPageTitle.arabic}</span>
      </Box>
      <Box>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.fatherDetails.arabic}
          englishLabel={formLabel.headings.fatherDetails.english}
        >
          <Grid container spacing={1}>
            {showError && <ErrorTitle />}
            <Grid item container spacing={4}>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.family.fatherName.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "fatherName");
                  }}
                  englishLabel={formLabel.family.fatherName.english}
                />
                {errors.fatherName && (
                  <ErrorMessage errorMessage={errors.fatherName} />
                )}
              </Grid>

              <Grid xs={12} md={4} item className="hidden">
                <Input
                  value={formData.family.fatherNationality.value}
                  handleChange={(e) =>
                    handleChange(e, "family", "fatherNationality")
                  }
                  arabicLabel={formLabel.family.fatherNationality.arabic}
                  englishLabel={formLabel.family.fatherNationality.english}
                  select={true}
                  option={countryListOptionWithBedoon}
                  customMenuItemClass={customBorderBottom}
                />
                {errors.fatherNationality && (
                  <ErrorMessage errorMessage={errors.fatherNationality} />
                )}
              </Grid>

              <Grid xs={12} md={4} item className="hidden">
                <Input
                  value={formData.family.fatherCivilId.value}
                  handleChange={(e) => {
                    if (maxLength(e, 12)) return;
                    handleChange(e, "family", "fatherCivilId");
                  }}
                  type={"number"}
                  arabicLabel={formLabel.family.fatherCivilId.arabic}
                  englishLabel={formLabel.family.fatherCivilId.english}
                />
                {errors.fatherCivilId && (
                  <ErrorMessage errorMessage={errors.fatherCivilId} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.family.fatherOccup.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "fatherOccup");
                  }}
                  arabicLabel={formLabel.family.fatherOccup.arabic}
                  englishLabel={formLabel.family.fatherOccup.english}
                />
                {errors.fatherOccup && (
                  <ErrorMessage errorMessage={errors.fatherOccup} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.family.fatherEmployer.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "fatherEmployer");
                  }}
                  arabicLabel={formLabel.family.fatherEmployer.arabic}
                  englishLabel={formLabel.family.fatherEmployer.english}
                />
              </Grid>
              <Grid xs={12} md={4} item className="">
                <Input
                  value={formData.family.fatherDepartment.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "fatherDepartment");
                  }}
                  englishLabel={formLabel.family.fatherDepartment.english}
                  arabicLabel={formLabel.family.fatherDepartment.arabic}
                />
                {errors.fatherDepartment && (
                  <ErrorMessage errorMessage={errors.fatherDepartment} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.family.fatherMobile.arabic}
                    englishLabel={formLabel.family.fatherMobile.english}
                  />

                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      background: "#F6F2F8",
                      height: "2.6rem"
                    }}
                    country={"kw"}
                    value={formData.family.fatherMobile.value}
                    // onlyCountries={['in', 'de', 'ru']}
                    onChange={(value, countryData) => {
                      const countryCodeNumber = countryData?.dialCode;
                      handleChange(
                        { target: { value } },
                        "family",
                        "fatherMobile"
                      );
                      handleChange(
                        { target: { value: countryCodeNumber } },
                        "family",
                        "fatherMobile",
                        "phoneCode"
                      );
                    }}
                  />
                  {errors.fatherMobile && (
                    <ErrorMessage errorMessage={errors.fatherMobile} />
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "0.2rem"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.family.fatherWorkTel.arabic}
                    englishLabel={formLabel.family.fatherWorkTel.english}
                  />

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        background: "#F6F2F8",
                        height: "2.6rem"
                      }}
                      // style={{ width: '100%', border: '1px solid lightgray', borderRadius: "12px" }}
                      country={"kw"}
                      value={formData.family.fatherWorkTel.value}
                      // onlyCountries={['in', 'de', 'ru']}
                      onChange={(value, countryData) => {
                        const countryCodeNumber = countryData?.dialCode;
                        handleChange(
                          { target: { value } },
                          "family",
                          "fatherWorkTel"
                        );
                        handleChange(
                          { target: { value: countryCodeNumber } },
                          "family",
                          "fatherWorkTel",
                          "phoneCode"
                        );
                      }}
                    />
                  </div>
                  {errors.fatherWorkTel && (
                    <ErrorMessage errorMessage={errors.fatherWorkTel} />
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.family.fatherEmail.arabic}
                    englishLabel={formLabel.family.fatherEmail.english}
                  />

                  <TextField
                    size={"small"}
                    color="primary"
                    value={formData.family.fatherEmail.value}
                    onChange={(e) => handleChange(e, "family", "fatherEmail")}
                    sx={{
                      width: "100%",
                      background: "#F6F2F8",
                      borderColor: "lightgray",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",
                          borderRadius: "12px" // Specify your desired border color here
                        },
                        //   '&:hover fieldset': {
                        //     borderColor: 'green', // Specify your desired hover border color here
                        //   },
                        "&.Mui-focused fieldset": {
                          borderColor: "black" // Specify your desired focused border color here
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.fatherEmail && (
                    <ErrorMessage errorMessage={errors.fatherEmail} />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item container spacing={1}></Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.motherDetails.arabic}
          englishLabel={formLabel.headings.motherDetails.english}
        >
          <Grid container spacing={1}>
            <Grid item container spacing={4}>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.family.motherName.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "motherName");
                  }}
                  arabicLabel={formLabel.family.motherName.arabic}
                  englishLabel={formLabel.family.motherName.english}
                />
                {errors.motherName && (
                  <ErrorMessage errorMessage={errors.motherName} />
                )}
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <Input
                  value={formData.family.arabicMotherName.value}
                  handleChange={(e) => {
                    // if (!isEnglish(e)) return;
                    handleChange(e, "family", "arabicMotherName");
                  }}
                  arabicLabel={formLabel.family.motherName.arabic}
                />
                {errors.arabicMotherName && (
                  <ErrorMessage errorMessage={errors.arabicMotherName} />
                )}
                {/* <Input
                  type={"date"}
                  value={formData.family.motherDob.value}
                  handleChange={(e) => handleChange(e, "family", "motherDob")}
                  arabicLabel={formLabel.family.motherDob.arabic}
                  englishLabel={formLabel.family.motherDob.english}
                />
                {errors.motherDob && (
                  <ErrorMessage errorMessage={errors.motherDob} />
                )} */}
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <Input
                  value={formData.family.motherNationality.value}
                  handleChange={(e) =>
                    handleChange(e, "family", "motherNationality")
                  }
                  arabicLabel={formLabel.family.motherNationality.arabic}
                  englishLabel={formLabel.family.motherNationality.english}
                  select={true}
                  option={countryListOptionWithBedoon}
                  customMenuItemClass={customBorderBottom}
                />
                {errors.motherNationality && (
                  <ErrorMessage errorMessage={errors.motherNationality} />
                )}
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <Input
                  value={formData.family.motherCivilId.value}
                  handleChange={(e) => {
                    if (maxLength(e, 12)) return;
                    handleChange(e, "family", "motherCivilId");
                  }}
                  type={"number"}
                  arabicLabel={formLabel.family.motherCivilId.arabic}
                  englishLabel={formLabel.family.motherCivilId.english}
                />
                {errors.motherCivilId && (
                  <ErrorMessage errorMessage={errors.motherCivilId} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.family.motherOccup.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "motherOccup");
                  }}
                  arabicLabel={formLabel.family.motherOccup.arabic}
                  englishLabel={formLabel.family.motherOccup.english}
                />
                {errors.motherOccup && (
                  <ErrorMessage errorMessage={errors.motherOccup} />
                )}
              </Grid>
              <Grid xs={12} md={4} item>
                <Input
                  value={formData.family.motherEmployer.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "motherEmployer");
                  }}
                  arabicLabel={formLabel.family.motherEmployer.arabic}
                  englishLabel={formLabel.family.motherEmployer.english}
                />
              </Grid>
              <Grid xs={12} md={4} item className="">
                <Input
                  value={formData.family.motherDepartment.value}
                  handleChange={(e) => {
                    if (!isEnglish(e)) return;
                    handleChange(e, "family", "motherDepartment");
                  }}
                  englishLabel={formLabel.family.motherDepartment.english}
                  arabicLabel={formLabel.family.motherDepartment.arabic}
                />
                {errors.motherDepartment && (
                  <ErrorMessage errorMessage={errors.motherDepartment} />
                )}
              </Grid>
              {/* <Grid xs={12} md={4} item>

                                <Input isRequired={true} value={formData.family.motherOccup.value} handleChange={(e) => handleChange(e, "family", "motherOccup")} label={"Occupation"} />


                            </Grid>
                            <Grid xs={12} md={4} item>

                                <Input isRequired={true} value={formData.family.motherEmployer.value} handleChange={(e) => handleChange(e, "family", "motherEmployer")} label={"Employer"} />


                            </Grid> */}
              <Grid xs={12} md={4} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "100%"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.family.motherMobile.arabic}
                    englishLabel={formLabel.family.motherMobile.english}
                  />
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      background: "#F6F2F8",
                      height: "2.6rem"
                    }}
                    country={"kw"}
                    value={formData.family.motherMobile.value}
                    // onlyCountries={['in', 'de', 'ru']}
                    onChange={(value, countryData) => {
                      const countryCodeNumber = countryData?.dialCode;
                      handleChange(
                        { target: { value } },
                        "family",
                        "motherMobile"
                      );
                      handleChange(
                        { target: { value: countryCodeNumber } },
                        "family",
                        "motherMobile",
                        "phoneCode"
                      );
                    }}
                  />
                  {errors.motherMobile && (
                    <ErrorMessage errorMessage={errors.motherMobile} />
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "0.2rem"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.family.motherWorkTel.arabic}
                    englishLabel={formLabel.family.motherWorkTel.english}
                  />

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PhoneInput
                      // style={{ width: '100%', border: '1px solid lightgray', borderRadius: "12px" }}
                      inputStyle={{
                        width: "100%",
                        background: "#F6F2F8",
                        height: "2.6rem"
                      }}
                      country={"kw"}
                      value={formData.family.motherWorkTel.value}
                      // onlyCountries={['in', 'de', 'ru']}
                      onChange={(value, countryData) => {
                        const countryCodeNumber = countryData?.dialCode;
                        handleChange(
                          { target: { value } },
                          "family",
                          "motherWorkTel"
                        );
                        handleChange(
                          { target: { value: countryCodeNumber } },
                          "family",
                          "motherWorkTel",
                          "phoneCode"
                        );
                      }}
                    />
                  </div>
                  {errors.motherWorkTel && (
                    <ErrorMessage errorMessage={errors.motherWorkTel} />
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={4} item className="hidden">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column"
                  }}
                >
                  <FormFieldLabel
                    arabicLabel={formLabel.family.motherEmail.arabic}
                    englishLabel={formLabel.family.motherEmail.english}
                  />

                  <TextField
                    size={"small"}
                    color="primary"
                    value={formData.family.motherEmail.value}
                    onChange={(e) => handleChange(e, "family", "motherEmail")}
                    sx={{
                      width: "100%",
                      background: "#F6F2F8",
                      borderColor: "lightgray",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "none",
                          borderRadius: "12px" // Specify your desired border color here
                        },
                        //   '&:hover fieldset': {
                        //     borderColor: 'green', // Specify your desired hover border color here
                        //   },
                        "&.Mui-focused fieldset": {
                          borderColor: "black" // Specify your desired focused border color here
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.motherEmail && (
                    <ErrorMessage errorMessage={errors.motherEmail} />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.financialDetails.arabic}
          englishLabel={formLabel.headings.financialDetails.english}
        >
          <Grid container spacing={1}>
            <p className="font-semibold text-zinc-500 w-full flex justify-between flex-col">
              <span className="text-right">
                How do you expect to meet the cost of tuition and other
                expenses? Check all that applies
              </span>
              <span className=" text-right">
                المسئول عن دفع وسداد مصاريف الدراسة وغيرها؟
              </span>
            </p>
            <Grid xs={12} md={12} item>
              <div className="flex justify-start flex-col w-full">
                <div className="flex flex-col mt-3">
                  {financialData.map((data, index) => {
                    return (
                      <div className="flex justify-end gap-2" key={index}>
                        <label
                          htmlFor="financialSupport"
                          className="font-semibold"
                          onClick={() => {
                            handleChange(
                              { target: { value: { [data.value]: true } } },
                              "sponsorship"
                            );
                          }}
                        >
                          {data.english} / {data.arabic}
                        </label>
                        <input
                          type="radio"
                          name="financialSupport"
                          checked={
                            Object.keys(
                              formData.sponsorship.value || {
                                "": ""
                              }
                            )[0] === data.value
                          }
                          onChange={() =>
                            handleChange(
                              { target: { value: { [data.value]: true } } },
                              "sponsorship"
                            )
                          }
                        />
                      </div>
                    );
                  })}
                  {errors.sponser && (
                    <ErrorMessage
                      alignRight="text-right"
                      errorMessage={errors.sponser}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          {sponsorship === "ministry" && (
            <div className="w-full mt-3">
              {errors.sponsorshipScholarshipNote && (
                <ErrorMessage
                  alignRight="text-right"
                  errorMessage={errors.sponsorshipScholarshipNote}
                />
              )}
              <textarea
                placeholder="Describe"
                value={formData.sponsorshipScholarshipNote.value}
                onChange={(e) => {
                  handleChange(e, "sponsorshipScholarshipNote");
                }}
                className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
              />
            </div>
          )}
          {sponsorship === "others" && (
            <div className="w-full mt-3">
              {errors.sponsorshipOtherNote && (
                <ErrorMessage
                  alignRight="text-right"
                  errorMessage={errors.sponsorshipOtherNote}
                />
              )}
              <textarea
                placeholder="Describe"
                value={formData.sponsorshipOtherNote.value}
                onChange={(e) => handleChange(e, "sponsorshipOtherNote")}
                className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
              />
            </div>
          )}
        </CommonAccordion>

        <CommonAccordion title="Conflict of Interest">
          <Grid item container spacing={1}>
            <div className=" p-4 mb-4 w-full">
              <p className="font-semibold text-lg">
                Do you have any relative that works or have professional
                relation with any of the following?
              </p>
              <div className="font-mediumpack">
                {formData.relatives?.value.map((item, index) => {
                  return (
                    <div
                      className="flex items-center gap-2 py-1"
                      key={item.name}
                    >
                      <input
                        checked={
                          item.value === formData.relatives.selected.value
                        }
                        type="checkbox"
                        name={`${item.name}`}
                        id={`${item.name}`}
                        onChange={() =>
                          handleChange(
                            {
                              target: {
                                value: formData.relatives.value[index].value
                              }
                            },
                            "relatives",
                            "selected"
                          )
                        }
                      />
                      <label htmlFor={`${item.name}`} className="font-medium">
                        {item.name}
                      </label>
                    </div>
                  );
                })}
                {errors.relatives && (
                  <ErrorMessage errorMessage={errors.relatives} />
                )}
              </div>
              {relativeWorking === "mohe" && (
                <div className="w-full mt-3">
                  <div className="flex items-center justify-between">
                    <p className="my-2 text-gray-500 font-semibold">
                      Please specify
                    </p>
                    {errors.relativesNote && (
                      <ErrorMessage
                        alignRight="text-right"
                        errorMessage={errors.relativesNote}
                      />
                    )}
                  </div>
                  <textarea
                    placeholder="Describe"
                    value={formData.relativesNote.value}
                    onChange={(e) => {
                      handleChange(e, "relativesNote");
                    }}
                    className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
                  />
                </div>
              )}

              {relativeWorking === "kuwait university" && (
                <div className="w-full mt-3">
                  <div className="flex items-center justify-between">
                    <p className="my-2 text-gray-500 font-semibold">
                      Please specify
                    </p>
                    {errors.relativesNote && (
                      <ErrorMessage
                        alignRight="text-right"
                        errorMessage={errors.relativesNote}
                      />
                    )}
                  </div>
                  <textarea
                    placeholder="Describe"
                    value={formData.relativesNote.value}
                    onChange={(e) => {
                      handleChange(e, "relativesNote");
                    }}
                    className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
                  />
                </div>
              )}
            </div>
          </Grid>
        </CommonAccordion>

        {/* <CommonAccordion
          defaultExpanded={true}
          arabicLabel={formLabel.headings.financialDetails.arabic}
          englishLabel={formLabel.headings.financialDetails.english}
        >
          <Grid container spacing={1}>
            <p className="font-semibold text-zinc-500 w-full flex justify-between flex-col">
              <span className="text-right">
                How do you expect to meet the cost of tuition and other
                expenses? Check all that applies
              </span>
              <span className=" text-right">
                المسئول عن دفع وسداد مصاريف الدراسة وغيرها؟
              </span>
            </p>
            <Grid xs={12} md={12} item className="hidden">
              <div className="flex justify-start flex-col w-full">
                <div className="flex flex-col mt-3">
                  {financialData.map((data, index) => {
                    return (
                      <div className="flex justify-end gap-2" key={index}>
                        <label
                          htmlFor="financialSupport"
                          className="font-semibold"
                          onClick={() => {
                            handleFinancialSupportChange(index, data.value);
                          }}
                        >
                          {data.english} / {data.arabic}
                        </label>
                        <input
                          type="radio"
                          name="financialSupport"
                          checked={
                            Object.keys(
                              formData.sponsorship.value || { "": "" }
                            )[0] === data.value
                          }
                          onChange={() =>
                            handleFinancialSupportChange(index, data.value)
                          }
                        />
                      </div>
                    );
                  })}
                  {errors.sponser && (
                    <ErrorMessage
                      alignRight="text-right"
                      errorMessage={errors.sponser}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          {financialSupport.toLocaleLowerCase().includes("ministry") && (
            <div className="w-full mt-3">
              <textarea
                placeholder="Describe in brief"
                value={formData.sponsorshipScholarshipNote}
                className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
              />
            </div>
          )}
          {showTextBox && (
            <div className="w-full mt-3">
              <textarea
                placeholder="Describe in brief"
                value={formData.sponsorshipOtherNote}
                className="w-full h-24 resize-none  rounded-lg border-[1px] border-gray-400 outline-none px-3 py-2"
              />
            </div>
          )}
        </CommonAccordion> */}
      </Box>
    </Box>
  );
};

export default AdmissionForm2;
