import React, { useEffect, useState } from "react";
import { GoCheckCircleFill } from "react-icons/go";

const SuccessPage = ({ redirectURL }) => {
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    let interval = setTimeout(() => {
      if (timer > 0) {
        setTimer((timer) => timer - 1);
      } else {
        if (redirectURL) {
          window.location = `${redirectURL}`;
        } else {
          window.location.reload(true);
        }
      }
    }, 1000);

    return () => clearTimeout(interval);
  }, [timer, redirectURL]);

  return (
    <div
      className=" bg-[#e9ecef] flex items-center justify-center min-h-screen"
      style={{
        backgroundColor: "#fff",
        backgroundImage:
          "radial-gradient(rgba(183, 228, 199,1) 10%, transparent 10%)",
        backgroundPosition: "0% 0%",
        backgroundSize: "1rem 1rem"
      }}
    >
      <div className="flex flex-col items-center justify-center bg-white rounded-lg drop-shadow-2xl px-8 md:gap-4 gap-2 py-10">
        <GoCheckCircleFill className="md:text-[160px] text-[120px] text-[#52b69a]" />
        <p className="text-2xl font-bold md:text-[30px] ">
          Form submitted successfully
        </p>
        <span
          className="font-semibold text-[#52b69a]  md:text-lg"
          style={{ fontVariantNumeric: "tabular-nums" }}
        >
          Redirecting to home in <span className="">{timer}</span> seconds
        </span>
      </div>
    </div>
  );
};

export default SuccessPage;
