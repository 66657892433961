import { MdKeyboardArrowDown } from "react-icons/md";
import React, { useEffect, useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import Spinner from "../components/Spinner";
const SubmitPage = ({
  formData,
  handleChange,
  handleSubmit,
  errors,
  setShow
}) => {
  const [showDeclaration, setShowDeclaration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const declaration = `I the undersigned hereby certify that the above information is complete, accurate and correct. I authorize AUMS and related officials to verify all statements contained therein and give the University the absolute discretion to use these documents whenever and wherever it deems fit in connection with its tasks. 
Withholding information, misrepresentation, or forgery of the presented documents or information renders me subject to immediate dismissal from the college and all the applicable legal penalties.
All documents presented by applicants to complete a file for admission become the property of AUMS.
`;
  const arabicDeclaration = `اقر انا الموقع أدناه بأن المعلومات المذكورة في هذا الطلب صحيحة وكاملة. وامنح الجامعة  أو أي جهة رسمية معنية الحق كاملا بالتحقق منها  أو باستعمال المستندات المرفقة بما تراه ملائما. واسمح للجامعة باستخدام المعلومات المقدمة مني لأغراضها التعليمية.
كما امنح الجامعة الحق برفض طلبي أو إلغاء قيدي أو فصلي منها في حال اكتشافهم تزويرأو عدم صحة  لأي معلومة مقدمه مني  أو مستند من المستندات المرفقة ، كما أن للجامعة الحق  في الاحتفاظ بجميع الرسوم المدفوعه في حال حدوث ذلك ، كما اتعهد في حالة قبولي في الجامعة باستكمال وإنهاء  جميع الآجراءات الخاصة  بها ، كما تعتبر المعلومات المقدمة مني ملكا للكلية ولها الحق بالاحتفاظ بجميع المستندات المقدمة مني لها
`;

  const eligible = `You should answer yes if, at any time, you have been found
            responsible for any disciplinary violation at your school whether
            related to academic or behavior misconduct that resulted in your
            probation, suspension, removal, dismissal, or expulsion from your
            institution. If yes, please attach an explanation.`;

  const handleLocalChange = (selectedIndex) => {
    handleChange(
      { target: { value: formData.relatives.value[selectedIndex].value } },
      "relatives",
      "selected"
    );
  };

  const handleReadMore = () => {
    setShowDeclaration((prev) => !prev);
  };

  useEffect(() => {
    document.title = "AUMS • Declaration";
  }, []);

  return (
    <div className="w-full min-h-screen bg-[#e9ecef] flex items-center justify-center md:py-8 p-4 select-none">
      <div className="bg-white rounded-xl md:w-[600px] p-4">
        <div className="bg-[#f8f9fa] rounded-lg p-4 mb-4 hidden">
          <p className="font-bold text-[#b70042]">
            Do you have a relative that works in any of the following?
          </p>
          <div className="font-mediumpack">
            {formData.relatives?.value.map((item, index) => {
              return (
                <div className="flex items-center gap-2 py-1" key={item.name}>
                  <input
                    checked={item.value === formData.relatives.selected.value}
                    type="checkbox"
                    name={`${item.name}`}
                    id={`${item.name}`}
                    onChange={() => handleLocalChange(index)}
                  />
                  <label htmlFor={`${item.name}`} className="font-medium">
                    {item.name}
                  </label>
                </div>
              );
            })}
            {errors.relatives && (
              <ErrorMessage errorMessage={errors.relatives} />
            )}
          </div>
        </div>
        <div className="bg-[#f8f9fa] rounded-lg p-4 mb-4">
          <p className="font-bold text-[#b70042]">
            Are you eligible to return to every institution you have previously
            attended?
          </p>
          <div className="flex gap-3">
            <div className="flex items-center gap-1 py-1">
              <input
                type="checkbox"
                name="yes"
                id="yes"
                checked={formData.eligible.value === true}
                onChange={(e) =>
                  handleChange({ target: { value: true } }, "eligible")
                }
              />
              <label htmlFor="yes" className="font-medium">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-1 py-1">
              <input
                type="checkbox"
                name="no"
                id="no"
                checked={formData.eligible.value === false}
                onChange={(e) =>
                  handleChange({ target: { value: false } }, "eligible")
                }
              />
              <label htmlFor="no" className="font-medium">
                No
              </label>
            </div>
            {errors.eligible && <ErrorMessage errorMessage={errors.eligible} />}
          </div>
          <p className="font-medium text-zinc-500 hidden">
            {showDeclaration ? (
              <>
                {eligible + " "}
                <ReadMoreBtn
                  handleReadMore={handleReadMore}
                  title=" show less"
                />
              </>
            ) : (
              <>
                {eligible.slice(0, 50) + " "}
                <ReadMoreBtn
                  handleReadMore={handleReadMore}
                  title="view more"
                />
              </>
            )}
          </p>
        </div>
        <div className="bg-[#f8f9fa] rounded-lg p-4 mb-4 hidden">
          <details open>
            <summary className="font-bold text-[#b70042] cursor-pointer">
              NOTE
            </summary>
            <div>
              <ul className="list-disc pl-4 font-medium">
                <li>
                  Non refundable application fee of KD 20 must be submitted.
                </li>
                <li>
                  Applications received without the fee will not be processed or
                  reviewed.
                </li>
                <li>
                  Admission is extremely competitive and is based on the
                  strength of the applicant pool and the available capacity.
                </li>
                <li>
                  Note that fulfilling the requirements and admission conditions
                  does not necessarily mean acceptance into the university
                </li>
              </ul>
            </div>
          </details>
        </div>
        <div className="bg-[#f8f9fa] rounded-lg p-4 mb-4 ">
          <p className="font-medium text-zinc-600">
            <input
              type="checkbox"
              name="declare"
              id="delcare"
              className="mr-1"
              checked={formData.isDeclared.value}
              onChange={(e) =>
                handleChange(
                  { target: { value: !formData.isDeclared.value } },
                  "isDeclared"
                )
              }
            />
            <span className="font-bold text-[#b70042]">Declaration: </span>
            {declaration}
            <br />
            {arabicDeclaration}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <button
            // onClick={handleSubmit}
            type="submit"
            disabled={
              formData.isDeclared.value === null ||
              formData.isDeclared.value === false
                ? true
                : false
            }
            className="bg-[#b70042] disabled:bg-rose-300 text-white font-bold px-10 py-3 rounded-lg w-full hover:bg-rose-700"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const ReadMoreBtn = ({ handleReadMore, title }) => {
  return (
    <button className="text-[#b70042] font-bold" onClick={handleReadMore}>
      {title}
    </button>
  );
};

export default SubmitPage;
