import { countryListOptions, countryListOptionWithBedoon } from "./methods";

export const initialFormData = {
  firstName: { value: "", error: "" },
  middleName: { value: "", error: "" },
  lastName: { value: "", error: "" },
  arabicFirstName: { value: "", error: "" },
  arabicMiddleName: { value: "", error: "" },
  arabicLastName: { value: "", error: "" },
  dob: { value: null, error: "" },
  gender: {
    value: "",
    option: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" }
    ],
    error: ""
  },
  maritalStatus: {
    value: "",
    option: [
      { value: "single", label: "Single" },
      { value: "married", label: "Married" },
      { value: "divorcee", label: "Divorcee" }
    ],
    error: ""
  },
  civilId: { value: "", error: "" },
  civilIdExpiryDate: { value: null, error: "" },
  mobileOne: { value: "", error: "", phoneCode: { value: "" } },
  mobileTwo: { value: "", error: "", phoneCode: { value: "" } },
  homeTelOne: { value: "", error: "", phoneCode: { value: "" } },
  homeTelTwo: { value: "", error: "", phoneCode: { value: "" } },
  emailOne: { value: "", error: "" },
  emailTwo: { value: "", error: "" },
  nationality: { value: "", error: "", option: countryListOptions },
  birthCountry: { value: "", error: "", option: countryListOptions },
  passport: { value: null, error: "" },
  passportExpiry: { value: null, error: "" },
  securityCard: { value: null, error: "" },
  securityCardExpiry: { value: null, error: "" },
  isHealthRecord: { value: null, error: "" },
  healthNote: { value: "", error: "" },
  isSuspended: { value: null, error: "" },
  suspensionNote: { value: "", error: "" },
  sponsorship: {
    value: null,
    error: ""
  },
  sponsorshipOtherNote: {
    value: "",
    error: ""
  },
  sponsorshipScholarshipNote: {
    value: "",
    error: ""
  },

  isDeclared: { value: false, error: "" },
  document: {
    photograph: { value: null, error: "" },
    transcript: { value: null, error: "" },
    englishscore: { value: null, error: "" },
    highschool: { value: null, error: "" },
    civilidFront: { value: null, error: "" },
    civilidBack: { value: null, error: "" },
    securityCardFront: { value: null, error: "" },
    securityCardBack: { value: null, error: "" },
    passport: { value: null, error: "" },
    record: { value: null, error: "" },
    goodconduct: { value: null, error: "" },
    fatherCivilId: { value: null, error: "" },
    motherCivilId: { value: null, error: "" }
  },
  isAcademic: { value: null },
  archivements: { value: [{ value: "", error: "" }] },
  address: {
    home: { value: "", error: "" },
    block: { value: null, error: "" },
    appartment: { value: null, error: "" },
    street: { value: "", error: "" },
    area: { value: "", error: "" },
    jaddah: {
      value: null,
      option: [
        { value: true, label: "True" },
        { value: false, label: "False" }
      ],
      error: ""
    },
    countryWithBedoon: {
      value: "",
      error: "",
      option: countryListOptionWithBedoon
    },
    country: { value: "", error: "", option: countryListOptions },
    po: { value: null, error: "" },
    zipCode: { value: null, error: "" },
    governorate: {
      value: "",
      option: [
        { value: "Ahmadi Governorate", label: "Ahmadi Governorate" },
        { value: "Al-Asimah Governorate", label: "Al-Asimah Governorate" },
        { value: "Farwaniya Governorate", label: "Farwaniya Governorate" },
        { value: "Hawalli Governorate", label: "Hawalli Governorate" },
        { value: "Jahra Governorate", label: "Jahra Governorate" },
        {
          value: "Mubarak Al-Kabeer Governorate",
          label: "Mubarak Al-Kabeer Governorate"
        }
      ],
      error: ""
    }
  },
  family: {
    fatherName: { value: "", error: "" },
    arabicFatherName: { value: "", error: "" },
    fatherDob: { value: null, error: "" },
    fatherNationality: { value: "", error: "", option: countryListOptions },
    fatherCivilId: { value: null, error: "" },
    fatherOccup: { value: "", error: "" },
    fatherEmployer: { value: "", error: "" },
    fatherDepartment: { value: "", error: "" },
    fatherMobile: { value: null, error: "", phoneCode: { value: "" } },
    fatherWorkTel: { value: null, error: "", phoneCode: { value: "" } },
    fatherEmail: { value: "", error: "" },
    motherName: { value: "", error: "" },
    arabicMotherName: { value: "", error: "" },
    motherDob: { value: null, error: "" },
    motherNationality: { value: "", error: "", option: countryListOptions },
    motherCivilId: { value: null, error: "" },
    motherOccup: { value: "", error: "" },
    motherEmployer: { value: "", error: "" },
    motherDepartment: { value: "", error: "" },
    motherMobile: { value: null, error: "", phoneCode: { value: "" } },
    motherWorkTel: { value: null, error: "", phoneCode: { value: "" } },
    motherEmail: { value: "", error: "" }
  },
  emergency: [
    {
      name: { value: "", error: "" },
      contact: { value: "", error: "", phoneCode: { value: "" } },
      relationship: { value: "", error: "" },
      civilId: { value: "", error: "" }
    },
    {
      personTwo: { value: false, error: "" },
      name: { value: "", error: "" },
      contact: { value: "", error: "", phoneCode: { value: "" } },
      relationship: { value: "", error: "" },
      civilId: { value: "", error: "" }
    }
  ],

  school: {
    value: [
      {
        name: { value: "", error: "" },
        type: { value: "", error: "" },
        gpa: { value: "", error: "" },
        date: { value: "", error: "" },
        degree: { value: "", error: "" },
        physics: { value: "", error: "" },
        chemistry: { value: "", error: "" },
        biology: { value: "", error: "" },
        average: { value: "", error: "" }
      }
    ]
  },
  scientificMarks: {
    value: [
      {
        physics: { value: "", error: "" },
        chemistry: { value: "", error: "" },
        biology: { value: "", error: "" },
        average: { value: "", error: "" }
      }
    ]
  },
  isCollege: { value: null, error: "" },
  college: {
    value: [
      {
        name: { value: "", error: "" },
        type: { value: "", error: "" },
        country: { value: "", error: "", option: countryListOptions },
        gpa: { value: null, error: "" },
        date: { value: null, error: "" },
        major: { value: "", error: "" },
        degree: { value: "", error: "" }
      }
    ]
  },
  languages: [
    {
      language: { value: "English", error: "" },
      read: { value: "", error: "" },
      write: { value: "", error: "" },
      speak: { value: "", error: "" }
    },
    {
      language: { value: "Arabic", error: "" },
      read: { value: "", error: "" },
      write: { value: "", error: "" },
      speak: { value: "", error: "" }
    },
    {
      language: { value: "", error: "" },
      read: { value: "", error: "" },
      write: { value: "", error: "" },
      speak: { value: "", error: "" }
    }
  ],
  test: {
    testScore: { value: "", error: "" },
    testDate: { value: null, error: "" },
    testType: {
      value: "",
      error: "",
      option: [
        // { value: "NA", label: "NA" },
        { value: "ielts", label: "IELTS" },
        { value: "toefl", label: "TOEFL" },
        { value: "other", label: "OTHER" }
      ]
    }
  },
  testTypeOtherNote: { value: "", error: "" },
  relatives: {
    value: [
      {
        name: "PUC",
        value: "puc"
      },
      {
        name: "MOHE",
        value: "mohe"
      },
      {
        name: "Kuwait University",
        value: "kuwait university"
      },
      {
        name: "None of the above",
        value: "na"
      }
    ],
    selected: { value: "" }
  },
  relativesNote: { value: "", error: "" },
  eligible: { value: null, error: "" }
};
