// import { initialFormData } from "./formData";

import { getStudentAge } from "./methods";

export const validateFormTwo = (formData, nationality, age, setErrors) => {
  const newErrors = {};
  // if (nationality === "Non-Kuwaiti") {
  //   console.log("inside validation form two");
  //   if (!formData.securityCard.value) {
  //     newErrors.securityCard = "Security card is required";
  //   }
  //   if (!formData.securityCardExpiry.value) {
  //     newErrors.securityCardExpiry = "Security card expiry date is required";
  //   }
  // }
  if (nationality !== "Non-Kuwaiti") {
    if (!formData.passport.value) {
      newErrors.passport = "Passport number is required";
    }
    if (!formData.passportExpiry.value) {
      newErrors.passportExpiry = "Passport expiry date is required";
    }
  }

  if (!formData.family.fatherDob.value) {
    newErrors.fatherDob = "Father date of birth is required";
  }

  if (!formData.family.fatherNationality.value) {
    newErrors.fatherNationality = "Father nationality is required";
  }

  if (!formData.family.fatherCivilId.value) {
    newErrors.fatherCivilId = "Father Civil ID is required";
  }

  // if (!formData.family.fatherWorkTel.value) {
  //   newErrors.fatherWorkTel = "Father work telephone number is required";
  // }

  // if (!formData.family.fatherEmail.value) {
  //   newErrors.fatherEmail = "Father email is required";
  // }

  if (!formData.family.motherDob.value) {
    newErrors.motherDob = "Mother date of birth is required";
  }

  if (!formData.family.motherNationality.value) {
    newErrors.motherNationality = "Mother nationality is required";
  }

  if (!formData.family.motherCivilId.value) {
    newErrors.motherCivilId = "Mother Civil ID is required";
  }
  // if (!formData.family.motherWorkTel.value) {
  //   newErrors.motherWorkTel = "Mother work telephone number is required";
  // }

  // if (!formData.family.motherEmail.value) {
  //   newErrors.motherEmail = "Mother email is required";
  // }

  // if (formData.sponsorship.value === null) {
  //   newErrors.sponser = "Please select any one of these";
  // }

  const languages = [];
  formData.languages.map((language, index) => {
    languages.push({});
    if (language.language.value !== "") {
      if (!language.read.value) {
        languages[index].languageRead = "Please select any one of these";
      }

      if (!language.write.value) {
        languages[index].languageWrite = "Please select any one of these";
      }
      if (!language.speak.value) {
        languages[index].languageSpeak = "Please select any one of these";
      }
    }
  });
  languages.map((data, index) => {
    if (Object.keys(languages[index]) != 0) {
      newErrors.languages = languages;
    }
  });

  const emergencies = [];
  emergencies.push({});
  if (!formData.emergency[0].name.value) {
    emergencies[0].emergencyName = "Emergency person name is required";
  }

  if (!formData.emergency[0].relationship.value) {
    emergencies[0].emergencyRelationship =
      "Emergency person relationship is required";
  }

  if (!formData.emergency[0].civilId.value) {
    emergencies[0].emergencyCivilId = "Emergency person Civil ID is required";
  }

  if (!formData.emergency[0].contact.value) {
    emergencies[0].emergencyContact = "Emergency person contact is required";
  }
  if (Object.keys(emergencies[0]) != 0) {
    newErrors.emergencies = emergencies;
  }

  if (formData.emergency[1].personTwo.value === true) {
    emergencies.push({});
    if (!formData.emergency[1].name.value) {
      emergencies[1].emergencyName = "Emergency person name is required";
    }

    if (!formData.emergency[1].relationship.value) {
      emergencies[1].emergencyRelationship =
        "Emergency person relationship is required";
    }

    if (!formData.emergency[1].civilId.value) {
      emergencies[1].emergencyCivilId = "Emergency person Civil ID is required";
    }

    if (!formData.emergency[1].contact.value) {
      emergencies[1].emergencyContact = "Emergency person contact is required";
    }
    if (Object.keys(emergencies[1]) != 0) {
      newErrors.emergencies = emergencies;
    }
  }

  if (!formData.isDeclared.value) {
    newErrors.isDeclared = "Please confirm";
  }
  if (!formData.document.photograph.value) {
    newErrors.photograph = "Photograph is required";
  }

  if (!formData.document.transcript.value) {
    newErrors.transcript = "Transcript is required";
  }

  if (!formData.document.englishscore.value) {
    newErrors.englishscore = "IELTS is required";
  }

  if (!formData.document.highschool.value) {
    newErrors.highschool = "High school certificate is required";
  }

  if (!formData.document.passport.value) {
    newErrors.passportCopy = "Passport copy is required";
  }
  if (getStudentAge(age) > 18) {
    if (!formData.document.record.value) {
      newErrors.record = "Valid criminial record is required";
    }
  }

  if (!formData.document.goodconduct.value) {
    newErrors.goodconduct = "Good conduct certificate is required";
  }

  if (!formData.document.fatherCivilId.value) {
    newErrors.fatherCivilIdDoc = "Father Civil ID copy is required";
  }

  if (!formData.document.motherCivilId.value) {
    newErrors.motherCivilIdDoc = "Mother Civil ID copy is required";
  }
  console.log(newErrors);
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};
