import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Download from "../components/Download";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "../components/ErrorMessage";
import ErrorTitle from "../components/ErrorTitle";

const CivilIdDocument = ({
  formData,
  handleChange,
  showError,
  errors,
  documentError,
  documentErrorMsg
}) => {
  useEffect(() => {
    document.title = "AUMS • Document";
  }, []);
  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          backgroundColor: "#B70042",
          padding: "1rem",
          fontWeight: "bold"
        }}
      >
        <h4 className="form">Documents</h4>
      </Box>

      <Box sx={{ padding: "0.5rem", color: "#606060" }}>
        <Box>
          <h4 className="my-3 font-bold text-center">
            {formLabel.document.title.english}
          </h4>
          <div className="px-4">{showError && <ErrorTitle />}</div>
          <div className="px-4">
            {documentError && <ErrorMessage errorMessage={documentErrorMsg} />}
          </div>
        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead className="w-full ">
              <TableRow>
                <TableCell sx={{ fontWeight: "700" }} align="left">
                  <span className="text-lg">
                    Copy of the Valid Kuwait Civil ID card
                  </span>
                  <br />
                  1. FRONT COPY
                  {errors.civilIdFront && (
                    <ErrorMessage errorMessage={errors.civilIdFront} />
                  )}
                  {!errors.civilIdFront && <br />}
                  2. BACK COPY
                  <br />
                  {errors.civilIdBack && (
                    <ErrorMessage errorMessage={errors.civilIdBack} />
                  )}
                </TableCell>
                <TableCell className="w-[300px]">
                  <div
                    style={{
                      display: "flex",
                      gap: "0.3rem",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Download
                      formData={formData}
                      handleChange={handleChange}
                      value={"civilidFront"}
                    />
                    <Download
                      formData={formData}
                      handleChange={handleChange}
                      value={"civilidBack"}
                    />
                  </div>
                </TableCell>
              </TableRow>
              {formData.nationality.value === "Non-Kuwaiti" && (
                <TableRow>
                  <TableCell sx={{ fontWeight: "700" }} align="left">
                    {formData.nationality.value === "Non-Kuwaiti" ? (
                      <>
                        <span className="text-lg">
                          Copy of the Security card
                        </span>
                        <br />
                        1. FRONT COPY
                        {errors.securityCardFront && (
                          <ErrorMessage
                            errorMessage={errors.securityCardFront}
                          />
                        )}
                        {!errors.securityCardFront && <br />}
                        2. BACK COPY
                        <br />
                        {errors.securityCardBack && (
                          <ErrorMessage
                            errorMessage={errors.securityCardBack}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <span className="text-lg">
                          Copy of the Valid Kuwait Civil ID card
                        </span>
                        <br />
                        1. FRONT COPY
                        {errors.civilIdFront && (
                          <ErrorMessage errorMessage={errors.civilIdFront} />
                        )}
                        {!errors.civilIdFront && <br />}
                        2. BACK COPY
                        <br />
                        {errors.civilIdBack && (
                          <ErrorMessage errorMessage={errors.civilIdBack} />
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell className="w-[300px]">
                    <div
                      style={{
                        display: "flex",
                        gap: "0.3rem",
                        width: "100%",
                        flexDirection: "column",
                        justifyContent: "flex-end"
                      }}
                    >
                      {formData.nationality.value === "Non-Kuwaiti" ? (
                        <>
                          <Download
                            formData={formData}
                            handleChange={handleChange}
                            value={"securityCardFront"}
                          />
                          <Download
                            formData={formData}
                            handleChange={handleChange}
                            value={"securityCardBack"}
                          />
                        </>
                      ) : (
                        <>
                          <Download
                            formData={formData}
                            handleChange={handleChange}
                            value={"civilidFront"}
                          />
                          <Download
                            formData={formData}
                            handleChange={handleChange}
                            value={"civilidBack"}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CivilIdDocument;
