import React, { useEffect, useState } from "react";
import { PiUserCircleDuotone } from "react-icons/pi";
import { RiErrorWarningLine } from "react-icons/ri";
import { maxLength } from "../utils/methods";

import Spinner from "../components/Spinner";
import { BASE_URL } from "../utils/config";
const CivilNumberVerification = ({ civilId, handleChange, stepHandler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState("");

  const handleVerify = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${BASE_URL}/student/admission/verify/${civilId.value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const json = await response.json();

      if (json) {
        setIsLoading(false);
        if (json.data?.id) {
          setIsVerified("Application form is already submitted ");
        } else {
          setIsLoading(false);
          setIsVerified("");
          stepHandler();
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsVerified("");
    }
  };

  useEffect(() => {
    document.title = "AUMS • Civil ID verification";
  }, []);

  return (
    <>
      <div
        // style={{
        //   background:
        //     "linear-gradient(to bottom, rgba(233, 174, 50,1) 0%, rgba(0,0,0,0) 49%, rgba(21, 101, 192,0.90) 100%), url('/form.png')",
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        //   backgroundAttachment: "fixed",
        //   minHeight: "100vh"
        // }}
        className="w-screen h-screen bg-gradient-to-r from-[#ef233c] to-[#B70042] bg-[#B70042]  flex items-center justify-center "
      >
        <div className="w-[360px] md:w-[600px] bg-[#edf2f4] rounded-2xl shadow-xl md:px-10 px-4 flex flex-col items-start justify-center gap-4 py-6">
          <div className="flex w-full items-center justify-center flex-col">
            <PiUserCircleDuotone className="text-[100px] text-[#B70042]" />
            <h1 className="md:text-3xl text-xl font-bold font-sans">
              Civil ID verification
            </h1>
            <p className="font-semibold md:text-lg text-sm text-[#5d6677]">
              Enter your civil ID number for verification
            </p>
          </div>
          <div className="flex items-center gap-2 w-full">
            <input
              value={civilId.value}
              onChange={(e) => {
                if (maxLength(e, 12)) return;
                handleChange(e, "civilId");
                setIsVerified("");
              }}
              autoFocus
              type="number"
              placeholder="000000000000"
              className="md:px-6 py-3 px-4 flex-1  rounded-xl outline-none"
            />
            <button
              disabled={civilId.value?.length !== 12 || isLoading}
              onClick={handleVerify}
              className="px-4 py-3 rounded-xl bg-[#2b2d42] text-white font-bold disabled:bg-gray-300 disabled:text-gray-600 hover:bg-[#8d99ae] hover:text-[#2b2d42]"
            >
              Verify
            </button>
          </div>
          {isVerified && (
            <div className="font-bold w-full rounded-xl p-4 bg-[#ffccd5] text-[#B70042]">
              <p className="flex items-center gap-1">
                <RiErrorWarningLine className="text-[15.5px] md:text-xl" />
                <span className="text-[12.5px] font-bold md:text-[15px]">
                  {isVerified}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Spinner />}
    </>
  );
};

export default CivilNumberVerification;
