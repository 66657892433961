import { countries } from "./country";
import Flag from "react-world-flags";
import { GoDotFill } from "react-icons/go";

export const customBorderBottom = (args) => {
  if (args === 0) {
    return "borderBottomImp";
  }
  return "";
};

export const countryListOptionWithBedoon =
  Object.keys(countries)?.map((countryCode, index) => {
    return {
      value: countries[countryCode],
      label: (
        <>
          <span className={`flex items-center w-full gap-2 `}>
            {countries[countryCode] === "Non-Kuwaiti" ? (
              <GoDotFill />
            ) : (
              <Flag
                code={countryCode}
                style={{
                  height: "15px",
                  width: "19px"
                }}
              />
            )}
            {countries[countryCode]}
          </span>
        </>
      )
    };
  }) || [];

export const countryListOptions = Object.entries(countries)
  .slice(1) // Skip the first two items
  .reduce((options, [countryCode, countryName], index) => {
    options.push({
      value: countries[countryCode],
      label: (
        <>
          <span className={`flex items-center w-full gap-2 `}>
            <Flag
              code={countryCode}
              style={{
                height: "15px",
                width: "19px"
              }}
            />
            {countryName}
          </span>
        </>
      )
    });
    return options;
  }, []);

export const maxLength = (e, length) => {
  if (e.target.value.length > length) {
    return true;
  } else {
    return false;
  }
};

export const maxScoreOrGpa = (e) => {
  const value = e.target.value;

  const gpaPattern = /^\d{1,3}(\.\d{1,2})?$/;

  if (!gpaPattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isEnglish = (e) => {
  if (e.target.value.match(/^[a-zA-Z ]*$/)) {
    return true;
  } else {
    return false;
  }
};

export const isArabic = (e) => {
  if (e.target.value.match(/^[\u0600-\u06FF ]*$/)) {
    return true;
  } else {
    return false;
  }
};

export const isNotArabic = (e) => {
  if (!e.target.value.match(/[\u0600-\u06FF]/)) {
    return true;
  } else {
    return false;
  }
};

export const isValidEmail = (email) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

export const getStudentAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
