import { Box } from "@mui/material";
import { GoCheckCircleFill } from "react-icons/go";
import { MdFileUploadOff } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

const Download = ({
  handleChange,
  value,
  setIsImageError = () => {},
  resetDocumentState = false
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [sizeError, setSizeError] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (value === "photograph" && selectedFile.type === "application/pdf") {
      setIsImageError("Please upload image");
      return;
    } else {
      setIsImageError("");
    }
    console.log(selectedFile, value);

    let compressedImage;
    if (
      selectedFile.size / 1000 > 1024 &&
      selectedFile.type !== "application/pdf"
    ) {
      compressedImage = await resizeFile(selectedFile);
      console.log("COMPRESS", compressedImage);
    }

    if (
      selectedFile.type === "application/pdf" &&
      selectedFile.size / 1000 > 5120
    ) {
      setSizeError(true);
      return;
    }

    if (!selectedFile) {
      console.log("No file is selected");
    }

    const updatedFormData = {
      target: {
        value: compressedImage || selectedFile
      }
    };

    handleChange(updatedFormData, "document", value);
    setSelectedFile(updatedFormData);
  };

  useEffect(() => {
    if (selectedFile) setSizeError(false);
  }, [selectedFile]);

  useEffect(() => {
    if (resetDocumentState) {
      setSelectedFile(null);
      setSizeError(false);
    }
  }, [resetDocumentState]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "0.5rem"
      }}
    >
      <div className="flex items-center justify-center gap-[.5rem]">
        <div
          className={`${
            !selectedFile && "bg-slate-200"
          } rounded-lg flex items-center justify-center w-[80px] h-[66px]`}
        >
          {!selectedFile ? (
            <MdFileUploadOff className="text-[50px] text-slate-500" />
          ) : (
            <GoCheckCircleFill className="text-[50px] text-[#17bebb]" />
          )}
        </div>
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png,application/pdf"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <button
          type="button"
          disabled={selectedFile}
          onClick={handleButtonClick}
          className={`${
            !selectedFile
              ? "bg-[#b70042] hover:bg-rose-700"
              : "bg-slate-500 opacity-55 hidden"
          }  h-full px-4 lg:py-4 py-2 text-white font-bold rounded-lg `}
        >
          Upload Document
        </button>
      </div>
      {sizeError ? (
        <p className="bg-rose-200 w-fit-content px-2 rounded-lg text-left py-1 font-bold text-rose-500 my-2 mb-2 ">
          * The PDF file size exceeds 5MB.
        </p>
      ) : null}
    </Box>
  );
};

export default Download;
