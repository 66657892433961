import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../utils/config";
import { Box } from "@mui/material";
import Download from "../components/Download";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "../components/ErrorMessage";
import ErrorTitle from "../components/ErrorTitle";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { initialFormData } from "../utils/formData";
import Paper from "@mui/material/Paper";
import { validateDocument } from "../utils/validateForm";
import AdmissionFormStatus from "./AdmissionFormStatus";
import { GoXCircleFill, GoCheckCircleFill } from "react-icons/go";
import { MdBlock } from "react-icons/md";
import SuccessPage from "./SuccessPage";
import Spinner from "../components/Spinner";
import { toast, ToastContainer } from "react-toastify";

const CompleteDocumentPage = ({ isNull, id }) => {
  const [formTwoData, setFormTwoData] = useState(initialFormData);
  const [isImageError, setIsImageError] = useState("");
  const [resetDocumentState, setResetDocumentState] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formNotFound, setFormNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [documentErrorMsg, setDocumentErrorMsg] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [errors, setErrors] = useState({});
  const btnRef = useRef(null);

  const getStudent = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/student/admission/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await response.json();
      console.log(json);
      if (json.status) {
        setFormStatus(json.data.student.admissionStatus);
        setFormData(json.data);

        setFormNotFound(false);
      } else {
        setDocumentError(true);
        setDocumentErrorMsg(json.message);
        setFormNotFound(true);
      }
    } catch (error) {
      setDocumentError(true);
    } finally {
      setIsLoading(false);
    }
  };
  console.log(isNull);

  useEffect(() => {
    getStudent();
  }, []);

  useEffect(() => {
    if (resetDocumentState) {
      setResetDocumentState(false); // Reset the state flag
    }
  }, [resetDocumentState]);

  const handleChange = (e, firstKey, secondKey, thirdKey, forthKey) => {
    if (e.persist) e.persist();

    setFormTwoData((currentData) => {
      const value = e.target.value;
      const data = { ...currentData };
      let updateData = data;
      if (firstKey) {
        updateData = updateData[firstKey];
      }

      if (secondKey) {
        updateData = updateData[secondKey];
      }

      if (thirdKey) {
        updateData = updateData[thirdKey];
      }
      if (forthKey) {
        updateData = updateData[forthKey];
      }
      updateData.value = value;

      return data;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btnRef.current.disabled = true;
    console.log(btnRef);
    const isValid = validateDocument(formTwoData, setErrors);

    if (isValid) {
      const docFile = new FormData();
      if (formData.nationality === "Non-Kuwaiti") {
        docFile.append(
          "securityFront",
          formTwoData.document.securityCardFront.value
        );
        docFile.append(
          "securityBack",
          formTwoData.document.securityCardBack.value
        );
        docFile.append("civilidFront", formTwoData.document.civilidFront.value);
        docFile.append("civilidBack", formTwoData.document.civilidBack.value);
        docFile.append("photograph", formTwoData.document.photograph.value);
        docFile.append("transcript", formTwoData.document.transcript.value);
        docFile.append("englishscore", formTwoData.document.englishscore.value);
        docFile.append("highschool", formTwoData.document.highschool.value);
        docFile.append("passport", formTwoData.document.passport.value);
        docFile.append("record", formTwoData.document.record.value);
        docFile.append("goodconduct", formTwoData.document.goodconduct.value);
        docFile.append(
          "fatherCivilId",
          formTwoData.document.fatherCivilId.value
        );
        docFile.append(
          "motherCivilId",
          formTwoData.document.motherCivilId.value
        );
      } else {
        docFile.append("photograph", formTwoData.document.photograph.value);
        docFile.append("transcript", formTwoData.document.transcript.value);
        docFile.append("englishscore", formTwoData.document.englishscore.value);
        docFile.append("highschool", formTwoData.document.highschool.value);
        docFile.append("passport", formTwoData.document.passport.value);
        docFile.append("record", formTwoData.document.record.value);
        docFile.append("goodconduct", formTwoData.document.goodconduct.value);
        docFile.append(
          "fatherCivilId",
          formTwoData.document.fatherCivilId.value
        );
        docFile.append(
          "motherCivilId",
          formTwoData.document.motherCivilId.value
        );
        docFile.append("civilidFront", formTwoData.document.civilidFront.value);
        docFile.append("civilidBack", formTwoData.document.civilidBack.value);
      }
      docFile.append("id", id);

      try {
        setIsLoading(true);
        const docResponse = await fetch(
          `${BASE_URL}/student/admission/document/`,
          {
            method: "POST",
            body: docFile
          }
        );

        const jsonDoc = await docResponse.json();
        console.log(jsonDoc);

        if (jsonDoc.status) {
          setIsSubmitted(true);
        } else {
          toast.error(jsonDoc.message);

          setDocumentError(true);
          setIsLoading(false);

          setFormTwoData({
            ...formTwoData,
            document: {
              photograph: { value: null },
              transcript: { value: null },
              englishscore: { value: null },
              highschool: { value: null },
              passport: { value: null },
              record: { value: null },
              goodconduct: { value: null },
              fatherCivilId: { value: null },
              motherCivilId: { value: null }
            }
          });

          setResetDocumentState(true);
        }
        if (jsonDoc.status !== true) {
          setDocumentError(true);
          btnRef.current.disabled = false;
          setDocumentErrorMsg(jsonDoc.message);
        }
      } catch (error) {
        setDocumentError(true);
        console.error("Document upload failed:", error);
        setDocumentErrorMsg(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isSubmitted ? (
        <SuccessPage redirectURL={"/"} />
      ) : (
        <>
          {isLoading && <Spinner />}
          <Box sx={{}}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                backgroundColor: "#B70042",
                padding: "1rem",
                fontWeight: "bold"
              }}
            >
              <h4 className="form">Documents</h4>
            </Box>

            <Box sx={{ padding: "0.5rem", color: "#606060" }}>
              <Box>
                <h4 className="my-3 font-bold text-center">
                  {formLabel.document.title.english}
                </h4>
              </Box>

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead className="w-full ">
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <span className="font-bold text-lg">
                          {formLabel.document.photograph.english}
                        </span>
                        <span className="font-bold"> / </span>
                        <span className="font-bold text-lg">
                          {formLabel.document.photograph.arabic}
                        </span>
                        {errors.photograph && (
                          <ErrorMessage errorMessage={errors.photograph} />
                        )}
                        {isImageError !== "" && (
                          <ErrorMessage errorMessage={isImageError} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"photograph"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <span className="font-bold text-lg">
                          {formLabel.document.transcript.english}
                        </span>
                        <span className="font-bold"> / </span>
                        <span className="font-bold text-lg">
                          {formLabel.document.transcript.arabic}
                        </span>
                        {errors.transcript && (
                          <ErrorMessage errorMessage={errors.transcript} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"transcript"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <span className="font-bold text-lg">
                          {formLabel.document.englishscore.english}
                        </span>
                        <span className="font-bold"> </span>
                        <span className="font-bold text-lg">
                          {formLabel.document.englishscore.arabic}
                        </span>
                        {errors.englishscore && (
                          <ErrorMessage errorMessage={errors.englishscore} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"englishscore"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <span className="font-bold text-lg">
                          {formLabel.document.highschool.english}
                        </span>
                        <span className="font-bold"> / </span>
                        <span className="font-bold text-lg">
                          {formLabel.document.highschool.arabic}
                        </span>
                        {errors.highschool && (
                          <ErrorMessage errorMessage={errors.highschool} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"highschool"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <div className="font-bold text-lg">
                          Copy of the Valid Passport Copy.
                        </div>
                        {errors.passportCopy && (
                          <ErrorMessage errorMessage={errors.passportCopy} />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.3rem"
                          }}
                        >
                          <Download
                            value={"passport"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <span className="text-lg">
                          Copy of the Valid Kuwait Civil ID card
                        </span>
                        <br />
                        1. FRONT COPY
                        {errors.civilIdFront && (
                          <ErrorMessage errorMessage={errors.civilIdFront} />
                        )}
                        {!errors.civilIdFront && <br />}
                        2. BACK COPY
                        <br />
                        {errors.civilIdBack && (
                          <ErrorMessage errorMessage={errors.civilIdBack} />
                        )}
                      </TableCell>
                      <TableCell className="w-[300px]">
                        <div
                          style={{
                            display: "flex",
                            gap: "0.3rem",
                            width: "100%",
                            flexDirection: "column",
                            justifyContent: "flex-end"
                          }}
                        >
                          <Download
                            formData={formTwoData}
                            handleChange={handleChange}
                            value={"civilidFront"}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                          <Download
                            formData={formTwoData}
                            handleChange={handleChange}
                            value={"civilidBack"}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {formData.nationality === "Non-Kuwaiti" && (
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          {formData.nationality === "Non-Kuwaiti" ? (
                            <>
                              <span className="text-lg">
                                Copy of the Security card
                              </span>
                              <br />
                              1. FRONT COPY
                              {errors.securityCardFront && (
                                <ErrorMessage
                                  errorMessage={errors.securityCardFront}
                                />
                              )}
                              {!errors.securityCardFront && <br />}
                              2. BACK COPY
                              <br />
                              {errors.securityCardBack && (
                                <ErrorMessage
                                  errorMessage={errors.securityCardBack}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <span className="text-lg">
                                Copy of the Valid Kuwait Civil ID card
                              </span>
                              <br />
                              1. FRONT COPY
                              {errors.civilIdFront && (
                                <ErrorMessage
                                  errorMessage={errors.civilIdFront}
                                />
                              )}
                              {!errors.civilIdFront && <br />}
                              2. BACK COPY
                              <br />
                              {errors.civilIdBack && (
                                <ErrorMessage
                                  errorMessage={errors.civilIdBack}
                                />
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell className="w-[300px]">
                          <div
                            style={{
                              display: "flex",
                              gap: "0.3rem",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-end"
                            }}
                          >
                            {formData.nationality === "Non-Kuwaiti" ? (
                              <>
                                <Download
                                  formData={formTwoData}
                                  handleChange={handleChange}
                                  value={"securityCardFront"}
                                />
                                <Download
                                  formData={formTwoData}
                                  handleChange={handleChange}
                                  value={"securityCardBack"}
                                />
                              </>
                            ) : (
                              <>
                                <Download
                                  formData={formTwoData}
                                  handleChange={handleChange}
                                  value={"civilidFront"}
                                />
                                <Download
                                  formData={formTwoData}
                                  handleChange={handleChange}
                                  value={"civilidBack"}
                                />
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <div className="font-bold text-lg">
                          Applicant of 21 years of age or above and been
                          discontinued from studying for more than one academic
                          year, must submit a recent valid clear criminal record
                          (not exceeding 3 months) upon application.
                        </div>
                        {errors.record && (
                          <ErrorMessage errorMessage={errors.record} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"record"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <div className="font-bold text-lg">
                          Certificate of Good Conduct.
                        </div>
                        {errors.goodconduct && (
                          <ErrorMessage errorMessage={errors.goodconduct} />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"goodconduct"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <div className="font-bold text-lg">Father Civil ID</div>
                        {errors.fatherCivilIdDoc && (
                          <ErrorMessage
                            errorMessage={errors.fatherCivilIdDoc}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"fatherCivilId"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }} align="left">
                        <div className="font-bold text-lg">
                          {" "}
                          Mother Civil ID
                        </div>
                        {errors.motherCivilIdDoc && (
                          <ErrorMessage
                            errorMessage={errors.motherCivilIdDoc}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Download
                          value={"motherCivilId"}
                          formData={formData}
                          handleChange={handleChange}
                          setIsImageError={setIsImageError}
                          resetDocumentState={resetDocumentState}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Box>
            <div className="w-full pb-6">
              <button
                ref={btnRef}
                onClick={handleSubmit}
                className="bg-[#b70042] disabled:bg-rose-300 text-white font-bold px-10 py-3 rounded-lg mx-auto flex items-center my-6 hover:bg-rose-700"
              >
                Submit
              </button>
            </div>
          </Box>
          <ToastContainer />
        </>
      )}

      {/* {formStatus === "approved" && !isNull && formData.document !== null ? (
        <AdmissionFormStatus
          icon={
            <GoCheckCircleFill className="md:text-[160px] text-[120px] text-[#52b69a]" />
          }
          message="Application form is approved"
        />
      ) : formStatus === "rejected" ? (
        <AdmissionFormStatus
          icon={
            <GoXCircleFill className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form one is rejected"
        />
      ) : formNotFound ? (
        <AdmissionFormStatus
          icon={
            <GoXCircleFill className="md:text-[160px] text-[120px] text-[#d93939]" />
          }
          message="Application form not found"
        />
      ) : isSubmitted ? (
        <SuccessPage redirectURL={"/"} />
      ) : (
        (formStatus === "approved" && isNull) ||
        (formData.document === null && (
          <>
            {isLoading && <Spinner />}
            <Box sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  backgroundColor: "#B70042",
                  padding: "1rem",
                  fontWeight: "bold"
                }}
              >
                <h4 className="form">Documents</h4>
              </Box>

              <Box sx={{ padding: "0.5rem", color: "#606060" }}>
                <Box>
                  <h4 className="my-3 font-bold text-center">
                    {formLabel.document.title.english}
                  </h4>
                </Box>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead className="w-full ">
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <span className="font-bold text-lg">
                            {formLabel.document.photograph.english}
                          </span>
                          <span className="font-bold"> / </span>
                          <span className="font-bold text-lg">
                            {formLabel.document.photograph.arabic}
                          </span>
                          {errors.photograph && (
                            <ErrorMessage errorMessage={errors.photograph} />
                          )}
                          {isImageError !== "" && (
                            <ErrorMessage errorMessage={isImageError} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"photograph"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <span className="font-bold text-lg">
                            {formLabel.document.transcript.english}
                          </span>
                          <span className="font-bold"> / </span>
                          <span className="font-bold text-lg">
                            {formLabel.document.transcript.arabic}
                          </span>
                          {errors.transcript && (
                            <ErrorMessage errorMessage={errors.transcript} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"transcript"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <span className="font-bold text-lg">
                            {formLabel.document.englishscore.english}
                          </span>
                          <span className="font-bold"> </span>
                          <span className="font-bold text-lg">
                            {formLabel.document.englishscore.arabic}
                          </span>
                          {errors.englishscore && (
                            <ErrorMessage errorMessage={errors.englishscore} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"englishscore"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <span className="font-bold text-lg">
                            {formLabel.document.highschool.english}
                          </span>
                          <span className="font-bold"> / </span>
                          <span className="font-bold text-lg">
                            {formLabel.document.highschool.arabic}
                          </span>
                          {errors.highschool && (
                            <ErrorMessage errorMessage={errors.highschool} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"highschool"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <div className="font-bold text-lg">
                            Copy of the Valid Passport Copy.
                          </div>
                          {errors.passportCopy && (
                            <ErrorMessage errorMessage={errors.passportCopy} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.3rem"
                            }}
                          >
                            <Download
                              value={"passport"}
                              formData={formData}
                              handleChange={handleChange}
                              setIsImageError={setIsImageError}
                              resetDocumentState={resetDocumentState}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <span className="text-lg">
                            Copy of the Valid Kuwait Civil ID card
                          </span>
                          <br />
                          1. FRONT COPY
                          {errors.civilIdFront && (
                            <ErrorMessage errorMessage={errors.civilIdFront} />
                          )}
                          {!errors.civilIdFront && <br />}
                          2. BACK COPY
                          <br />
                          {errors.civilIdBack && (
                            <ErrorMessage errorMessage={errors.civilIdBack} />
                          )}
                        </TableCell>
                        <TableCell className="w-[300px]">
                          <div
                            style={{
                              display: "flex",
                              gap: "0.3rem",
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-end"
                            }}
                          >
                            <Download
                              formData={formTwoData}
                              handleChange={handleChange}
                              value={"civilidFront"}
                              setIsImageError={setIsImageError}
                              resetDocumentState={resetDocumentState}
                            />
                            <Download
                              formData={formTwoData}
                              handleChange={handleChange}
                              value={"civilidBack"}
                              setIsImageError={setIsImageError}
                              resetDocumentState={resetDocumentState}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      {formData.nationality === "Non-Kuwaiti" && (
                        <TableRow>
                          <TableCell sx={{ fontWeight: "700" }} align="left">
                            {formData.nationality === "Non-Kuwaiti" ? (
                              <>
                                <span className="text-lg">
                                  Copy of the Security card
                                </span>
                                <br />
                                1. FRONT COPY
                                {errors.securityCardFront && (
                                  <ErrorMessage
                                    errorMessage={errors.securityCardFront}
                                  />
                                )}
                                {!errors.securityCardFront && <br />}
                                2. BACK COPY
                                <br />
                                {errors.securityCardBack && (
                                  <ErrorMessage
                                    errorMessage={errors.securityCardBack}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <span className="text-lg">
                                  Copy of the Valid Kuwait Civil ID card
                                </span>
                                <br />
                                1. FRONT COPY
                                {errors.civilIdFront && (
                                  <ErrorMessage
                                    errorMessage={errors.civilIdFront}
                                  />
                                )}
                                {!errors.civilIdFront && <br />}
                                2. BACK COPY
                                <br />
                                {errors.civilIdBack && (
                                  <ErrorMessage
                                    errorMessage={errors.civilIdBack}
                                  />
                                )}
                              </>
                            )}
                          </TableCell>
                          <TableCell className="w-[300px]">
                            <div
                              style={{
                                display: "flex",
                                gap: "0.3rem",
                                width: "100%",
                                flexDirection: "column",
                                justifyContent: "flex-end"
                              }}
                            >
                              {formData.nationality === "Non-Kuwaiti" ? (
                                <>
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"securityCardFront"}
                                  />
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"securityCardBack"}
                                  />
                                </>
                              ) : (
                                <>
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"civilidFront"}
                                  />
                                  <Download
                                    formData={formTwoData}
                                    handleChange={handleChange}
                                    value={"civilidBack"}
                                  />
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <div className="font-bold text-lg">
                            Applicant of 21 years of age or above and been
                            discontinued from studying for more than one
                            academic year, must submit a recent valid clear
                            criminal record (not exceeding 3 months) upon
                            application.
                          </div>
                          {errors.record && (
                            <ErrorMessage errorMessage={errors.record} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"record"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <div className="font-bold text-lg">
                            Certificate of Good Conduct.
                          </div>
                          {errors.goodconduct && (
                            <ErrorMessage errorMessage={errors.goodconduct} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"goodconduct"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <div className="font-bold text-lg">
                            Father Civil ID
                          </div>
                          {errors.fatherCivilIdDoc && (
                            <ErrorMessage
                              errorMessage={errors.fatherCivilIdDoc}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"fatherCivilId"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "700" }} align="left">
                          <div className="font-bold text-lg">
                            {" "}
                            Mother Civil ID
                          </div>
                          {errors.motherCivilIdDoc && (
                            <ErrorMessage
                              errorMessage={errors.motherCivilIdDoc}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Download
                            value={"motherCivilId"}
                            formData={formData}
                            handleChange={handleChange}
                            setIsImageError={setIsImageError}
                            resetDocumentState={resetDocumentState}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>
              <div className="w-full pb-6">
                <button
                  ref={btnRef}
                  onClick={handleSubmit}
                  className="bg-[#b70042] disabled:bg-rose-300 text-white font-bold px-10 py-3 rounded-lg mx-auto flex items-center my-6 hover:bg-rose-700"
                >
                  Submit
                </button>
              </div>
            </Box>
            <ToastContainer />
          </>
        ))
      )} */}
    </>
  );
};

export default CompleteDocumentPage;
