import { Box, Checkbox, FormControlLabel, styled } from "@mui/material";
import React, { useState } from "react";
import "./AdmissionForm.css";
import { IoPersonCircle } from "react-icons/io5";
import { Grid } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";

import CommonAccordion from "./CommonAccordion";
import Input from "./Input";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Data } from "./Details";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-phone-input-2/lib/style.css";
import "./phoneInput.css";
import PhoneInput from "react-phone-input-2";
import FormFieldLabel from "./FormFieldLabel";
import { formLabel } from "../utils/formFieldLabels";
import ErrorMessage from "./ErrorMessage";
import ErrorTitle from "./ErrorTitle";

const Form = ({ formData, handleChange, showError, errors }) => {
  const isChecked = formData.emergency[1].personTwo.value;

  const CustomCheckboxYes = styled(Checkbox)(({ theme }) => ({
    color: "#B70042", // Set the color of the checkbox itself
    "&.Mui-checked": {
      color: "blue" // Change color when checked
    }
  }));
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData("Myself", "", <Input />),
    createData("Ministry", "", <Input />),
    createData("Others", "", <Input />)
  ];
  return (
    <Box sx={{ gap: "10rem" }}>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "space-between",
          backgroundColor: "#B70042",
          color: "white",
          width: "100%",
          padding: "1rem"
        }}
      >
        <span>{formLabel.headings.emergencyPageTitle.english}</span>
        <span>{formLabel.headings.emergencyPageTitle.arabic}</span>
      </Box>
      <div className="px-4">{showError && <ErrorTitle />}</div>
      <Box>
        <CommonAccordion
          defaultExpanded={true}
          englishLabel={formLabel.headings.emergencyPageTitle.english}
          arabicLabel={formLabel.headings.emergencyPageTitle.arabic}
        >
          <Grid
            container
            spacing={1}
            className="border-[1px] p-3 rounded-md"
            style={{ marginTop: ".1rem" }}
          >
            <p className="flex gap-1 items-center my-3 font-bold uppercase ml-2">
              <IoPersonCircle className="text-2xl text-[#B70042] " />
              Person 1
            </p>
            <Grid item container spacing={4}>
              <Grid xs={12} md={6} item>
                <Input
                  englishLabel={formLabel.emergency.name.english}
                  arabicLabel={formLabel.emergency.name.arabic}
                  value={formData.emergency[0].name.value}
                  handleChange={(e) =>
                    handleChange(e, "emergency", "0", "name")
                  }
                />
                {errors?.emergencies?.[0]?.emergencyName && (
                  <ErrorMessage
                    errorMessage={errors?.emergencies?.[0]?.emergencyName}
                  />
                )}
              </Grid>
              <Grid xs={12} md={6} item>
                <Input
                  englishLabel={formLabel.emergency.relationship.english}
                  arabicLabel={formLabel.emergency.relationship.arabic}
                  value={formData.emergency[0].relationship.value}
                  handleChange={(e) =>
                    handleChange(e, "emergency", "0", "relationship")
                  }
                />
                {errors?.emergencies?.[0]?.emergencyRelationship && (
                  <ErrorMessage
                    errorMessage={
                      errors?.emergencies?.[0]?.emergencyRelationship
                    }
                  />
                )}
              </Grid>
              <Grid xs={12} md={6} item>
                <Input
                  type={"number"}
                  englishLabel={formLabel.emergency.civilId.english}
                  arabicLabel={formLabel.emergency.civilId.arabic}
                  value={formData.emergency[0].civilId.value}
                  handleChange={(e) =>
                    handleChange(e, "emergency", "0", "civilId")
                  }
                />
                {errors?.emergencies?.[0]?.emergencyCivilId && (
                  <ErrorMessage
                    errorMessage={errors?.emergencies?.[0]?.emergencyCivilId}
                  />
                )}
              </Grid>
              <Grid xs={12} md={6} item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "0.3rem"
                  }}
                >
                  <FormFieldLabel
                    englishLabel={formLabel.emergency.contact.english}
                    arabicLabel={formLabel.emergency.contact.arabic}
                  />

                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      border: "1px solid lightgray",
                      borderRadius: "8px",
                      background: "#F6F2F8",
                      height: "2.6rem"
                      /* Add any additional styles you need */
                    }}
                    country={"kw"}
                    value={formData.emergency[0].contact.value}
                    // onlyCountries={['in', 'de', 'ru']}
                    onChange={(value, countryData) => {
                      const countryCodeNumber = countryData?.dialCode;
                      handleChange(
                        { target: { value } },
                        "emergency",
                        "0",
                        "contact"
                      );
                      handleChange(
                        { target: { value: countryCodeNumber } },
                        "emergency",
                        "0",
                        "contact",
                        "phoneCode"
                      );
                    }}
                  />
                  {errors?.emergencies?.[0]?.emergencyContact && (
                    <ErrorMessage
                      errorMessage={errors?.emergencies?.[0]?.emergencyContact}
                    />
                  )}
                </div>
                {/* <Input endornment={<EmailOutlinedIcon/>} label={item.label} /> */}
                {/* <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                               <label className='label'>{item.label}</label>
                                               <input style={{ backgroundColor: "lightgray", border: "none", borderRadius: '8px', height: "2rem" }} />
                                           </div> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            className="border-[1px] p-3 rounded-md"
            style={{ marginTop: "1rem" }}
          >
            <p className="flex gap-1 items-center my-3 font-bold uppercase ml-2">
              <IoPersonCircle className="text-2xl text-[#B70042] " />
              Person 2 <i className="lowercase">(optional)</i>
            </p>
            <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center ml-2">
              <input
                type="checkbox"
                name="autoSaver"
                className="sr-only "
                checked={formData.emergency[1].personTwo.value}
                onChange={(e) => {
                  handleChange(
                    { target: { value: e.target.checked } },
                    "emergency",
                    "1",
                    "personTwo"
                  );
                }}
              />
              <span
                className={`slider mr-3 flex h-[22px] w-[40px] items-center rounded-full p-1 duration-200 ${
                  isChecked ? "bg-[#B70042]" : "bg-[#CCCCCE]"
                }`}
              >
                <span
                  className={`dot h-[16px] w-[16px] rounded-full bg-white duration-200 ${
                    isChecked ? "translate-x-4" : ""
                  }`}
                ></span>
              </span>
              <span className="label flex items-center text-sm font-medium text-black"></span>
            </label>
            {isChecked && (
              <Grid item container spacing={4}>
                <Grid xs={12} md={6} item>
                  <Input
                    englishLabel={formLabel.emergency.name.english}
                    arabicLabel={formLabel.emergency.name.arabic}
                    value={formData.emergency[1].name.value}
                    handleChange={(e) =>
                      handleChange(e, "emergency", "1", "name")
                    }
                  />
                  {errors?.emergencies?.[1]?.emergencyName && (
                    <ErrorMessage
                      errorMessage={errors?.emergencies?.[1]?.emergencyName}
                    />
                  )}
                </Grid>

                <Grid xs={12} md={6} item>
                  <Input
                    englishLabel={formLabel.emergency.relationship.english}
                    arabicLabel={formLabel.emergency.relationship.arabic}
                    value={formData.emergency[1].relationship.value}
                    handleChange={(e) =>
                      handleChange(e, "emergency", "1", "relationship")
                    }
                  />
                  {errors?.emergencies?.[1]?.emergencyRelationship && (
                    <ErrorMessage
                      errorMessage={
                        errors?.emergencies?.[1]?.emergencyRelationship
                      }
                    />
                  )}
                </Grid>

                <Grid xs={12} md={6} item>
                  <Input
                    englishLabel={formLabel.emergency.civilId.english}
                    arabicLabel={formLabel.emergency.civilId.arabic}
                    type={"number"}
                    value={formData.emergency[1].civilId.value}
                    handleChange={(e) =>
                      handleChange(e, "emergency", "1", "civilId")
                    }
                  />
                  {errors?.emergencies?.[1]?.emergencyCivilId && (
                    <ErrorMessage
                      errorMessage={errors?.emergencies?.[1]?.emergencyCivilId}
                    />
                  )}
                </Grid>

                <Grid xs={12} md={6} item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      gap: "0.3rem"
                    }}
                  >
                    <FormFieldLabel
                      englishLabel={formLabel.emergency.contact.english}
                      arabicLabel={formLabel.emergency.contact.arabic}
                    />

                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        border: "1px solid lightgray",
                        borderRadius: "8px",
                        background: "#F6F2F8",
                        height: "2.6rem"
                      }}
                      country={"kw"}
                      value={formData.emergency[1].contact.value}
                      // onlyCountries={['in', 'de', 'ru']}
                      onChange={(value, countryData) => {
                        const countryCodeNumber = countryData?.dialCode;
                        handleChange(
                          { target: { value } },
                          "emergency",
                          "1",
                          "contact"
                        );
                        handleChange(
                          { target: { value: countryCodeNumber } },
                          "emergency",
                          "1",
                          "contact",
                          "phoneCode"
                        );
                      }}
                    />
                    {errors?.emergencies?.[1]?.emergencyContact && (
                      <ErrorMessage
                        errorMessage={
                          errors?.emergencies?.[1]?.emergencyContact
                        }
                      />
                    )}
                  </div>
                  {/* <Input endornment={<EmailOutlinedIcon/>} label={item.label} /> */}
                  {/* <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
                                               <label className='label'>{item.label}</label>
                                               <input style={{ backgroundColor: "lightgray", border: "none", borderRadius: '8px', height: "2rem" }} />
                                           </div> */}
                </Grid>
              </Grid>
            )}
          </Grid>
        </CommonAccordion>
        {/* <CommonAccordion defaultExpanded={true} title={"SponsorShip Details"}>
                    <Box><h4>How Do You Expect To Meet The Cost Of Tution And Other Expenses ? Check All That Applies </h4></Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 700 }}> Parents </TableCell>
                                    <TableCell align="right"> </TableCell>
                                    <TableCell align="right">
                                        <Input />
                                    </TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CommonAccordion> */}
        <Box sx={{ padding: "2rem" }}>
          <Box></Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div style={{ border: "2px solid redx" }} className="flex  gap-2">
              <input
                type="checkbox"
                name="isDeclared"
                id="isDeclared"
                checked={formData.isDeclared.value === true}
                onChange={(e) => {
                  handleChange(
                    { target: { value: e.target.checked } },
                    "isDeclared"
                  );
                }}
              />
              <h4 className="text-[#B70042] flex justify-start gap-2 items-center font-bold">
                <span>{formLabel.declaration.title.english}</span>
                <span>{formLabel.declaration.title.arabic}</span>
              </h4>
              {errors.isDeclared && (
                <ErrorMessage errorMessage={errors.isDeclared} />
              )}
            </div>
            <div className="mt-5">
              <p className="font-semibold flex flex-col text-justify">
                <span>{formLabel.declaration.declarationText.english}</span>
                <br />
                <span className="text-right">
                  {formLabel.declaration.declarationText.arabic}
                </span>
              </p>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
